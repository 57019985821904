import React, { useEffect, useState } from 'react';
import StyledButton from '@/shared/components/StyledButton/StyledButton';
import './IssueDetails.scss';
import { useDispatch, useSelector } from 'react-redux';
import StyledSelect from '@/shared/components/StyledSelect/StyledSelect';
import { deleteCourseIssue, updateCourseIssue } from '@/review/redux/slices/courseIssuesSlice';
import StyledTextInput from '@/shared/components/StyledTextInput/StyledTextInput';
import ChatAvatar from '@/chat/ChatAvatar';
import StyledRichSelect from '@/shared/components/StyledRichSelect/StyledRichSelect';
import IssueCommentThread from '../IssueCommentThread/IssueCommentThread';
import IssueAttachments from '../IssueAttachments/IssueAttachments';

const IssueDetails = (props) => {
  const { issue } = props;
  const dispatch = useDispatch();

  //   user state from redux
  const usersState = useSelector((state) => state.users);
  const users = usersState.users;

  const statusesState = useSelector((state) => state.courseIssueStatuses);
  const statuses = statusesState.statuses;

  const prioritiesState = useSelector((state) => state.courseIssuePriorities);
  const priorities = prioritiesState.priorities;

  const [formData, setFormData] = useState({
    title: issue.title ?? '',
    description: issue.description ?? '',
    assignee_id: issue.assignee_id ?? '',
    status_id: issue.status_id ?? '',
    priority_id: issue.priority_id ?? '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateCourseIssue({ id: issue.id, updatedData: formData }));
  };

  const deleteIssue = () => {
    dispatch(deleteCourseIssue({ id: issue.id }));
  };

  useEffect(() => {
    setFormData({
      title: issue.title ?? '',
      description: issue.description ?? '',
      assignee_id: issue.assignee_id ?? '',
      status_id: issue.status_id ?? '',
      priority_id: issue.priority_id ?? '',
    });
  }, [issue]);

  return (
    <div className='IssueDetails'>
      <div className={'back-to-board'}>
        <StyledButton greySlim onClick={props.backToBoard}>
          Back to Board
        </StyledButton>
      </div>
      <div className={'issue-body'}>
        {/* title */}
        <div className='field title'>
          <div className={'label'}>Issue Title</div>
          <div className={'value'}>
            <StyledTextInput name='title' value={formData.title} onChange={handleInputChange} />
          </div>
        </div>

        {/* description */}
        <div className='field description'>
          <div className={'label'}>Description</div>
          <div className={'value'}>
            <StyledTextInput name='description' value={formData.description} onChange={handleInputChange} />
          </div>
        </div>

        {/* status */}
        <div className='field status'>
          <div className={'label'}>Status</div>
          <div className={'value'}>
            {' '}
            {
              <StyledSelect>
                <select name={'status_id'} onChange={handleInputChange} value={formData.status_id}>
                  {statuses.map((status, index) => {
                    return (
                      <option key={index} value={status.id}>
                        {status.prettyname}
                      </option>
                    );
                  })}
                </select>
              </StyledSelect>
            }
          </div>
        </div>

        {/* priority */}
        <div className='field priority'>
          <div className={'label'}>Priority</div>
          <div className={'value'}>
            <StyledSelect>
              <select name='priority_id' onChange={handleInputChange} value={formData.priority_id || ''}>
                <option value={''} default>
                  None
                </option>
                {priorities.map((priority, index) => {
                  return (
                    <option key={index} value={priority.id}>
                      {priority.name}
                    </option>
                  );
                })}
              </select>
            </StyledSelect>
          </div>
        </div>

        {/* assigned */}
        <div className='field assigned'>
          <div className={'label'}>Assignee</div>
          <div className={'value'}>
            {
              <StyledRichSelect
                value={formData.assignee_id}
                onChange={(value) => {
                  setFormData((prevState) => ({
                    ...prevState,
                    assignee_id: value,
                  }));
                }}
                placeholder={'Select Assignee'}
                contentStyle={{ display: 'flex', alignItems: 'center' }}
                options={[...users].map((user) => {
                  return {
                    value: user.id,
                    label: user.email,
                    searchValue: `${user.first_name} ${user.last_name} ${user.email}`,
                    content: (
                      <div>
                        <ChatAvatar user={user} size={35} />
                        <div className={'name'} style={{ marginLeft: 10 }}>
                          {user.first_name} {user.last_name}
                        </div>
                      </div>
                    ),
                  };
                })}
              />
            }
          </div>
        </div>
        {/* screenshot */}
        <div className={'field screenshot'}>
          <div className={'label'}>Screenshot</div>
          <div className={'value'}>
            <div className={'screenshot-preview'}>
              {issue.screenshot && (
                <div className={'wrapper'}>
                  <img src={issue.screenshot} alt='Screenshot Preview' />
                </div>
              )}
              {issue.screenshot && (
                <a href={issue.screenshot} target='_blank' rel='noreferrer'>
                  Open Image
                </a>
              )}
              {!issue.screenshot && <div className={'no-screenshot'}>No screenshot uploaded</div>}
            </div>
          </div>
        </div>

        {/* save button */}
        <div className={'field save'}>
          <div className={'label'}>Save Issue</div>
          <div className={'value'}>
            <StyledButton
              onClick={handleSubmit}
              disabled={Object.keys(formData).every((key) => formData[key] === issue[key])}
            >
              Save Issue
            </StyledButton>
          </div>
        </div>
        <div className={'field save'}>
          <div className={'label'}>Delete Issue</div>
          <div className={'value'}>
            <StyledButton onClick={deleteIssue} danger disabled={false}>
              Delete Issue
            </StyledButton>
          </div>
        </div>
      </div>

      {/* attachments */}
      <IssueAttachments issue={issue} />

      {/* comments */}
      <IssueCommentThread issue={issue} />
    </div>
  );
};

export default IssueDetails;
