import React from 'react';
import './StyledSelect.scss';

const StyledSelect = props => {
  return <div className={'StyledSelect'}>
    {
      props.label && <label>{props.label}</label>
    }
    <div className={'select-cont'}>

      {props.children}
    </div>
  </div>;
};

export default StyledSelect;
