import ChatAvatar from '@/chat/ChatAvatar';
import React from 'react';

const IssueCard = (props) => {
  return (
    <button className='IssueCard' onClick={props.select}>
      <div className={'text'}>
        <div className={'title'}>{props.issue?.title ?? 'No Title'}</div>
        {props.issue?.priority && <div className={`priority ${props.issue.priority}`}>{props.issue.priority?.name} priority</div>}
      </div>
      <ChatAvatar user={props.issue?.assignee} />
    </button>
  );
};

export default IssueCard;
