import { createSlice } from '@reduxjs/toolkit';

const breadcrumbsFeatureSlice = createSlice({
  name: 'breadcrumbs',
  initialState: {
    list: [
      { text: 'Home', rrlink: '/home' },
    ],
  },
  reducers: {
    setBreadcrumbs: (state, action) => {
      state.list = action.payload; // Set a new breadcrumb trail
    },
    addBreadcrumb: (state, action) => {
      state.list.push(action.payload); // Add a new breadcrumb
    },
    clearBreadcrumbs: (state) => {
      state.list = []; // Clear all breadcrumbs
    },
  },
});

export const { setBreadcrumbs, addBreadcrumb, clearBreadcrumbs } = breadcrumbsFeatureSlice.actions;

export default breadcrumbsFeatureSlice.reducer;
