import React, { useEffect, useState } from 'react';
import config from '@/config';
import axios from 'axios';
import StyledButton from '@/shared/components/StyledButton/StyledButton';
import ChatAvatar from '@/chat/ChatAvatar';
import PageTitle from '@/shared/components/PageTitle/PageTitle';

const CommentsBoard = (props) => {
  const { courseID } = config;

  const [comment, setComment] = useState('');

  const [thread, setThread] = useState([]);

  const refreshThread = (isMounted = true) => {
    axios
      .get(`/api/course_comments/${courseID}`)
      .then((res) => {
        if (isMounted) {
          setThread(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        // Additional cleanup or actions can be performed here
      });
  };

  const submitComment = () => {
    const toSubmit = comment.trim();
    if (!toSubmit) {
      return;
    }

    setComment('');
    setThread([...thread, { comment: toSubmit, created_at: new Date() }]);

    axios
      .post('/api/course_comments/', {
        comment: toSubmit,
        course_id: courseID,
      })
      .then((res) => {
        refreshThread();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {});
  };

  const stampifyTime = (created_at) => {
    const now = new Date();
    const dateCreated = new Date(created_at);
    if (isNaN(dateCreated.getTime())) {
      return '';
    }

    const ageInMilliseconds = now - dateCreated;
    const ageInMinutes = Math.floor(ageInMilliseconds / 60000);
    const ageInHours = Math.floor(ageInMinutes / 60);

    if (ageInMinutes < 60) {
      if (ageInMinutes < 1) {
        return 'just now';
      }

      return `${ageInMinutes} min ago`;
    } else if (ageInHours < 24) {
      return dateCreated.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    } else {
      return dateCreated.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    }
  };

  const dehtmlizeText = (text) => {
    // Replace common HTML entities with their character equivalents
    return text
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&quot;/g, '"')
      .replace(/&#39;/g, "'")
      .replace(/&#039;/g, "'")
      .replace(/&apos;/g, "'");
  };

  useEffect(() => {
    if (!courseID) {
      return;
    }

    let mounted = true;

    refreshThread(mounted);

    return () => {
      mounted = false;
    };
  }, [courseID]);

  return (
    <div className='CommentsBoard'>
      <PageTitle
        secondary
        title={'General Comments'}
        text='Discuss the course here. Comments are visible to anyone who has editor access for this course.'
      />
      <div className={'feed'}>
        {thread.map((comment, index) => {
          return (
            <div key={index} className={'comment'}>
              <div className={'comment-flex-wrapper'}>
                <div className={'comment-author'}>
                  <ChatAvatar user={comment.user} />
                  <div className={'email'}>{`${comment?.user?.first_name} ${comment?.user?.last_name}`}</div>
                </div>
                <div className={'comment-text'}>{dehtmlizeText(comment.comment)}</div>
              </div>
              <div className={'comment-date'}>{stampifyTime(comment.created_at)}</div>
            </div>
          );
        })}
      </div>
      <div className={'comment-entry'}>
        <textarea placeholder='' value={comment} onChange={(e) => setComment(e.currentTarget.value)}></textarea>
        <StyledButton onClick={submitComment}>Submit</StyledButton>
      </div>
    </div>
  );
};

export default CommentsBoard;
