import React, { Suspense, useEffect, useState } from 'react';
import './Chat.scss';
import axios from 'axios';
// import ChatUserList from './ChatUserList';
// import ChatWindow from './ChatWindow';
// import ChatAvatar from './ChatAvatar';
import SVGIcon from '@/shared/components/SVGIcon/SVGIcon';
import notify from '@/shared/helpers/notification_helper';
import config from '@/config';

const ChatUserList = React.lazy(() => import('./ChatUserList'));
const ChatWindow = React.lazy(() => import('./ChatWindow'));
const ChatAvatar = React.lazy(() => import('./ChatAvatar'));

const Chat = (props) => {
  const { open = true, setOpen = () => {}, setHasUnread = () => {} } = props;

  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [messageLog, setMessageLog] = useState({});

  //dnd
  const [dragStyle, setDragStyle] = useState({
    bottom: '0',
    right: '30px',
  });
  const [dragging, setDragging] = useState(false);

  const me = Array.isArray(userList) ? userList.find((user) => user.id == config.my_user_id) : null;

  const onDragStart = (e) => {
    setDragging(true);
  };

  const handleDrag = (e) => {
    if (dragging) {
      // compare mouse x, y to .chat-display.title location
      const title = document.querySelector('.chat-display .title');
      if (title) {
        const rect = title.getBoundingClientRect();
        // attempt to move under mouse
        const xDiff = e.clientX - rect.x - rect.width / 2;
        const yDiff = e.clientY - rect.y - rect.height / 2;

        const pxAdd = (str, num) => {
          const val = parseInt(str.replace('px', ''));

          return `${val - num}px`;
        };

        setDragStyle({
          bottom: pxAdd(dragStyle.bottom, yDiff),
          right: pxAdd(dragStyle.right, xDiff),
        });
      }
    }
  };

  const resetPosition = () => {
    setDragStyle({
      bottom: '0',
      right: '30px',
    });
  };
  const isMoved = dragStyle.bottom != '0' || dragStyle.right != '30px';

  const handleMinimize = () => {
    setSelectedUser(null);
    setOpen(false);
    resetPosition();
    setDragging(false);
  };

  const onMessageReceived = (msg) => {
    setHasUnread(true);
    const { from } = msg;
    const fromUser = userList.find((user) => user.id == from);
    setMessageLog((prevLog) => ({
      ...prevLog,
      [from]: {
        user: fromUser,
        // read: false,
        read: selectedUser?.id == from,
        log: [
          ...(prevLog[from]?.log || []),
          {
            ...msg,
            created_at: new Date().toISOString(),
          },
        ],
      },
    }));
  };

  const onMessageSent = (msg, to) => {
    const id = Math.random();
    setMessageLog((prevLog) => ({
      ...prevLog,
      [to]: {
        user: me,
        log: [
          ...(prevLog[to]?.log || []),
          {
            from: 'me',
            message: msg,
            id: id,
            created_at: new Date().toISOString(),
            to: to,
          },
        ],
        read: true,
      },
    }));

    axios
      .post(`/api/chat/send/${to}`, {
        message: msg,
      })
      .then((res) => {
        // return response()->json(['message' => $message, 'altered' => $altered]);
        const { message, altered } = res.data;
        if (altered) {
          notify.alert(`Message contained links and/or html code. Message changed to: ${message}`);
        }

        // update state to reflect new message - look for unchecked messages
        setMessageLog((prevLog) => ({
          ...prevLog,
          [to]: {
            ...prevLog[to],
            log: prevLog[to].log.map((log) => {
              if (log.id === id) {
                return {
                  ...log,
                  message: message,
                  checked: true,
                };
              }

              return log;
            }),
          },
        }));
      })
      .catch((err) => {
        // 403 handle
        if (err.response?.status == 403) {
          notify.alert('You cannot send messages to this user.');
          setSelectedUser(null);
        } else {
          notify.alert('Error sending message.');
        }

        console.error(err);
      })
      .finally(() => {});
  };

  const handleUserSelect = (user) => {
    // if no log, start new chat

    setSelectedUser(user);
    // mark log as read
    setMessageLog((prevLog) => ({
      ...prevLog,
      [user.id]: {
        ...(prevLog[user.id] ?? {
          user: user,
          log: [
            ...(prevLog[user.id]?.log || []),
            {
              from: 'system',
              message: 'Private chat started. Chat logs are not stored once the browser is closed.',
              created_at: new Date().toISOString(),
              to: user.id,
            },
          ],
        }),
        read: true,
      },
    }));
    // focus chat window (.chat-input textarea)
    setTimeout(() => {
      const input = document.querySelector('.chat-input textarea');
      if (input) {
        input.focus();
      }
    }, 100);
  };

  const loadUsers = () => {
    axios
      .get('/api/chat/users')
      .then((res) => {
        if (!Array.isArray(res.data)) {
          return;
        }

        setUserList(res.data);

        // check if selected user is not in list
        if (selectedUser) {
          const found = res.data.find((user) => user.id == selectedUser.id);
          if (!found) {
            setSelectedUser(null);
          }
        }
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadUsers();
    setInterval(() => {
      loadUsers();
    }, 60000);
  }, []);

  useEffect(() => {
    const handleNewMessage = (event) => {
      const newNotification = event.detail;
      onMessageReceived(newNotification);
    };

    window.removeEventListener('private-message', handleNewMessage);

    window.addEventListener('private-message', handleNewMessage);

    return () => {
      window.removeEventListener('private-message', handleNewMessage);
    };
  }, [onMessageReceived]);

  useEffect(() => {
    // check if no unread
    const unread = Object.values(messageLog).filter((log) => !log.read).length;
    setHasUnread(unread > 0);
  }, [messageLog]);

  useEffect(() => {
    if (!open) {
      handleMinimize();
    }
  }, [open]);

  if (!Array.isArray(userList)) {
    setOpen(false);

    return null;
  }

  if (!open) {
    return null;
  }

  return (
    <div className={`Chat ${dragging ? 'dragging' : ''}`}>
      {dragging && (
        <div
          className={'drag-bounds'}
          onMouseMove={handleDrag}
          onMouseUp={() => {
            setDragging(false);
          }}
        ></div>
      )}

      <div className={'chat-display'} style={dragStyle}>
        <div className={'handles'}>
          <div
            className={'title'}
            onMouseDown={onDragStart}
            onMouseMove={(e) => {
              handleDrag(e);
            }}
            // onMouseLeave={() => { setDragging(false) }}
            onMouseUp={() => {
              setDragging(false);
            }}
          >
            Chameleon Chat {dragging ? '(Dragging)' : ''}
          </div>

          <div className={'actions'}>
            {open && isMoved && (
              <button
                className={'hideMobile'}
                onClick={() => {
                  resetPosition();
                }}
              >
                <SVGIcon type={'moveBottomRight'} fill='rgba(0, 0, 0, 0.5)' />
              </button>
            )}
            {open && (
              <button
                onClick={() => {
                  handleMinimize();
                }}
              >
                X
              </button>
            )}
          </div>
        </div>

        <div className={'flex'}>
          <Suspense fallback={null}>
            {/* chat window */}
            {selectedUser && (
              <ChatWindow
                user={selectedUser}
                me={me}
                log={messageLog?.[selectedUser?.id]?.log ?? []}
                onMessageSent={onMessageSent}
              />
            )}

            {!selectedUser && (
              <div className={'ChatWindow'}>
                <div className={'title-bar'}>
                  <ChatAvatar useDefault />
                  <div className={'title welcome'}>
                    <p>Welcome to Chameleon Chat.</p>
                    <p>
                      {userList.length > 1
                        ? 'Select a user to chat with.'
                        : 'Online users will be displayed on the right.'}
                    </p>
                    <p style={{ fontStyle: 'italic' }}>
                      Notice: Private chats are not stored on Chameleon servers. Once the browser is closed, the chat is
                      gone.
                    </p>
                  </div>
                </div>
              </div>
            )}

            {/* user list */}
            <ChatUserList
              loadUsers={() => {
                setLoading(true);
                loadUsers();
              }}
              loading={loading}
              log={messageLog}
              selectedUser={selectedUser}
              list={userList}
              onSelectUser={handleUserSelect}
            />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default Chat;
