import React from 'react';
import './StyledCheckbox.scss';

const StyledCheckbox = props => {
  const {
    label,
    value,
    name = label,
    onChange = () => { console.error('no change handler for StyledCheckbox provided'); },
    disabled = false,
    small = false,
  } = props;

  return <div className={`StyledCheckbox ${small ? 'small' : ''} ${disabled ? 'disabled' : ''}`}>
    <input
      type={'checkbox'}
      name={name}
      id={label}
      checked={value}
      onChange={onChange}
      disabled={disabled}
      aria-label={label}
    />
    <label htmlFor={label}>{label}</label>
  </div>;
};

export default StyledCheckbox;
