import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '@/config';

// Async thunk to fetch course issues
export const fetchCourseIssues = createAsyncThunk(
  'courseIssues/fetchCourseIssues',
  async(courseID = config.courseID, thunkAPI) => {
    try {
      const response = await axios.get(`/api/course_issues/${courseID}`);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  },
);

export const updateCourseIssue = createAsyncThunk(
  'courseIssues/updateCourseIssue',
  async({ id, updatedData }, thunkAPI) => {
    try {
      const response = await axios.put(`/api/course_issues/${id}`, updatedData);

      return response.data; // Assuming the updated issue is returned in response
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  },
);

// delete
export const deleteCourseIssue = createAsyncThunk('courseIssues/deleteCourseIssue', async({ id }, thunkAPI) => {
  try {
    const response = await axios.delete(`/api/course_issues/${id}`);

    return response.data.issue;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

const courseIssuesSlice = createSlice({
  name: 'courseIssues',
  initialState: {
    issues: [],
    loading: false,
    error: null,
  },
  reducers: {
    // Add synchronous actions if needed
  },
  extraReducers: (builder) => {
    // Handle fetchCourseIssues
    builder
      .addCase(fetchCourseIssues.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCourseIssues.fulfilled, (state, action) => {
        state.loading = false;
        state.issues = action.payload;
      })
      .addCase(fetchCourseIssues.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Handle updateCourseIssue
    builder
      .addCase(updateCourseIssue.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCourseIssue.fulfilled, (state, action) => {
        state.loading = false;
        const updatedIssue = action.payload;
        // Find the updated issue and replace it in the state
        const index = state.issues.findIndex((issue) => issue.id === updatedIssue.id);
        if (index !== -1) {
          state.issues[index] = updatedIssue;
        }
      })
      .addCase(updateCourseIssue.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Handle deleteCourseIssue
    builder
      .addCase(deleteCourseIssue.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCourseIssue.fulfilled, (state, action) => {
        state.loading = false;
        const deletedIssueID = action.payload.id;
        // Remove the deleted issue from the state
        state.issues = state.issues.filter((issue) => issue.id !== deletedIssueID);
      })
      .addCase(deleteCourseIssue.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default courseIssuesSlice.reducer;
