import React, { useEffect, useState } from 'react';
import './ActivityFeed.scss';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ActivityFeed = (props) => {
  const [loading, setLoading] = useState(false);
  const [feedData, setFeedData] = useState(null);
  const [error, setError] = useState(null);

  const getData = () => {
    setLoading(true);
    axios
      .get('/api/activity_feed')
      .then((res) => {
        let data = [...res.data];
        data = data
          .filter((action) => {
            // only if has valid unix time created_at
            return action.created_at && action.created_at > 0;
          })
          .sort((a, b) => {
            return new Date(b.created_at) - new Date(a.created_at);
          });

        setFeedData(data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const convertTimestamp = (unix) => {
    // Ensure the input is a valid number
    if (isNaN(unix) || unix <= 0) {
      return '? minutes ago';
    }

    // Human-readable how long ago is a timestamp
    const now = new Date();
    const then = new Date(unix * 1000);

    // Check if 'then' is a valid date
    if (isNaN(then.getTime())) {
      return '? minutes ago';
    }

    const diff = now - then;
    const minutes = Math.floor(diff / 60000);
    if (minutes < 1) return 'Just now';
    if (minutes < 60) return `${minutes} minutes ago`;
    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours} hours ago`;
    const days = Math.floor(hours / 24);

    return `${days} days ago`;
  };

  const actionTitleMap = {
    edited: 'was edited',
    migrated: 'was migrated',
    created: 'was created',
    deleted: 'was deleted',
  };

  const processActionText = (action) => {
    // action.user_email
    // action.action_title => map to real text
    // action.course_id, action.course_title => course link
    const verb = actionTitleMap[action.action_title] ?? action.action_title;

    if (action.action_title === 'deleted') {
      return (
        <>
          #{action.course_id} {action.course_title} {verb}
        </>
      );
    }

    return (
      <>
        <a href={action.course_link ?? '#'}>
          #{action.course_id} {action.course_title}
        </a>{' '}
        {verb}{' '}
      </>
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className='ActivityFeed'>
      <div className={'heading'}>
        <h3>Activity Feed</h3>

        <a
          onClick={() => {
            setLoading(true);
            setFeedData(null);
            setError(null);
            getData();
          }}
        >
          Refresh
        </a>
      </div>

      {feedData &&
        feedData.map((action, i) => {
          return (
            <div key={i} className='action'>
              <div className='text'>{processActionText(action)}</div>
              <div className='date'>{convertTimestamp(action.created_at)}</div>
            </div>
          );
        })}

      {feedData && feedData.length === 0 && (
        <div className='action special'>
          <div className='text'>
            No Recent Activity. To subscribe to specific course updates, view the{' '}
            <Link to={'/profile/feed-settings'}>Activity Feed Settings</Link> page.
          </div>
          <div className='date'></div>
        </div>
      )}

      {loading && <div className='action special loading'>Loading...</div>}

      {error && <div className='action special error'>Error: {error.message}</div>}
    </div>
  );
};

export default ActivityFeed;
