import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AuthCheck.scss';
import notify from '@/shared/helpers/notification_helper';
import TopbarButtonTooltip from '@/shared/components/TopbarButtonTooltip/TopbarButtonTooltip';

const AuthCheck = () => {
  const [authStatus, setAuthStatus] = useState(null);

  const checkAuth = async() => {
    try {
      const response = await axios.get('/api/authcheck');
      setAuthStatus(response.data.status);

      if (response.data.status === 210) {
        if (window.location.pathname !== '/profile/reset-password') {
          window.location.href = '/profile/reset-password';
        }
      }
    } catch (error) {
      setAuthStatus(401);
      console.error('Error checking authentication:', error);
    }
  };

  useEffect(() => {
    // Run the check immediately on component mount
    checkAuth();

    const intervalId = setInterval(() => {
      checkAuth();
    }, 60000); // Ping the endpoint every 60 seconds (60000 ms)

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (authStatus !== 401) return;
    notify.alert('Your login session is invalid. Please log in again.');
  }, [authStatus]);

  let bgcolor = 'gray';
  let boxshadow = '0 0 2px gray, 0 0 10px gray';
  let tooltip = 'Checking';
  if (authStatus === 200) {
    bgcolor = '#51ac61';
    boxshadow = '0 0 2px #51ac61, 0 0 10px #51ac61';
    tooltip = 'Connected';
  } else if (authStatus === 401) {
    bgcolor = 'red';
    boxshadow = '0 0 2px red, 0 0 10px red';
    tooltip = 'Not Connected';
  } else if (authStatus === 210) {
    bgcolor = 'yellow';
    boxshadow = '0 0 2px yellow, 0 0 10px yellow';
    tooltip = 'Expired Password';
  }

  const circleStyle = {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: bgcolor,
    boxShadow: boxshadow,
  };

  return (
    <div className='AuthCheck'>
      <div
        className={'circle'}
        role='button'
        style={circleStyle}
        onClick={() => {
          setAuthStatus(null);
          checkAuth();
        }}
        onKeyUp={(e) => {
          // space or enter
          if (e.key === ' ' || e.key === 'Enter') {
            setAuthStatus(null);
            checkAuth();
          }
        }}
        title='Check Connection'
        tabIndex='0'
      >
        <TopbarButtonTooltip text={tooltip} />
      </div>
    </div>
  );
};

export default AuthCheck;
