import React from 'react';

export function svgFormDataSubmit(iconStyle,pathStyle) {
  const lbStyle={ fill:'#637c84' };
  const fieldStyle={ fill:'#fff',strokeWidth:2,stroke:'#637c84' };

  return <g>
    <rect style={lbStyle} width="81.5" height="5" />
    <rect style={fieldStyle} x="1" y="8" width="108" height="7" />
    <rect style={lbStyle} y="22" width="55" height="5" />
    <rect style={fieldStyle} x="1" y="30" width="108" height="7" />
    <rect style={lbStyle} y="44" width="102.9" height="5" />
    <rect style={fieldStyle} x="1" y="52" width="108" height="14" />
    <rect style={iconStyle} x="0" y="74" width="15" height="5" />
  </g>;
}

;

export function svgMultiColumnGridChecklist(iconStyle,pathStyle) {
  const lbStyle={ fill:'#637c84' };
  const lbStyleL={ fill:'#afbfbe' };

  return <g>
    <rect style={iconStyle} x="48.5" y="74.2" width="12.9" height="4.8" />
    <rect style={pathStyle} width="110" height="9.2" />
    <rect style={lbStyle} x="41.7" y="2.1" width="14" height="5" />
    <rect style={lbStyle} x="59.8" y="2.1" width="14" height="5" />
    <rect style={lbStyle} x="77.9" y="2.1" width="14" height="5" />
    <rect style={lbStyle} x="96" y="2.1" width="14" height="5" />
    <rect style={lbStyle} y="11.9" width="37.5" height="12.5" />
    <rect style={lbStyleL} x="37.5" y="11.9" width="72.5" height="12.5" />
    <circle style={iconStyle} cx="48.6" cy="18.2" r="3" />
    <circle style={pathStyle} cx="66.7" cy="18.2" r="3" />
    <circle style={iconStyle} cx="84.8" cy="18.2" r="3" />
    <circle style={pathStyle} cx="102.9" cy="18.2" r="3" />
    <rect style={lbStyleL} x="37.5" y="27.1" width="72.5" height="12.5" />
    <rect style={lbStyle} y="27.1" width="37.5" height="12.5" />
    <circle style={pathStyle} cx="48.6" cy="33.3" r="3" />
    <circle style={pathStyle} cx="66.7" cy="33.3" r="3" />
    <circle style={pathStyle} cx="84.8" cy="33.3" r="3" />
    <circle style={iconStyle} cx="102.9" cy="33.3" r="3" />
    <rect style={lbStyleL} x="37.5" y="42.3" width="72.5" height="12.5" />
    <rect style={lbStyle} y="42.3" width="37.5" height="12.5" />
    <circle style={pathStyle} cx="48.6" cy="48.5" r="3" />
    <circle style={iconStyle} cx="66.7" cy="48.5" r="3" />
    <circle style={pathStyle} cx="84.8" cy="48.5" r="3" />
    <circle style={iconStyle} cx="102.9" cy="48.5" r="3" />
    <rect style={lbStyleL} x="37.5" y="57.5" width="72.5" height="12.5" />
    <rect style={lbStyle} y="57.5" width="37.5" height="12.5" />
    <circle style={iconStyle} cx="48.6" cy="63.7" r="3" />
    <circle style={pathStyle} cx="66.7" cy="63.7" r="3" />
    <circle style={pathStyle} cx="84.8" cy="63.7" r="3" />
    <circle style={pathStyle} cx="102.9" cy="63.7" r="3" />
  </g>;
}

;

export function svgTrueFalseGrid(iconStyle,pathStyle) {
  const noDragStyle={ fill:'#fff',strokeWidth:2,stroke:'#637c84' };
  const noDragStyleActive={ fill:'#637c84' };
  const iconStyleCirc=iconStyle;
  iconStyleCirc.strokeWidth=2;
  iconStyleCirc.stroke='#637c84';

  return <g>
    <defs>
      <filter id="icon-drag" width="110" height="67">
        <feDropShadow dx="0" dy="1.7" stdDeviation="2" />
      </filter>
    </defs>
    <rect style={noDragStyleActive} x="49.7" y="0" width="11.9" height="5" />
    <rect style={noDragStyleActive} x="64.1" y="0" width="11.9" height="5" />
    <rect style={noDragStyleActive} x="0" y="7.9" width="47.2" height="10" />
    <circle style={iconStyleCirc} cx="55.6" cy="12.9" r="3" />
    <circle style={noDragStyle} cx="70" cy="12.9" r="3" />
    <rect style={noDragStyleActive} x="0" y="23.2" width="47.2" height="10" />
    <circle style={noDragStyle} cx="55.6" cy="28.2" r="3" />
    <circle style={iconStyleCirc} cx="70" cy="28.2" r="3" />
    <rect style={noDragStyleActive} x="0" y="38.5" width="47.2" height="10" />
    <circle style={noDragStyle} cx="55.6" cy="43.5" r="3" />
    <circle style={iconStyleCirc} cx="70" cy="43.5" r="3" />
    <rect style={noDragStyleActive} x="0" y="53.7" width="47.2" height="10" />
    <circle style={iconStyleCirc} cx="55.6" cy="58.7" r="3" />
    <circle style={noDragStyle} cx="70" cy="58.7" r="3" />
    <rect style={noDragStyleActive} x="0" y="69" width="47.2" height="10" />
    <circle style={noDragStyle} cx="55.6" cy="74" r="3" />
    <circle style={iconStyleCirc} cx="70" cy="74" r="3" />
    <rect style={noDragStyleActive} x="78.5" y="7.9" width="31.5" height="71.1" />
  </g>;
}

;

export function svgQuizNonJudgedMediaL(iconStyle,pathStyle) {
  const lbStyle={ fill:'#637c84' };

  return <g>
    <rect style={lbStyle} x="5" y="74.4" width="100" height="4.6" />
    <rect style={iconStyle} width="110" height="75.4" />
    <path style={pathStyle} d="M 4 4 v 67.2 h 102.2 V 4 H 4 Z M 9 39 h 35.2 v 6.4 H 9 V 39 Z M 38.3 66 H 9 v -6.4 h 29.3 C 38.3 59.6 38.3 66 38.3 66 Z M 48.2 55.8 H 9 v -6.4 h 39.2 V 55.8 Z M 9 32 V 20.3 l 8.2 -11.7 l 13.3 15.7 l 5.8 -4.1 L 48.2 32 C 48.2 32 9 32 9 32 Z M 41.9 8.5 c 2.6 0 4.7 2.1 4.7 4.7 S 44.5 18 41.9 18 c -2.6 0 -4.7 -2.1 -4.7 -4.7 S 39.3 8.5 41.9 8.5 Z" />
    <path style={iconStyle} d="M 53.2 0 v 75.4 H 110 V 0 H 53.2 Z M 67.6 4 h 28.1 v 3.8 H 67.6 V 4 Z M 71.6 71.1 c -1.7 0 -3 -1.3 -3 -3 c 0 -1.7 1.3 -3 3 -3 c 1.7 0 3 1.3 3 3 C 74.6 69.8 73.3 71.1 71.6 71.1 Z M 81.6 71.1 c -1.7 0 -3 -1.3 -3 -3 c 0 -1.7 1.3 -3 3 -3 c 1.7 0 3 1.3 3 3 C 84.6 69.8 83.3 71.1 81.6 71.1 Z M 91.6 71.1 c -1.7 0 -3 -1.3 -3 -3 c 0 -1.7 1.3 -3 3 -3 c 1.7 0 3 1.3 3 3 C 94.6 69.8 93.3 71.1 91.6 71.1 Z M 106.2 31.9 H 57 v -7.6 h 49.2 V 31.9 Z M 106.2 19.9 H 57 v -7.6 h 49.2 V 19.9 Z" />
  </g>;
}

;

export function svgQuizNonJudgedMediaR(iconStyle,pathStyle) {
  const lbStyle={ fill:'#637c84' };

  return <g>
    <rect style={lbStyle} x="5" y="74.4" width="100" height="4.6" />
    <rect style={iconStyle} width="110" height="75.4" />
    <path style={pathStyle} d="M 3.8 4 v 67.2 h 102.4 V 4 H 3.8 Z M 62 39 h 35.2 v 6.4 H 62 V 39 Z M 91.3 66 H 62 v -6.4 h 29.3 C 91.3 59.6 91.3 66 91.3 66 Z M 101.2 55.8 H 62 v -6.4 h 39.2 V 55.8 Z M 62 32 V 20.3 l 8.2 -11.7 l 13.3 15.7 l 5.8 -4.1 L 101.2 32 C 101.2 32 62 32 62 32 Z M 94.9 8.5 c 2.6 0 4.7 2.1 4.7 4.7 S 97.5 18 94.9 18 c -2.6 0 -4.7 -2.1 -4.7 -4.7 S 92.3 8.5 94.9 8.5 Z" />
    <path style={iconStyle} d="M 0 0 v 75.4 h 56.8 V 0 H 0 Z M 14.3 4 h 28.1 v 3.8 H 14.3 V 4 Z M 18.4 71.1 c -1.7 0 -3 -1.3 -3 -3 c 0 -1.7 1.3 -3 3 -3 c 1.7 0 3 1.3 3 3 C 21.4 69.8 20.1 71.1 18.4 71.1 Z M 28.4 71.1 c -1.7 0 -3 -1.3 -3 -3 c 0 -1.7 1.3 -3 3 -3 c 1.7 0 3 1.3 3 3 C 31.4 69.8 30.1 71.1 28.4 71.1 Z M 38.4 71.1 c -1.7 0 -3 -1.3 -3 -3 c 0 -1.7 1.3 -3 3 -3 c 1.7 0 3 1.3 3 3 C 41.4 69.8 40.1 71.1 38.4 71.1 Z M 53 31.9 H 3.8 v -7.6 H 53 V 31.9 Z M 53 19.9 H 3.8 v -7.6 H 53 V 19.9 Z" />
  </g>;
}

;
