import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './CMLButton.scss';
const cb = 'cml-button';

class CMLButton extends Component {
  constructor(props) {
    super(props);
    const methods = ['getOverrideClasses'];
    methods.forEach(method => (this[method] = this[method].bind(this)));
  }

  render() {
    const cbOverrides = this.getOverrideClasses();

    return (
      <button
        className={`${cb} ${cbOverrides}`}
        id={this.props.id}
        onClick={this.props.handleOnClick}
        type={this.props.type}
        disabled={this.props.disabled || false}
      >
        { this.props.title }
      </button>
    );
  }

  getOverrideClasses() {
    const cbOverrides = [];

    if ( this.props.color ) {
      cbOverrides.push(`${cb}--${this.props.color}`);
    }

    if ( this.props.disabled ) {
      cbOverrides.push(`${cb}--disabled`);
    }

    if ( this.props.inverse ) {
      cbOverrides.push(`${cb}--inverse`);
    }

    if ( this.props.link ) {
      cbOverrides.push(`${cb}--link`);
    }

    if ( this.props.loading ) {
      cbOverrides.push(`${cb}--loading`);
    }

    if ( this.props.size ) {
      cbOverrides.push(`${cb}--${this.props.size}`);
    }

    if ( this.props.active ) {
      cbOverrides.push('active');
    }

    return cbOverrides.join(' ');
  }
}

CMLButton.propTypes = {
  active: PropTypes.bool,
  color: PropTypes.string,
  handleOnClick: PropTypes.func,
  inverse: PropTypes.bool,
  link: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
};

CMLButton.defaultProps = {
  color: 'primary',
  handleOnClick: () => {},
  inverse: false,
  link: false,
  loading: false,
  size: 'default',
  type: 'button',
};

export default CMLButton;
