import React from 'react';
import './TopbarButtonTooltip.scss';

const TopbarButtonTooltip = props => {
  const { text } = props;

  return <div className="TopbarButtonTooltip">
    <div className={'text'}>
      {text}
    </div>
  </div>;
};

export default TopbarButtonTooltip;
