import config from '@/config';

export const appUrl = `${config.s3_resolved_base}/`;

export const s3_bucket = config.s3_bucket;

export const bucketURL = `${config.s3_resolved_base}/`;

export const animationOptionsSimple = [
  ':None',
  'anim-left:Slide In Right',
  'anim-right:Slide In Left',
  'anim-top:Slide In Bottom',
  'anim-bottom:Slide In Top',
  'anim-scale-in:Scale In',
  'anim-rotate-in:Rotate In',
  'anim-rotate-in2:Rotate in CC',
  'anim-scale-rotate-in:Scale/Rotate In',
  'anim-scale-rotate-in2:Scale/Rotate In CC',
];

export const animationOptions = [
  ':None',
  'bounce:Bounce',
  'bounceIn:Bounce In',
  'bounceInDown:Bounce In Down',
  'bounceInLeft:Bounce In Left',
  'bounceInRight:Bounce In Right',
  'bounceInUp:Bounce In Up',
  'fadeIn:Fade In',
  'fadeInDown:Fade In Down',
  'fadeInDownBig:Fade In Down Big',
  'fadeInLeft:Fade In Left',
  'fadeInLeftBig:Fade In Left Big',
  'fadeInRight:Fade In Right',
  'fadeInRightBig:Fade In Right Big',
  'fadeInUp:Fade In Up',
  'fadeInUpBig:Fade In Up Big',
  'flash:Flash',
  'flipInX:Flip In X',
  'flipInY:Flip In Y',
  'headShake:Head Shake',
  'jello:Jello',
  'lightSpeedIn:Light Speed In',
  'pulse:Pulse',
  'rollIn:Roll In',
  'rotateIn:Rotate In',
  'rotateInDownLeft:Rotate In Down Left',
  'rotateInDownRight:Rotate In Down Right',
  'rotateInUpLeft:Rotate In Up Left',
  'rotateInUpRight:Rotate In Up Right',
  'rubberBand:Rubber Band',
  'shake:Shake',
  'slideInDown:Slide In Down',
  'slideInLeft:Slide In Left',
  'slideInRight:Slide In Right',
  'slideInUp:Slide In Up',
  'swing:Swing',
  'tada:Tada',
  'wobble:Wobble',
  'zoomIn:Zoom In',
  'zoomInDown:Zoom In Down',
  'zoomInLeft:Zoom In Left',
  'zoomInRight:Zoom In Right',
  'zoomInUp:Zoom In Up',
];

export const animationOptionsLite = [
  ':None',
  'bounceIn:Bounce In',
  'fadeIn:Fade In',
  'fadeInLeft:Fade In Left',
  'fadeInRight:Fade In Right',
  'flipInX:Flip In X',
  'flipInY:Flip In Y',
  'lightSpeedIn:Light Speed In',
  'rollIn:Roll In',
  'rotateIn:Rotate In',
];

export const animationOutOptions = [
  ':None',
  'bounceOut:Bounce Out',
  'bounceOutDown:Bounce Out Down',
  'bounceOutLeft:Bounce Out Left',
  'bounceOutRight:Bounce Out Right',
  'bounceOutUp:Bounce Out Up',
  'fadeOut:Fade Out',
  'fadeOutDown:Fade Out Down',
  'fadeOutDownBig:Fade Out Down Big',
  'fadeOutLeft:Fade Out Left',
  'fadeOutLeftBig:Fade Out Left Big',
  'fadeOutRight:Fade Out Right',
  'fadeOutRightBig:Fade Out Right Big',
  'fadeOutUp:Fade Out Up',
  'fadeOutUpBig:Fade Out Up Big',
  'flipOutX:Flip Out X',
  'flipOutY:Flip Out Y',
  'lightSpeedOut:Light Speed Out',
  'rollOut:Roll Out',
  'rotateOut:Rotate Out',
  'rotateOutDownLeft:Rotate Out Down Left',
  'rotateOutDownRight:Rotate Out Down Right',
  'rotateOutUpLeft:Rotate Out Up Left',
  'rotateOutUpRight:Rotate Out Up Right',
  'slideOutDown:Slide Out Down',
  'slideOutLeft:Slide Out Left',
  'slideOutRight:Slide Out Right',
  'slideOutUp:Slide Out Up',
  'zoomOut:Zoom Out',
  'zoomOutDown:Zoom Out Down',
  'zoomOutLeft:Zoom Out Left',
  'zoomOutRight:Zoom Out Right',
  'zoomOutUp:Zoom Out Up',
];

export const animationOutOptionsLite = [
  ':None',
  'bounceOut:Bounce Out',
  'fadeOut:Fade Out',
  'flipOutX:Flip Out X',
  'flipOutY:Flip Out Y',
  'lightSpeedOut:Light Speed Out',
  'rollOut:Roll Out',
  'rotateOut:Rotate Out',
];

export const apis = [
  'chameleon:LRS',
  'scorm:SCORM 1.2 (default)',
  'scorm_2:SCORM 2004 2nd',
  'scorm_3:SCORM 2004 3rd',
  'scorm_4:SCORM 2004 4th',
  'tincan:TinCan',
  'none:No Tracking',
];

export const bgs = ['none:None', 'color:Color', 'image:Image', 'video:Video'];

export const pageBgs = ['none:None', 'adobeanimate:Adobe Animate', 'color:Color', 'image:Image', 'video:Video'];

export const isEdge =
  window.navigator.userAgent.indexOf('Windows') > -1 && window.navigator.userAgent.indexOf('Edge') > -1 ? true : false;

export const languages = [
  { sc: 'ar', lang: 'Arabic', active: 1 },
  { sc: 'bs', lang: 'Bosnian', active: 1 },
  { sc: 'bg', lang: 'Bulgarian', active: 1 },
  { sc: 'zh-hk', lang: 'Chinese (Hong Kong SAR)', active: 0 },
  { sc: 'zh-tw', lang: 'Chinese (Taiwan)', active: 1 },
  { sc: 'cmn', lang: 'Chinese Mandarin', active: 1 },
  { sc: 'zh', lang: 'Chinese Simplified', active: 1 },
  { sc: 'zh_HA', lang: 'Chinese Traditional', active: 1 },
  { sc: 'hr', lang: 'Croatian', active: 1 },
  { sc: 'cs', lang: 'Czech', active: 1 },
  { sc: 'da', lang: 'Danish', active: 1 },
  { sc: 'nl', lang: 'Dutch', active: 1 },
  { sc: 'en-US', lang: 'English', active: 1 },
  { sc: 'en-au', lang: 'English (Australia)', active: 0 },
  { sc: 'gb', lang: 'English UK', active: 1 },
  { sc: 'et', lang: 'Estonian', active: 1 },
  { sc: 'fr_CA', lang: 'French (Canadian)', active: 1 },
  { sc: 'fi', lang: 'Finnish', active: 1 },
  { sc: 'fr', lang: 'French', active: 1 },
  { sc: 'ka', lang: 'Georgian', active: 1 },
  { sc: 'de', lang: 'German', active: 1 },
  { sc: 'gsw', lang: 'German (Switzerland)', active: 1 },
  { sc: 'el', lang: 'Greek', active: 1 },
  { sc: 'he', lang: 'Hebrew', active: 1 },
  { sc: 'hi', lang: 'Hindi', active: 1 },
  { sc: 'hu', lang: 'Hungarian', active: 1 },
  { sc: 'is', lang: 'Icelandic', active: 1 },
  { sc: 'id', lang: 'Indonesian', active: 1 },
  { sc: 'it', lang: 'Italian', active: 1 },
  { sc: 'ja', lang: 'Japanese', active: 1 },
  { sc: 'kk', lang: 'Kazakh', active: 1 },
  { sc: 'tlh', lang: 'Klingon', active: 0 },
  { sc: 'ko', lang: 'Korean', active: 1 },
  { sc: 'lv', lang: 'Latvian', active: 1 },
  { sc: 'lt', lang: 'Lithuanian', active: 1 },
  { sc: 'ms', lang: 'Malay', active: 1 },
  { sc: 'no', lang: 'Norwegian', active: 1 },
  { sc: 'pl', lang: 'Polish', active: 1 },
  { sc: 'pt_BR', lang: 'Portuguese Brazil', active: 1 },
  { sc: 'pt_PR', lang: 'Portuguese Portugal', active: 1 },
  { sc: 'ro', lang: 'Romanian', active: 1 },
  { sc: 'ru', lang: 'Russian', active: 1 },
  { sc: 'sr', lang: 'Serbian', active: 1 },
  { sc: 'sk', lang: 'Slovak', active: 1 },
  { sc: 'sl', lang: 'Slovenian', active: 1 },
  { sc: 'es-cl', lang: 'Spanish (Chile)', active: 1 },
  { sc: 'es-co', lang: 'Spanish (Colombia)', active: 0 },
  { sc: 'es_LA', lang: 'Spanish', active: 1 },
  { sc: 'es', lang: 'Spanish (Spain)', active: 1 },
  { sc: 'es-mx', lang: 'Spanish (Mexico)', active: 1 },
  { sc: 'es-pe', lang: 'Spanish (Peru)', active: 0 },
  { sc: 'sv', lang: 'Swedish', active: 1 },
  { sc: 'th', lang: 'Thai', active: 1 },
  { sc: 'tr', lang: 'Turkish', active: 1 },
  { sc: 'ur', lang: 'Urdu', active: 1 },
  { sc: 'vi', lang: 'Vietnamese', active: 1 },
];

export const pageTransitions = [
  'bounce:Bounce',
  'carousel:Carousel',
  'carouselVert:Carousel Vertical',
  'crossFade:Cross Fade',
  'flipInCenterX:Flip In Center X',
  'flipInCenterY:Flip In Center Y',
  'flipInBottom:Flip In Bottom',
  'flipInLeft:Flip In Left',
  'flipInTop:Flip In Top',
  'flipInRight:Flip In Right',
  'lightspeedIn:Light Speed In',
  'rollInBottom:Roll In Bottom',
  'rollInLeft:Roll In Left',
  'rollInRight:Roll In Right',
  'rollInTop:Roll In Top',
  'rotateInClockwise:Rotate In Clockwise',
  'rotateInCClockwise:Rotate In CC',
  'rotateInDownLeft:Rotate In Down Left',
  'rotateInDownRight:Rotate In Down Right',
  'rotateInUpLeft:Rotate In Up Left',
  'rotateInUpRight:Rotate In Up Right',
  'scaleOpac:Scale Opac',
  'zoomInCenter:Zoom In Center',
  'none:None',
];

export const rtlLayoutOptions = ['flex-start:Left', 'center:Center', 'flex-end:Right (default)'];

/**
 * Widget Helpers
 */
//OLD WIDGETS
export const supportedBackgroundWidgets = [
  { id: 'bgcolor', icon: 'bgcolor', label: 'background color' },
  { id: 'bgimage', icon: 'bgimage', label: 'background image' },
  { id: 'bgvideo', icon: 'bgvideo', label: 'background video' },
];

//MEDIA & TEXT
export const supportedMediaWidgets = [
  { id: 'adobe-edge', icon: 'adobeanimate', label: 'adobe animate', rowType: 'none', mw: 1, dh: false },
  { id: 'audio', icon: 'audio', rowType: 'none', mw: 2, dh: false },
  { id: 'before-after', icon: 'beforeafter', label: 'before / after', rowType: 'none', mw: 3, dh: false },
  { id: 'embed', icon: 'embed', rowType: 'none', mw: 3, dh: false },
  { id: 'fpo', icon: 'fpo', rowType: 'none', mw: 1, dh: false },
  { id: 'image', icon: 'image', rowType: 'none', mw: 1, dh: false },
  { id: 'imagestack', icon: 'imagestack', label: 'image stack', rowType: 'none', mw: 4, dh: false },
  { id: 'panorama', icon: 'panorama', rowType: 'none', mw: 3, dh: false },
  { id: 'pinnedmosaic', icon: 'pinnedmosaic', label: 'pinned image mosaic', rowType: 'none', mw: 4, dh: false },
  { id: 'pullquote', icon: 'pullquote', rowType: 'none', mw: 3, dh: false },
  { id: 'text', icon: 'text_fields', rowType: 'none', mw: 1, dh: false },
  { id: 'slideshow', icon: 'timedcarousel', label: 'timed carousel', rowType: 'none', mw: 3, dh: false },
  { id: 'video', icon: 'video', rowType: 'none', mw: 2, dh: false },
  { id: 'zoom', icon: 'zoom', rowType: 'none', mw: 1, dh: false },
];

//SCROLLING
export const supportedScrollingWidgets = [
  { id: 'freefallparallax', icon: 'freefallparallax', label: 'freefall parallax', rowType: 'fp', mw: 12, dh: false },
  { id: 'gradparallax', icon: 'gradparallax', label: 'graduated parallax', rowType: 'fp', mw: 12, dh: false },
  { id: 'timelineVert', icon: 'timelineVert', label: 'vertical timeline', rowType: 'fr', mw: 12, dh: false },
];

//CLICK-TELLS
export const supportedClicktellWidgets = [
  {
    id: 'accordions-simpleAccordion',
    icon: 'accordion-none',
    label: 'accordion',
    componentLayout: 'simpleAccordion',
    rowType: 'none',
    mw: 6,
    dh: false,
  },
  { id: 'accordions', icon: 'accordion', label: 'accordion (side media)', rowType: 'none', mw: 6, dh: false },
  {
    id: 'accordions-zoomAccordion',
    icon: 'accordion-zoomL',
    label: 'accordion (zoom)',
    componentLayout: 'zoomAccordion',
    rowType: 'none',
    mw: 6,
    dh: false,
  },
  { id: 'multcarousel', icon: 'multcarousel', label: 'carousel', rowType: 'fr', mw: 12, dh: false },
  { id: 'cube', icon: 'cube', label: 'cube', rowType: 'none', mw: 1, dh: false },
  { id: 'quickhittile', icon: 'quickhittile', label: 'flip cards', rowType: 'none', mw: 2, dh: false },
  {
    id: 'fullwidthbgaccordion',
    icon: 'fullwidthbgaccordion',
    label: 'full width background accordion',
    rowType: 'fp',
    mw: 12,
    dh: false,
  },
  {
    id: 'progressionguided',
    icon: 'progressionguided',
    label: 'guided progression',
    rowType: 'none',
    mw: 12,
    dh: false,
  },
  { id: 'timelineHoriz', icon: 'timelineHoriz', label: 'horizontal timeline', rowType: 'fr', mw: 12, dh: false },
  { id: 'hotspotclicktell', icon: 'hotspotclicktell', label: 'hotspots', rowType: 'none', mw: 6, dh: false },
  {
    id: 'quickhitmintext',
    icon: 'quickhitmintext',
    searchVal: 'icon tabs easy medium',
    label: 'icon tabs',
    rowType: 'fr',
    mw: 6,
    dh: false,
  },
  {
    id: 'progressionclicktell2',
    icon: 'progressionclicktell2',
    label: 'large slideshow',
    componentLayout: 'progClicktellSide',
    rowType: 'fr',
    mw: 12,
    dh: false,
  },
  { id: 'panoramavr', icon: 'panoramavr', label: 'panorama vr', rowType: 'none', mw: 8, dh: false },
  {
    id: 'progressionclicktell',
    icon: 'progressionclicktell1',
    label: 'progression',
    rowType: 'none',
    mw: 6,
    dh: false,
  },
  { id: 'gallery', icon: 'gallery', label: 'slideshow modal', rowType: 'none', mw: 1, dh: false },
  { id: 'tabs', icon: 'tab', rowType: 'none', mw: 6, dh: false },
  {
    id: 'thumbnailmosaicclicktell',
    icon: 'thumbnailmosaicclicktell-horizontal-left',
    label: 'thumbnail image (horizontal)',
    rowType: 'fr',
    mw: 12,
    dh: false,
  },
  {
    id: 'thumbnailmosaicclicktell-vertical-multi',
    icon: 'thumbnailmosaicclicktell-vertical-multi',
    label: 'thumbnail image (multi-level)',
    componentLayout: 'thumbClickMulti',
    rowType: 'fr',
    mw: 12,
    dh: false,
    disabled: true,
  },
  {
    id: 'thumbnailmosaicclicktell-vertical',
    icon: 'thumbnailmosaicclicktell-vertical',
    label: 'thumbnail image (vertical)',
    componentLayout: 'thumbClickVert',
    rowType: 'fr',
    mw: 12,
    dh: false,
  },
  { id: 'tip', icon: 'tipbutton', rowType: 'none', mw: 3, dh: false },
  { id: 'timelineVisual', icon: 'timelineVisual1', label: 'visual timeline', rowType: 'fp', mw: 12, dh: false },
];

//SORTABLES
export const supportedSortableWidgets = [
  { id: 'categorymatching', icon: 'categorymatching', label: 'category matching', rowType: 'fr', mw: 12, dh: false },
  { id: 'dragmatching', icon: 'dragmatching', label: 'drag matching', rowType: 'fr', mw: 12, dh: false },
  {
    id: 'dragimagematching',
    icon: 'dragimagematching',
    label: 'drag media matching',
    rowType: 'fr',
    mw: 12,
    dh: false,
  },
  {
    id: 'dragmediatomedia',
    icon: 'dragmediatomedia',
    label: 'drag media to media',
    rowType: 'fr',
    mw: 12,
    dh: false,
    disabled: true,
  },
  { id: 'dragtolabelimage', icon: 'dragtolabelimage', label: 'drag to label image', rowType: 'fr', mw: 12, dh: false },
  { id: 'dragtolist', icon: 'dragtolist', label: 'drag to list', rowType: 'fr', mw: 12, dh: false },
];

//CHECKPOINTS
export const supportedCheckpointWidgets = [
  { id: 'attestation', icon: 'attestation', rowType: 'none', mw: 4, dh: false },
  { id: 'memory', icon: 'memory', label: 'memory / matching', rowType: 'none', mw: 12, dh: false },
  {
    id: 'quizlistenuntimed',
    icon: 'quizlistenuntimed',
    label: 'quiz listen untimed',
    rowType: 'fr',
    mw: 12,
    dh: false,
  },
  { id: 'quiznonjudged', icon: 'quiznonjudged', label: 'quiz non-judged', rowType: 'fr', mw: 12, dh: false },
  { id: 'scratchreveal', icon: 'scratchreveal', label: 'scratch reveal', rowType: 'none', mw: 3, dh: false },
  { id: 'wordfrenzy', icon: 'wordfrenzy', label: 'word frenzy', rowType: 'fp', mw: 12, dh: true },
];

//SCORABLE QUIZZES & GAMES
export const supportedQuizGamesWidgets = [
  { id: 'branchingsummary', icon: 'branchingsummary', label: 'branching summary', rowType: 'fr', mw: 12, dh: true },
  {
    id: 'accordions-dragToOrder',
    icon: 'accordion-dragToOrder',
    label: 'drag to order',
    componentLayout: 'dragToOrder',
    rowType: 'none',
    mw: 6,
    dh: false,
  },
  {
    id: 'multicolumngridchecklist',
    icon: 'multicolumngridchecklist',
    label: 'multi-column grid checklist',
    rowType: 'fr',
    mw: 12,
    dh: false,
  },
  { id: 'peril', icon: 'peril', label: 'peril', rowType: 'fp', mw: 12, dh: true },
  { id: 'standardquiz', icon: 'standardquiz', label: 'standard quiz', rowType: 'fp', mw: 12, dh: false },
  { id: 'truefalsegrid', icon: 'truefalsegrid', label: 'true / false grid', rowType: 'none', mw: 6, dh: false },
];

//NAVIGATION
export const supportedTopicMenuWidgets = [
  { id: 'anchortag', icon: 'anchortag', label: 'anchor tag', rowType: 'none', mw: 1, dh: false },
  { id: 'button', icon: 'button', label: 'custom button', rowType: 'none', mw: 1, dh: false },
  { id: 'dropdownmenu', icon: 'dropdown', label: 'dopdown menu', rowType: 'none', mw: 1, dh: false },
  {
    id: 'topicmenuverticallist',
    icon: 'topicmenuverticallist',
    label: 'topic menu: vertical list',
    rowType: 'none',
    mw: 3,
    dh: false,
  },
  {
    id: 'topicmenuhorizontalmedia',
    icon: 'topicmenuhorizontalmedia',
    label: 'topic menu: horizontal media',
    rowType: 'none',
    mw: 12,
    dh: false,
  },
  {
    id: 'topicmenuverticalmedia',
    icon: 'topicmenuverticalmedia',
    label: 'topic menu: vertical media',
    rowType: 'none',
    mw: 6,
    dh: false,
  },
];

//DATA CAPTURING
export const supportedDataCapturingWidgets = [
  { id: 'formdatasubmit', icon: 'formdatasubmit', label: 'form data submit', rowType: 'none', mw: 6, dh: false },
  { id: 'opinionPoll', icon: 'poll', label: 'opinion poll', rowType: 'none', mw: 6, dh: false, disabled: false },
  { id: 'tipresponse', icon: 'tipresponse', label: 'text / response', rowType: 'none', mw: 4, dh: false },
  {
    id: 'tipresponse2',
    icon: 'tipresponse2',
    label: 'text / response media reveal',
    componentLayout: 'tipResponseReveal',
    rowType: 'none',
    mw: 6,
    dh: false,
  },
  {
    id: 'tipresponseoverview',
    icon: 'tipresponseoverview',
    label: 'text / response overview',
    rowType: 'none',
    mw: 6,
    dh: false,
  },
];

//SPECIALTY
export const supportedGeneralWidgets = [
  { id: 'fullpagewebsite', icon: 'fullpagewebsite', label: 'fullpage website', rowType: 'fp', mw: 12, dh: true },
  { id: 'htmlize', icon: 'htmlize', label: 'html', rowType: 'none', mw: 1, dh: false, disabled: false },
  { id: 'separator', icon: 'separator', label: 'separator', rowType: 'none', mw: 1, dh: false },
  { id: 'simpleTable', icon: 'table', label: 'simple table', rowType: 'none', mw: 3, dh: false },
];

export const accTypes = ['mediaL', 'mediaR']; //dragToOrder,"none","zoomL","zoomR","Simple","Accordion Right, Zoom Image Left","Accordion Left, Zoom Image Right" hidden from this view
export const accDesc = ['Accordion Right, Media Left', 'Accordion Left, Media Right'];

export const accZoomTypes = ['zoomL', 'zoomR'];
export const accZoomDesc = ['Accordion Right, Zoom Image Left', 'Accordion Left, Zoom Image Right'];

export const categorymatchingTypes = ['draggableL', 'draggableR'];
export const categorymatchingDesc = ['Draggables Left, Drop Zones Right', 'Draggables Right, Drop Zones Left'];

export const dragimagematchingTypes = ['draggableL', 'draggableR'];
export const dragimagematchingDesc = ['Draggables Left, Drop Zones Right', 'Draggables Right, Drop Zones Left'];

export const dragmatchingTypes = ['draggableL', 'draggableR'];
export const dragmatchingDesc = ['Draggables Left, Drop Zones Right', 'Draggables Right, Drop Zones Left'];

export const dragmediatomediaTypes = ['mediaR', 'mediaL'];
export const dragmediatomediaDesc = ['Draggables Left, Drop Zone Right', 'Draggables Right, Drop Zone Left'];

export const dragtolabelimageTypes = ['mediaR', 'mediaL'];
export const dragtolabelimageDesc = ['Draggables Left, Drop Zones Right', 'Draggables Right, Drop Zones Left'];

export const dragtolistTypes = ['mediaR', 'mediaL'];
export const dragtolistDesc = ['Draggables Left, Drop Zone Right', 'Draggables Right, Drop Zone Left'];

export const hotspotclicktellTypes = [3, 2, 1];
export const hotspotclicktellDesc = ['Guided, Hotspots Right', 'Guided, Hotspots Left', 'Default, Unguided'];

export const progclickTypes = [0, 1];
export const progclickDesc = ['', 'Lower Caption'];

export const quickhitmintextTypes = ['mediaR', 'mediaL', 'noExtMedia'];
export const quickhitmintextDesc = [
  'Click-tell Left, Media Right',
  'Click-tell Right, Media Left',
  'No External Media',
];

export const quiznonjudgedTypes = ['mediaL', 'mediaR'];
export const quiznonjudgedDesc = ['Media Left, Questions/Answers Right', 'Media Right, Questions/Answers Left'];

export const galleryTypes = [1, 2];
export const galleryDesc = ['Vertical Layout', 'Horizontal Layout'];

export const tabTypes = [0, 3];
export const tabDesc = ['Tabs Above Panel', 'Tabs Left of Panel'];

export const thumbnailmosaicTypes = ['horizontal-left', 'horizontal-right', 'vertical', 'vertical-multi'];
export const thumbnailmosaicDesc = [
  'Thumbnails Left, Media Right',
  'Thumbnails Right, Media Left',
  'Thumbnails Top, Media Bottom',
  'Thumbnails Top, Media Bottom, Multi-Level',
];

export const tipResponseDesc = ['Text / Response', 'Text / Response Media Reveal'];

export const allSupportedWidgets = [
  ...supportedBackgroundWidgets,
  ...supportedScrollingWidgets,
  ...supportedMediaWidgets,
  ...supportedClicktellWidgets,
  ...supportedSortableWidgets,
  ...supportedCheckpointWidgets,
  ...supportedQuizGamesWidgets,
  ...supportedTopicMenuWidgets,
  ...supportedDataCapturingWidgets,
  ...supportedGeneralWidgets,
];

const fontSizeOptions = ['default:Theme Default'];
for (let x = 8; x < 41; x++) {
  fontSizeOptions.push(x + 'px' + ':' + x + 'px');
}

export const fontSizeOpts = [...fontSizeOptions];

export const widgetLabelByType = (type) => {
  const foundResult = allSupportedWidgets.find((widget) => widget.id === type);
  if (foundResult) {
    return foundResult.label || type;
  }

  return type;
};

export const findWidgetInfoByType = (type) => {
  return allSupportedWidgets.find((widget) => widget.id === type) ?? null;
};

/**
 * Helper Functions
 */

export const addClass = (id, cl) => {
  if (document.getElementById(id)) {
    const obj = document.getElementById(id);
    if (!obj.classList.contains(cl)) {
      obj.className += ' ' + cl;
    }
  }
};

export const addClassNameClass = (id, cl) => {
  if (id.classList && !id.classList.contains(cl)) {
    id.className += ' ' + cl;
  }
};

export const formatBytes = (bytes, dec = 2, roundDown = false) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = dec < 0 ? 0 : dec;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const cleanBytes = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  if (roundDown) return Math.floor(cleanBytes) + ' ' + sizes[i];

  return cleanBytes + ' ' + sizes[i];
};

export const hasClass = (id, cl) => {
  if (document.getElementById(id)) {
    return document.getElementById(id).classList.contains(cl);
  }
};

export const randomString = (num) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
  const string_length = num;
  let randomstring = '';
  for (let i = 0; i < string_length; i++) {
    const rnum = Math.floor(Math.random() * chars.length);
    randomstring += chars.substring(rnum, rnum + 1);
  }

  return randomstring;
};

export const removeClass = (id, cl) => {
  if (document.getElementById(id)) {
    const obj = document.getElementById(id);
    if (obj.classList.contains(cl)) {
      obj.classList.remove(cl);
    }
  }
};

export const removeClassNameClass = (id, cl) => {
  if (id.classList && id.classList.contains(cl)) {
    id.classList.remove(cl);
  }
};

export const hiddenPathName = (pathName) => {
  let response = pathName;

  if (response) {
    const parts = pathName.split('.');
    let imageArray = false;
    if (parts.length && parts[0] == 'image') {
      imageArray = true;
    }

    if (parts.length > 0) {
      if (imageArray) {
        parts[0] = '_' + parts[0];
      } else {
        parts[parts.length - 1] = '_' + parts[parts.length - 1];
      }

      response = parts.join('.');
    }
  }

  return response;
};

export const stripHTML = (text) => {
  if (text === undefined) return '';
  const val = text
    .split('</p>')
    .join('</p> ')
    .split('<br>')
    .join('<br> ')
    .split('<br />')
    .join('<br> ')
    .split('<br/>')
    .join('<br> ');
  const div = val ? document.createElement('div') : '';
  if (div) {
    div.innerHTML = val;
  }

  return div ? div.textContent || div.innerText || '' : '';
};

export const requiredEditor = 'This field is required, but will not appear in the course.';
export const requiredEditorAlt = 'This field is required and will only display for screen readers.';

export const mediaOptionsLimited = ['fpo:FPO', 'bgimage:Image', 'pullquote:Pullquote', 'text:Text', 'bgvideo:Video'];
export const backgroundMediaOptions = [
  'none:None',
  'adobeanimate:Adobe Animate',
  'color:Color',
  'image:Image',
  'video:Video',
];
export const mediaOptions = [
  'none:None',
  'audio:Audio',
  'adobe-edge:Adobe Animate',
  'before-after:Before / After',
  'embed:Embed Video URL',
  'fpo:FPO',
  'image:Image',
  'panorama:Panorama',
  'pullquote:Pullquote',
  'text:Text',
  'slideshow:Timed Carousel',
  'video:Video',
  'zoom:Zoom',
];
export const mediaOptionsNoTC = [
  'none:None',
  'audio:Audio',
  'adobe-edge:Adobe Animate',
  'before-after:Before / After',
  'embed:Embed Video URL',
  'fpo:FPO',
  'image:Image',
  'panorama:Panorama',
  'pullquote:Pullquote',
  'text:Text',
  'video:Video',
  'zoom:Zoom',
];
//,"slideshow:Timed Carousel"

export const alignmentOptions = ['left:Left', 'center:Center', 'right:Right'];

/*
validate: (props={}) => {
    return [
        {
            test: 'textShadow',
            message: 'Please use this format: 8px 8px 8px #000000.'
        }
    ]
}
*/

export const defaultSettings = (fieldType, name, path, adv, animationOptions, comp) => {
  let caption = [
    {
      label: 'Caption',
      name: (fieldType != 'slideshow' ? name : '') + 'attr.caption',
      path: '',
      description: 'Add a text caption that displays under media.',
      placeholder: 'Add text here.',
      type: 'textarea-gen',
    },
    {
      label: 'Caption Alignment',
      name: (fieldType != 'slideshow' ? name : '') + 'attr.captionAlign',
      path: '',
      description: 'Align caption under media.',
      default: 'right',
      type: 'select',
      options: alignmentOptions,
    },
  ];
  if (comp == 'dragimagematching' || comp == 'dragmatching' || comp == 'cube') {
    caption = [];
  }

  let genAdvOpt = [
    {
      label: 'Margin Bottom',
      name: (fieldType != 'slideshow' ? name : '') + 'attr.marginB',
      path: '',
      description: 'Change the bottom margin (in pixels).',
      type: 'slider',
      decimal: false,
      default: 40,
      min: 0,
      max: 100,
      multiplier: 1,
      step: 1,
    },
    {
      label: 'Animation Type',
      name: (fieldType != 'slideshow' ? name : '') + 'settings.animType',
      path: '',
      description: 'Add an animation to the component.',
      type: 'select',
      default: '',
      options: animationOptions,
    },
    {
      label: 'Animation Duration',
      name: (fieldType != 'slideshow' ? name : '') + 'settings.animDuration',
      path: '',
      description: 'Increase the animation duration (in seconds).',
      type: 'slider',
      decimal: true,
      default: 0,
      min: 0,
      max: 3,
      multiplier: 1,
      step: 0.01,
    },
  ];
  if (comp == 'cube') {
    genAdvOpt = [];
  }

  const freefallparallax = [
    {
      label: 'Location',
      name: name + 'settings.slot',
      path: '',
      description: 'Choose a location to add the media to.',
      type: 'select',
      options: ['1:Main', '2:Top', '3:Middle', '4:Bottom'],
    },
  ];
  const quickhitmintext = [
    {
      label: 'Column',
      name: name + 'settings.column',
      path: '',
      description: 'Choose the column to add the media to.',
      type: 'select',
      default: 'media',
      options: ['accordion:Click-tell Column', 'media:Media Column'],
    },
  ];
  const progressionclicktell = [
    {
      label: 'Interaction Component Padding',
      name: name + 'settings.removePadding',
      path: '',
      description: 'Remove padding around component.',
      type: 'boolean',
      default: false,
    },
  ];
  let c = '';
  if (comp == 'freefallparallax') {
    c = freefallparallax;
  } else if (comp == 'quickhitmintext') {
    c = quickhitmintext;
  } else if (comp == 'progressionclicktell') {
    c = progressionclicktell;
  }

  switch (fieldType) {
  case 'adobe-edge': {
    let aa = [
      {
        label: 'Upload Adobe Animate Zip File',
        name: name + 'attr.folder',
        path: '',
        description: 'An index.html file is required.',
        type: 'fileuploadwebsite',
      },
      {
        label: 'Stage Width',
        name: name + 'attr.stageWidth',
        path: '',
        description: 'In pixels',
        type: 'slider',
        decimal: false,
        default: 730,
        min: 1,
        max: 1280,
        multiplier: 1,
        step: 1,
      },
      {
        label: 'Stage Height',
        name: name + 'attr.stageHeight',
        path: '',
        description: 'In pixels',
        type: 'slider',
        decimal: false,
        default: 414,
        min: 1,
        max: 1280,
        multiplier: 1,
        step: 1,
      },
      {
        label: 'Alternate Text',
        name: name + 'attr.alt',
        path: '',
        description: 'Add a media description for screen reader purposes.',
        placeholder: 'Add text here.',
        type: 'textarea-gen',
      },
    ];
    const aaAdv = [
      {
        label: 'Gate Component',
        name: name + 'settings.gated',
        path: '',
        description:
            'When switched on, this component will need to be completed before a gated page is unlocked. Code will need to be added to your Animate file. Contact the Chameleon Support staff for more information.',
        type: 'boolean',
        default: false,
      },
    ];
    const aaBg = [
      {
        label: 'Upload Adobe Animate Zip File',
        name: name + 'attr.folder',
        path: '',
        description: 'An index.html file is required.',
        type: 'fileuploadwebsite',
      },
      {
        label: 'Stage Width',
        name: name + 'attr.stageWidth',
        path: '',
        description: 'In pixels',
        type: 'slider',
        decimal: false,
        default: 1280,
        min: 1,
        max: 1280,
        multiplier: 1,
        step: 1,
      },
      {
        label: 'Stage Height',
        name: name + 'attr.stageHeight',
        path: '',
        description: 'In pixels',
        type: 'slider',
        decimal: false,
        default: 720,
        min: 1,
        max: 1280,
        multiplier: 1,
        step: 1,
      },
      {
        label: 'Background Position',
        name: name + 'attr.bgPosition',
        path: '',
        description: 'Change the Adobe Animate file position.',
        type: 'drennersquare',
        default: 'center center',
      },
      {
        label: 'Mobile Background Position',
        name: name + 'attr.bgPositionMobile',
        path: '',
        description: 'Change the mobile Adobe Animate file position.',
        type: 'drennersquare',
        default: 'center center',
      },
      {
        label: 'Background Cover/Contain',
        name: name + 'attr.bgSize',
        path: '',
        description: 'Change the Adobe Animate file to cover or contain.',
        type: 'radio',
        radioType: 'bg',
        default: 'cover',
        options: ['cover:Cover', 'contain:Contain'],
      },
    ];
    if (comp === 'backgroundmedia') {
      aa = aaBg;
    } else if (adv) {
      if (c) {
        aa = [].concat(c, aa, caption, aaAdv, genAdvOpt);
      } else {
        aa = [].concat(aa, caption, aaAdv, genAdvOpt);
      }
    } else if (c) {
      aa = [].concat(c, aa, caption);
    } else {
      aa = [].concat(aa, caption);
    }

    return aa;
  }

  case 'audio': {
    let audio = [
      {
        label: 'Upload MP3',
        name: name + 'attr.audio',
        path: '',
        description: '',
        remove: false,
        type: 'fileuploadaudio',
      },
      {
        label: 'Upload Poster Image',
        name: 'image',
        path: path + 'attr',
        description: [''],
        type: 'fileuploadimage',
      },
      {
        label: 'Add Subtitles',
        name: name + 'attr.subtitle',
        path: '',
        description: ['Upload a WebVTT file.'],
        type: 'fileuploadvtt',
        remove: true,
      },
      {
        label: 'Audio Transcript Title',
        name: name + 'attr.audioTranscriptTitle',
        path: '',
        description: 'Add an audio transcript title that will display in an overlay above the audio transcript.',
        placeholder: 'Add text here.',
        type: 'text',
      },
      {
        label: 'Audio Transcript',
        name: name + 'attr.audioTranscript',
        path: '',
        description: 'Add an audio transcript that will display in an overlay.',
        placeholder: 'Add text here.',
        type: 'wysiwyg',
      },
      {
        label: 'Alternate Text',
        name: name + 'attr.alt',
        path: '',
        description: 'Add a media description for screen reader purposes.',
        placeholder: 'Add text here.',
        type: 'textarea-gen',
      },
    ];
    const audioAdv = [
      {
        label: 'Contain Poster Image',
        name: name + 'settings.containPoster',
        path: '',
        description: 'Force the entire poster image to display in a 16 x 9 ratio rectangle.',
        type: 'boolean',
        default: false,
      },
      {
        label: 'Show Play Button over Poster Image',
        name: name + 'settings.showPlayBtn',
        path: '',
        description: "Toggle the play button's opacity on/off over the poster image.",
        type: 'boolean',
        default: true,
      },
    ];
    if (adv) {
      if (c) {
        audio = [].concat(c, audio, caption, audioAdv, genAdvOpt);
      } else {
        audio = [].concat(audio, caption, audioAdv, genAdvOpt);
      }
    } else if (c) {
      audio = [].concat(c, audio, caption);
    } else {
      audio = [].concat(audio, caption);
    }

    return audio;
  }

  case 'before-after': {
    let ba = [
      {
        label: 'Upload Before Image',
        name: 'image.0',
        path: path + 'attr',
        description: ["Add the 'before' image."],
        type: 'fileuploadimage',
      },
      {
        label: 'Upload After Image',
        name: 'image.1',
        path: path + 'attr',
        description: ["Add the 'after' image."],
        type: 'fileuploadimage',
      },
      {
        label: 'Alternate Text',
        name: name + 'attr.alt',
        path: '',
        description: 'Add a media description for screen reader purposes.',
        placeholder: 'Add text here.',
        type: 'textarea-gen',
      },
    ];
    const baAdv = [
      {
        label: 'Initial Position',
        name: name + 'settings.initialX',
        path: '',
        description: 'In percentage',
        type: 'slider',
        decimal: false,
        default: 50,
        min: 0,
        max: 100,
        multiplier: 1,
        step: 1,
      },
      {
        label: 'Common Term Override: original',
        name: name + 'attr.commonTerms.original',
        path: '',
        description: 'Modify this common term.',
        placeholder: 'Add text here.',
        type: 'text',
        wide: true,
      },
      {
        label: 'Common Term Override: modified',
        name: name + 'attr.commonTerms.modified',
        path: '',
        description: 'Modify this common term.',
        placeholder: 'Add text here.',
        type: 'text',
        wide: true,
      },
      {
        label: 'Bold Labels',
        name: name + 'settings.textBold',
        path: '',
        description: 'Add bold styling to the image labels.',
        type: 'boolean',
        default: false,
      },
      {
        label: 'Label Color',
        name: name + 'settings.textColor',
        path: '',
        description: 'Change the image label color.',
        type: 'color',
        default: '#ffffff',
        states: ['Enabled', 'Disabled'],
      },
      {
        label: 'Label Text Shadow',
        name: name + 'settings.textShadow',
        path: '',
        description: 'Toggle the text shadow on/off.',
        type: 'boolean',
        default: true,
      },
      {
        label: 'Border Color',
        name: name + 'settings.borderColor',
        path: '',
        description: 'Add a border color.',
        type: 'color',
        default: 'transparent',
        states: ['Enabled', 'Disabled'],
      },
      {
        label: 'Border Radius',
        name: name + 'settings.borderRadius',
        path: '',
        description: 'Add a border radius. IE: 0px 0px 20px 20px, or 0% 50% 0% 50%',
        placeholder: 'Add text here.',
        type: 'text',
        wide: false,
      },
      {
        label: 'Border Size',
        name: name + 'settings.borderSize',
        path: '',
        description: 'Add a border size (in pixels).',
        type: 'slider',
        decimal: false,
        default: 1,
        min: 0,
        max: 30,
        multiplier: 1,
        step: 1,
      },
      {
        label: 'Border Style',
        name: name + 'settings.borderStyle',
        path: '',
        description: 'Add a border style.',
        type: 'select',
        default: 'none',
        options: [
          'none:None',
          'dotted:Dotted',
          'dashed:Dashed',
          'solid:Solid',
          'double:Double',
          'groove:Groove',
          'ridge:Ridge',
          'inset:Inset',
          'outset:Outset',
        ],
      },
      {
        label: 'Border Bottom',
        name: name + 'settings.borderB',
        path: '',
        description: 'Add a bottom border.',
        type: 'boolean',
        default: true,
      },
      {
        label: 'Border Left',
        name: name + 'settings.borderL',
        path: '',
        description: 'Add a left border.',
        type: 'boolean',
        default: false,
      },
      {
        label: 'Border Right',
        name: name + 'settings.borderR',
        path: '',
        description: 'Add a right border.',
        type: 'boolean',
        default: false,
      },
      {
        label: 'Border Top',
        name: name + 'settings.borderT',
        path: '',
        description: 'Add a top border.',
        type: 'boolean',
        default: false,
      },
      {
        label: 'Background Border Color',
        name: name + 'settings.bgBorderColor',
        path: '',
        description: "Add a background color that's the same color as the border.",
        type: 'boolean',
        default: false,
      },
      {
        label: 'Padding',
        name: name + 'settings.padding',
        path: '',
        description: 'Add padding (top right bottom left). Ex: 20px 0px 20px 0px',
        placeholder: 'Add text here.',
        type: 'text',
        wide: false,
      },
      {
        label: 'Drop Shadow',
        name: name + 'settings.boxShadow',
        path: '',
        description: 'Add a box shadow. IE: 8px 8px 8px 0px rgba(0,0,0,0.75)',
        placeholder: 'Add text here.',
        type: 'text',
        wide: true,
      },
    ];
    if (adv) {
      if (c) {
        ba = [].concat(c, ba, caption, baAdv, genAdvOpt);
      } else {
        ba = [].concat(ba, caption, baAdv, genAdvOpt);
      }
    } else if (c) {
      ba = [].concat(c, ba, caption);
    } else {
      ba = [].concat(ba, caption);
    }

    return ba;
  }

  case 'embed': {
    let embed = [
      {
        label: 'Add URL',
        name: name + 'attr.embed.url',
        path: '',
        description: 'Add a YouTube, Vimeo or SketchFab link.',
        type: 'embedexternalvideo',
        wide: true,
      },
      {
        label: 'Autoplay',
        name: name + 'attr.embed.autoplay',
        path: '',
        description: 'Autoplay the embedded resource',
        type: 'boolean',
        default: false,
      },
      {
        label: 'Alternate Text',
        name: name + 'attr.alt',
        path: '',
        description: 'Add a media description for screen reader purposes.',
        placeholder: 'Add text here.',
        type: 'textarea-gen',
      },
    ];
    if (adv) {
      if (c) {
        embed = [].concat(c, embed, caption, genAdvOpt);
      } else {
        embed = [].concat(embed, caption, genAdvOpt);
      }
    } else if (c) {
      embed = [].concat(c, embed, caption);
    } else {
      embed = [].concat(embed, caption);
    }

    return embed;
  }

  case 'fpo': {
    let fpo = [];
    if (adv) {
      if (c) {
        fpo = [].concat(c, caption, genAdvOpt);
      } else {
        fpo = [].concat(caption, genAdvOpt);
      }
    } else if (c) {
      fpo = [].concat(c, caption);
    } else {
      fpo = [].concat(fpo, caption);
    }

    return fpo;
  }

  case 'image': {
    let img = [
      {
        label: 'Upload Image',
        name: 'image',
        path: path + 'attr',
        description: [''],
        type: 'fileuploadimage',
      },
      {
        label: 'Alternate Text',
        name: name + 'attr.alt',
        path: '',
        description: 'Add a media description for screen reader purposes.',
        placeholder: 'Add text here.',
        type: 'textarea-gen',
      },
    ];
    const imgAdv = [
      {
        label: 'Common Term Override: pinch to zoom',
        name: name + 'attr.commonTerms.pinchToZoom',
        path: '',
        description: 'Modify this common term.',
        placeholder: 'Add text here.',
        type: 'text',
        wide: true,
      },
      {
        label: 'Common Term Override: drag to pan',
        name: name + 'attr.commonTerms.dragToPan',
        path: '',
        description: 'Modify this common term.',
        placeholder: 'Add text here.',
        type: 'text',
        wide: true,
      },
      {
        label: 'Border Color',
        name: name + 'settings.borderColor',
        path: '',
        description: 'Add a border color.',
        type: 'color',
        default: 'transparent',
        states: ['Enabled', 'Disabled'],
      },
      {
        label: 'Border Radius',
        name: name + 'settings.borderRadius',
        path: '',
        description: 'Add a border radius. IE: 0px 0px 20px 20px, or 0% 50% 0% 50%',
        placeholder: 'Add text here.',
        type: 'text',
        wide: false,
      },
      {
        label: 'Border Size',
        name: name + 'settings.borderSize',
        path: '',
        description: 'Add a border size (in pixels).',
        type: 'slider',
        decimal: false,
        default: 1,
        min: 0,
        max: 30,
        multiplier: 1,
        step: 1,
      },
      {
        label: 'Border Style',
        name: name + 'settings.borderStyle',
        path: '',
        description: 'Add a border style.',
        type: 'select',
        default: 'none',
        options: [
          'none:None',
          'dotted:Dotted',
          'dashed:Dashed',
          'solid:Solid',
          'double:Double',
          'groove:Groove',
          'ridge:Ridge',
          'inset:Inset',
          'outset:Outset',
        ],
      },
      {
        label: 'Border Bottom',
        name: name + 'settings.borderB',
        path: '',
        description: 'Add a bottom border.',
        type: 'boolean',
        default: true,
      },
      {
        label: 'Border Left',
        name: name + 'settings.borderL',
        path: '',
        description: 'Add a left border.',
        type: 'boolean',
        default: false,
      },
      {
        label: 'Border Right',
        name: name + 'settings.borderR',
        path: '',
        description: 'Add a right border.',
        type: 'boolean',
        default: false,
      },
      {
        label: 'Border Top',
        name: name + 'settings.borderT',
        path: '',
        description: 'Add a top border.',
        type: 'boolean',
        default: false,
      },
      {
        label: 'Drop Shadow',
        name: name + 'settings.boxShadow',
        path: '',
        description: 'Add a box shadow. IE: 8px 8px 8px 0px rgba(0,0,0,0.75)',
        placeholder: 'Add text here.',
        type: 'text',
        wide: true,
      },
      {
        label: 'Background Border Color',
        name: name + 'settings.bgBorderColor',
        path: '',
        description: "Add a background color that's the same color as the border.",
        type: 'boolean',
        default: false,
      },
    ];
    let imgAdvGen = [
      {
        label: 'Phone: Max Image Height',
        name: name + 'attr.maxMobileH',
        path: '',
        description:
            "Add a maximum height. Select '-1' to disable this option. This option is meant for tall, portrait images.",
        type: 'slider',
        decimal: false,
        default: -1,
        min: -1,
        max: 800,
        multiplier: 1,
        step: 1,
      },
      {
        label: 'Phone: Max Image Height Alignment',
        name: name + 'attr.maxMobileHAlign',
        path: '',
        description: 'Align the image within the available space when a max image height has been added.',
        type: 'select',
        default: 'center',
        options: alignmentOptions,
      },
    ];
    const imgBg = [
      {
        label: 'Upload Image',
        name: 'image',
        path: path + 'attr',
        description: [''],
        type: 'fileuploadimage',
      },
      {
        label: 'Background Position',
        name: name + 'attr.bgPosition',
        path: '',
        description: 'Change the background image pinning.',
        type: 'drennersquare',
        default: 'center center',
      },
      {
        label: 'Mobile Background Position',
        name: name + 'attr.bgPositionMobile',
        path: '',
        description: 'Change the mobile background image pinning.',
        type: 'drennersquare',
        default: 'center center',
      },
      {
        label: 'Background Cover/Contain',
        name: name + 'attr.bgSize',
        path: '',
        description: 'Change the background image size to cover or contain.',
        type: 'radio',
        radioType: 'bg',
        default: 'cover',
        options: ['cover:Cover', 'contain:Contain'],
      },
    ];

    if (comp == 'cube') {
      imgAdvGen = [];
    }

    if (comp === 'backgroundmedia') {
      img = imgBg;
    } else if (adv) {
      if (c) {
        img = [].concat(c, img, caption, imgAdv, imgAdvGen, genAdvOpt);
      } else {
        img = [].concat(img, caption, imgAdv, imgAdvGen, genAdvOpt);
      }
    } else if (c) {
      img = [].concat(c, img, caption);
    } else {
      img = [].concat(img, caption);
    }

    return img;
  }

  case 'panorama': {
    let pano = [
      {
        label: 'Upload Equirectangular JPG Image',
        name: 'image',
        path: path + 'attr',
        description: [''],
        type: 'fileuploadimage',
        allow: ['.jpg', '.jpeg'],
      },
      {
        label: 'Alternate Text',
        name: name + 'attr.alt',
        path: '',
        description: 'Add a media description for screen reader purposes.',
        placeholder: 'Add text here.',
        type: 'textarea-gen',
      },
    ];
    const panoAdv = [
      {
        label: 'Common Term Override: loading',
        name: name + 'attr.commonTerms.loading',
        path: '',
        description: 'Modify this common term.',
        placeholder: 'Add text here.',
        type: 'text',
        wide: true,
      },
      {
        label: 'Yaw',
        name: name + 'attr.config.0.yaw',
        path: '',
        description: 'Change the initial position (in degrees: -180 through 180)',
        type: 'slider',
        decimal: false,
        default: 0,
        min: -180,
        max: 180,
        multiplier: 1,
        step: 1,
      },
      {
        label: 'Pitch',
        name: name + 'attr.config.0.pitch',
        path: '',
        description: 'Change the initial position (in degrees: -90 through 90)',
        type: 'slider',
        decimal: false,
        default: 0,
        min: -90,
        max: 90,
        multiplier: 1,
        step: 1,
      },
      {
        label: 'HFOV',
        name: name + 'attr.config.0.hfov',
        path: '',
        description:
            'Change the initial horizontal field of view (in degrees: 50 through 120) (THIS NUMBER IS HARD TO FIGURE OUT)',
        type: 'slider',
        decimal: false,
        default: 120,
        min: 50,
        max: 120,
        multiplier: 1,
        step: 1,
      },
      {
        label: 'Automatic Rotation',
        name: name + 'attr.config.0.autoRotate',
        path: '',
        description: 'Change the automatic rotation speed/direction (-20 through 20; zero is none)',
        type: 'slider',
        decimal: false,
        default: 0,
        min: -20,
        max: 20,
        multiplier: 1,
        step: 1,
      },
      {
        label: 'Automatic Rotation Inactive Delay',
        name: name + 'attr.config.0.autoRotateInactivityDelay',
        path: '',
        description:
            'Add a delay to reinitiate automatic rotation after the user last interacted with the component. (in seconds; zero is none)',
        type: 'slider',
        decimal: true,
        default: 0,
        min: 0,
        max: 50,
        multiplier: 1,
        step: 0.01,
      },
      {
        label: 'North Offset',
        name: name + 'attr.config.0.northOffset',
        path: '',
        description: 'Set a north offset for devices that allow it (in degrees: -180 through 180).',
        type: 'slider',
        decimal: false,
        default: 0,
        min: -180,
        max: 180,
        multiplier: 1,
        step: 1,
      },
      {
        label: 'Show Controls',
        name: name + 'attr.config.0.showControls',
        path: '',
        description: 'Toggle the controls.',
        type: 'boolean',
        default: false,
      },
    ];
    if (adv) {
      if (c) {
        pano = [].concat(c, pano, caption, panoAdv, genAdvOpt);
      } else {
        pano = [].concat(pano, caption, panoAdv, genAdvOpt);
      }
    } else if (c) {
      pano = [].concat(c, pano, caption);
    } else {
      pano = [].concat(pano, caption);
    }

    return pano;
  }

  case 'pullquote': {
    let pq = [
      {
        label: 'Text',
        name: name + 'attr.text',
        path: '',
        description: '',
        placeholder: 'Add text here.',
        type: 'wysiwyg',
      },
    ];
    if (adv) {
      if (c) {
        pq = [].concat(c, pq, caption, genAdvOpt);
      } else {
        pq = [].concat(pq, caption, genAdvOpt);
      }
    } else if (c) {
      pq = [].concat(c, pq, caption);
    } else {
      pq = [].concat(pq, caption);
    }

    return pq;
  }

  case 'text': {
    let txt = [
      {
        label: 'Text',
        name: name + 'attr.text',
        path: '',
        description: '',
        placeholder: 'Add text here.',
        type: 'wysiwyg',
      },
      {
        label: 'Link Text Color',
        name: name + 'attr.linkTextColor',
        path: '',
        description: 'Change the link text colors in the text block above.',
        type: 'color',
        palette: [],
        default: '#000000',
        states: ['Enabled', 'Disabled'],
      },
      {
        label: 'Link Background Color',
        name: name + 'attr.linkBgColor',
        path: '',
        description: 'Change the link background colors in the text block above.',
        type: 'color',
        palette: [],
        default: '#FFFFFF',
        states: ['Enabled', 'Disabled'],
      },
      {
        label: 'List Item Bullet Color',
        name: name + 'attr.listBulletColor',
        path: '',
        description: 'Change the list item bullet colors in the text block above.',
        type: 'color',
        palette: [],
        default: '#000000',
        states: ['Enabled', 'Disabled'],
      },
    ];
    const txtAdv = [
      {
        label: 'Horizontal Alignment',
        name: name + 'settings.align',
        path: '',
        description: "Change the text's horizontal alignment.",
        type: 'select',
        default: 'left',
        options: alignmentOptions,
      },
      {
        label: 'Padding',
        name: name + 'settings.padding',
        path: '',
        description: 'Add padding (top right bottom left). Ex: 20px 0px 20px 0px',
        placeholder: 'Add text here.',
        type: 'text',
        wide: false,
      },
      {
        label: 'Text Shadow',
        name: name + 'settings.textShadow',
        path: '',
        description: 'Add shadow. Ex: 8px 8px 8px #000000',
        placeholder: 'Add text here.',
        type: 'text',
        wide: false,
      },
      {
        label: 'Background Color',
        name: name + 'settings.bgColor',
        path: '',
        description: 'Change the background color.',
        type: 'color',
        default: 'transparent',
        states: ['Enabled', 'Disabled'],
      },
      {
        label: 'Background Opacity',
        name: name + 'settings.bgOpacity',
        path: '',
        description: 'Change the background opacity.',
        type: 'slider',
        decimal: false,
        default: 1,
        min: 0,
        max: 1,
        multiplier: 100,
        step: 0.01,
      },
    ];
    if (adv) {
      if (c) {
        txt = [].concat(c, txt, txtAdv, genAdvOpt);
      } else {
        txt = [].concat(txt, txtAdv, genAdvOpt);
      }
    } else if (c) {
      txt = [].concat(c, txt);
    } else {
      txt = [].concat(txt);
    }

    return txt;
  }

  case 'slideshow': {
    let sls = [
      {
        label: 'Image',
        type: 'repeater-sub',
        name: 'componentSet',
        path: 'componentSet',
        tc: true,
        fields: [
          {
            label: 'Title',
            name: 'title',
            path: 'attr',
            description: requiredEditor,
            placeholder: 'Add text here.',
            type: 'text',
            wide: true,
          },
          {
            label: 'Upload Image',
            name: 'image',
            path: 'attr',
            description: [''],
            type: 'fileuploadimage',
          },
          {
            label: 'Alternate Text',
            name: 'alt',
            path: 'attr',
            description: 'Add a media description for screen reader purposes.',
            placeholder: 'Add text here.',
            type: 'textarea-gen',
          },
        ],
      },
      {
        label: 'Title',
        name: 'attr.title',
        path: '',
        description: requiredEditorAlt,
        placeholder: 'Add text here.',
        type: 'textarea-gen',
      },
    ];
    if (adv) {
      if (c) {
        sls = [].concat(c, sls, caption, genAdvOpt);
      } else {
        sls = [].concat(sls, caption, genAdvOpt);
      }
    } else if (c) {
      sls = [].concat(c, sls, caption);
    } else {
      sls = [].concat(sls, caption);
    }

    return sls;
  }

  case 'video': {
    let video = [
      {
        label: 'Upload MP4',
        name: name + 'attr.video',
        path: '',
        description: '',
        type: 'fileuploadvideo',
      },
      {
        label: 'Upload Poster Image',
        name: 'image',
        path: path + 'attr',
        description: [''],
        type: 'fileuploadimage',
      },
      {
        label: 'Add Subtitles',
        name: name + 'attr.subtitle',
        path: '',
        description: ['Upload a WebVTT file.'],
        type: 'fileuploadvtt',
      },
      {
        label: 'Audio Transcript Title',
        name: name + 'attr.audioTranscriptTitle',
        path: '',
        description: 'Add an audio transcript title that will display in an overlay above the audio transcript.',
        placeholder: 'Add text here.',
        type: 'text',
      },
      {
        label: 'Audio Transcript',
        name: name + 'attr.audioTranscript',
        path: '',
        description: 'Add an audio transcript that will display in an overlay.',
        placeholder: 'Add text here.',
        type: 'wysiwyg',
      },
      {
        label: 'Alternate Text',
        name: name + 'attr.alt',
        path: '',
        description: 'Add a media description for screen reader purposes.',
        placeholder: 'Add text here.',
        type: 'textarea-gen',
      },
    ];
    const videoAdv = [
      {
        label: 'Looping Video',
        name: name + 'settings.gifv',
        path: '',
        description: 'Toggle autoplay, muted audio, and looping.',
        type: 'boolean',
        default: false,
      },
      {
        label: 'Display Video in a Modal',
        name: name + 'settings.popup',
        path: '',
        description: 'When the poster image is clicked, the video will display in a modal instead of in-line.',
        type: 'boolean',
        default: false,
      },
      {
        label: 'Contain Poster Image',
        name: name + 'settings.containPoster',
        path: '',
        description: 'Force the entire poster image to display in a 16 x 9 ratio rectangle.',
        type: 'boolean',
        default: false,
      },
      {
        label: 'Show Play Button over Poster Image',
        name: name + 'settings.showPlayBtn',
        path: '',
        description: "Toggle the play button's opacity on/off over the poster image.",
        type: 'boolean',
        default: true,
      },
      {
        label: 'Video Background Color',
        name: name + 'attr.videoBgColor',
        path: '',
        description: "Select the video's background color.",
        type: adv ? 'color' : 'remove',
        palette: [],
        default: '#000000',
        states: ['Enabled', 'Disabled'],
      },
    ];
    const videoBg = [
      {
        label: 'Upload MP4',
        name: name + 'attr.video',
        path: '',
        description: '',
        type: 'fileuploadvideo',
      },
      {
        label: 'Upload Poster Image',
        name: 'image',
        path: path + 'attr',
        description: [''],
        type: 'fileuploadimage',
      },
      {
        label: 'Loop',
        name: name + 'attr.loop',
        path: '',
        description: 'Toggle video looping.',
        type: 'boolean',
        default: true,
      },
      {
        label: 'Alternate Background Position',
        name: name + 'attr.bgPosition',
        path: '',
        description: 'Change the alternate background image pinning.',
        type: 'drennersquare',
        default: 'center center',
      },
      {
        label: 'Mobile Alternate Background Position',
        name: name + 'attr.bgPositionMobile',
        path: '',
        description: 'Change the mobile alternate background image pinning.',
        type: 'drennersquare',
        default: 'center center',
      },
    ];
    if (comp === 'backgroundmedia') {
      video = videoBg;
    } else if (adv) {
      if (c) {
        video = [].concat(c, video, caption, videoAdv, genAdvOpt);
      } else {
        video = [].concat(video, caption, videoAdv, genAdvOpt);
      }
    } else if (c) {
      video = [].concat(c, video, caption);
    } else {
      video = [].concat(video, caption);
    }

    return video;
  }

  case 'zoom': {
    let zoom = [
      {
        label: 'Upload Small Image',
        name: 'image.0',
        path: path + 'attr',
        description: ["Add the 'small' image."],
        type: 'fileuploadimage',
        defMedia: 'biZoom',
      },
      {
        label: 'Upload Large Image',
        name: 'image.1',
        path: path + 'attr',
        description: ["Add the 'large' image."],
        type: 'fileuploadimage',
        defMedia: 'biZoom2',
      },
      {
        label: 'Display Zoom Button',
        name: name + 'settings.zoomButton',
        path: '',
        description: 'Toggle the zoom button that appears over the image on/off',
        type: 'boolean',
        default: true,
      },
      {
        label: 'Alternate Text',
        name: name + 'attr.alt',
        path: '',
        description: 'Add a media description for screen reader purposes.',
        placeholder: 'Add text here.',
        type: 'textarea-gen',
      },
    ];
    const zoomAdv = [
      {
        label: 'Common Term Override: pinch to zoom',
        name: name + 'attr.commonTerms.pinchToZoom',
        path: '',
        description: 'Modify this common term.',
        placeholder: 'Add text here.',
        type: 'text',
        wide: true,
      },
      {
        label: 'Common Term Override: drag to pan',
        name: name + 'attr.commonTerms.dragToPan',
        path: '',
        description: 'Modify this common term.',
        placeholder: 'Add text here.',
        type: 'text',
        wide: true,
      },
      {
        label: 'Border Color',
        name: name + 'settings.borderColor',
        path: '',
        description: 'Add a border color.',
        type: 'color',
        default: 'transparent',
        states: ['Enabled', 'Disabled'],
      },
      {
        label: 'Border Radius',
        name: name + 'settings.borderRadius',
        path: '',
        description: 'Add a border radius. IE: 0px 0px 20px 20px, or 0% 50% 0% 50%',
        placeholder: 'Add text here.',
        type: 'text',
        wide: false,
      },
      {
        label: 'Border Size',
        name: name + 'settings.borderSize',
        path: '',
        description: 'Add a border size (in pixels).',
        type: 'slider',
        decimal: false,
        default: 1,
        min: 0,
        max: 30,
        multiplier: 1,
        step: 1,
      },
      {
        label: 'Border Style',
        name: name + 'settings.borderStyle',
        path: '',
        description: 'Add a border style.',
        type: 'select',
        default: 'none',
        options: [
          'none:None',
          'dotted:Dotted',
          'dashed:Dashed',
          'solid:Solid',
          'double:Double',
          'groove:Groove',
          'ridge:Ridge',
          'inset:Inset',
          'outset:Outset',
        ],
      },
      {
        label: 'Border Bottom',
        name: name + 'settings.borderB',
        path: '',
        description: 'Add a bottom border.',
        type: 'boolean',
        default: true,
      },
      {
        label: 'Border Left',
        name: name + 'settings.borderL',
        path: '',
        description: 'Add a left border.',
        type: 'boolean',
        default: false,
      },
      {
        label: 'Border Right',
        name: name + 'settings.borderR',
        path: '',
        description: 'Add a right border.',
        type: 'boolean',
        default: false,
      },
      {
        label: 'Border Top',
        name: name + 'settings.borderT',
        path: '',
        description: 'Add a top border.',
        type: 'boolean',
        default: false,
      },
      {
        label: 'Drop Shadow',
        name: name + 'settings.boxShadow',
        path: '',
        description: 'Add a box shadow. IE: 8px 8px 8px 0px rgba(0,0,0,0.75)',
        placeholder: 'Add text here.',
        type: 'text',
        wide: true,
      },
    ];
    if (adv) {
      if (c) {
        zoom = [].concat(c, zoom, caption, zoomAdv, genAdvOpt);
      } else {
        zoom = [].concat(zoom, caption, zoomAdv, genAdvOpt);
      }
    } else if (c) {
      zoom = [].concat(c, zoom, caption);
    } else {
      zoom = [].concat(zoom, caption);
    }

    return zoom;
  }

  default:
    return {};
  }
};

export const biAnimate = appUrl + 'core/defaults/biAnimate';
export const biAudio = appUrl + 'core/defaults/biAudio.mp3';
export const biEmbed = 'https://sketchfab.com/models/81a40cfcc5754e508ee808b08f1b754e';
export const biVideo = appUrl + 'core/defaults/biVideo.mp4';
export const biwwLogo = appUrl + 'core/defaults/biLogo.jpg';
export const biwwLogo2 = appUrl + 'core/defaults/biLogo2.jpg';
export const biZoom = appUrl + 'core/defaults/biZoomS.jpg';
export const biZoom2 = appUrl + 'core/defaults/biZoomL.jpg';

export const states = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const padLRVals =
  'Rems are relative to the base body value set in the theme (ie: 16px x .75rem = 12px). Values are (3XSmall: .5rem, 2XSmall: .75rem, XSmall: 1rem, Small: 2rem, Medium: 3rem, Large: 5rem, and XLarge: 9rem).';
export const padBVals =
  'Values are (Small: 32px, Medium: 50px, Medium/Large: 64px, Large: 80px, XLarge: 110px, 2XLarge: 140px).';
export const padTVals =
  'Values are (Small: 32px, Medium: 50px, Medium/Large: 64px, Large: 80px, XLarge: 110px, 2XLarge: 140px).';

export const addInteractionTitle = 'Add the interaction title.';
export const addAccordionTitle = 'Add the accordion title.';

export const tooltips = {
  columnSettings:
    'Containers in rows with more than one container can be rearranged within that row by drag/dropping. Their settings can also be changed.',
  compSettings:
    'Component settings can be changed by clicking on the component card. These cards may be reordered or moved to different rows/containers by drag/dropping. The delete button will remove the component from the container.',
  containerNum: 'Choose the number of containers/columns for this specific row to put components into.',
  courseLinks:
    'View this course in a pre environment (currently-edited state), a stage environment (review state), or the prod environment (live state). The course will need to be migrated to stage and prod for those links to become active.',
  gearIcon: 'Gear icons allow you to change settings.',
  noHomepage: "Topic one page one will become the course's landing page.",
  popupLink:
    'The following links can be added here:<ol><li>External URL: https://www...</li><li>Anchor Tag ID: #anchorId</li><li>Topic/Page: #topicPage(2,4)</li><li>Home: #goHome</li><li>Next Page: #navNextPage</li><li>Previous Page: #navPrevPage</li><li>Print Certificate: #printCertificate</li><li>Show Glossary: #showGlossary</li><li>Show Languages: #showLanguages</li><li>Show Menu: #showMenu</li><li>Show Objectives: #showObjectives</li><li>Show Resources: #showResources</li><li>Show Search: #showSearch</li></ol>',
  pageButton:
    'Pages can be drag/dropped into the same or different topics, exported/viewed, duplicated, and deleted. Selecting a page will display the page structure/settings to the right.',
  rowSetting:
    'Rows can be copied and will appear at the bottom of the page, reordered by drag/dropping, can have settings changed, and can be deleted.',
  settingsPanel:
    "All settings for this object / component can be modified here. Go to 'Course Settings' and switch 'Advanced Fields' on to view hidden settings. For more editing space, click the <-|-> icon at the top/right of this panel.",
  showAdvanced: 'Advanced Course Options contain lesser-used options that can be changed in a course.',
  topicButton:
    'Topics can be rearranged by drag/dropping, have settings changed, and deleted. Deleting a topic will delete all pages within it.',
  wysiwyg: 'Keyboard commands: CTRL b: Bold, CTRL i: Italicize, SHIFT >: Indent, and SHIFT <: Outdent',
  transEditor: 'View text styles/formatting on the left side. Translate content on the right side.',
};

export const lightOrDark = (color) => {
  try {
    let r, g, b;

    if (color.match(/^rgb/)) {
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));
      r = color >> 16;
      g = (color >> 8) & 255;
      b = color & 255;
    }

    const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    if (hsp > 127.5) {
      return 'light';
    } else {
      return 'dark';
    }
  } catch (e) {
    console.error('Error in lightOrDark', color, e);

    return 'dark';
  }
};

export const faceNames = ['Side: Front', 'Side: Top', 'Side: Left', 'Side: Bottom', 'Side: Right', 'Side: Back'];

export const getWidth = () => {
  if (self.innerWidth) {
    return self.innerWidth;
  } else if (document.documentElement && document.documentElement.clientWidth) {
    return document.documentElement.clientWidth;
  } else if (document.body) {
    return document.body.clientWidth;
  }
};

export const addCommas = (val) => {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
