import React, { useEffect, useState, useRef } from 'react';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import ToastNotification from '@/shared/components/ToastNotification/ToastNotification';
import './Notifications.scss';
import ConcedeSessionNotification from './special/ConcedeSessionNotification';
import notifySound from './notification-sound.mp3';
import notify from '@/shared/helpers/notification_helper';
import CardToastNotification from '@/shared/components/ToastNotification/CardToastNotification';

const Notifications = (props) => {
  const [recentMessage, setRecentMessage] = useState(null);

  const messagesColumn = useRef(null);

  const [messages, setMessages] = useState([
    // {
    // text:'Test Message',
    // id:12312
    // },
    // {
    // text:'Test Message 2',
    // id:12313
    // },
    // {
    // text:'Test Message 3 ',
    // type:'error',
    // id:12314
    // },
    // {
    // text:'Test Message 4',
    // type:'warning',
    // id:12315
    // },
    // {
    //     text: 'Test Message 4',
    //     type: 'warning',
    //     special: 'prompt',
    //     persist: true,
    //     modal:true,
    //     action: (option) => { console.log(option + ' clicked') },
    //     prompt: {
    //         text: 'Select an option',
    //         onCloseIndex: 0,
    //         options: [
    //             {
    //                 text: 'Option 1',
    //                 value: 1,
    //             },
    //             {
    //                 text: 'Option 2',
    //                 value: 2,
    //             },
    //         ]
    //     },
    //     id: 12315
    // },
    // {
    //     text: 'Test Message 4',
    //     type: 'warning',
    //     special: 'prompt',
    //     persist: true,
    //     modal:true,
    //     action: (option) => { console.log(option + ' clicked') },
    //     prompt: {
    //         text: 'Select an option',
    //         onCloseIndex: 0,
    //         options: [
    //             {
    //                 text: 'Option 1',
    //                 value: 1,
    //             },
    //             {
    //                 text: 'Option 2',
    //                 value: 2,
    //             },
    //         ]
    //     },
    //     id: 12317
    // },
  ]);

  useEffect(() => {
    const appRoot = document.querySelector('#app-root');
    const currentUser = JSON.parse(appRoot.getAttribute('data-user')) || {};
    const pusher_key = appRoot.getAttribute('data-pusher-key') || null;

    window.Pusher = Pusher;

    const key = import.meta.env.VITE_PUSHER_APP_KEY ?? pusher_key;
    const cluster = import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'us2';

    if (!key) return;

    Pusher.logToConsole = false;
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: key,
      cluster: cluster,
      forceTLS: true,
      auth: {
        headers: {
          'X-CSRF-TOKEN': '{{ csrf_token() }}',
        },
      },
    });

    if (currentUser.id) {
      window.Echo.private(`user.${currentUser.id}`)
        .listen('.user-message', (e) => {
          if (e?.meta?.migration) {
            setRecentMessage({ message: e?.message ?? e, heading: `${e.meta.verb} ${e.meta.part}` });
          } else {
            setRecentMessage({ message: e?.message ?? e });
          }

          const event = new CustomEvent('subscribe-notifications', { detail: e });
          window.dispatchEvent(event);
        })
        .listen('.concede-session', (e) => {
          setRecentMessage({ message: e?.message ?? e, special: 'concede', meta: e?.meta });
        })
        .listen('.private-message', (e) => {
          const event = new CustomEvent('private-message', { detail: e });
          window.dispatchEvent(event);
        })
        .listen('.ban-notification', (e) => {
          notify.alert(e?.message ?? 'You have been logged out.', 'info', () => {
            window.location.href = '/logout';
          });
        });
      // .listen('.user-prompt', (e) => {
      //     console.log(e);
      //     setRecentMessage({ message: e?.message ?? e, special: 'prompt', meta: e?.meta, prompt: e?.prompt })
      // });
      window.Echo.join('global').listen('.announcement', (e) => {
        setRecentMessage({
          heading: 'System Message:',
          message: `${e?.message ?? e}`,
          special: 'announcement',
        });
      });
    }
  }, []);

  useEffect(() => {
    if (!recentMessage) return;
    const appRoot = document.querySelector('#app-root');
    const currentUser = JSON.parse(appRoot.getAttribute('data-user')) || {};
    let silent = false;
    if (currentUser.silent_notifications == true) {
      silent = true;
    }

    if (recentMessage) {
      //broadcast event to notify other components of new notification
      const newMessage = {
        text: recentMessage?.message,
        heading: recentMessage?.meta?.heading ?? recentMessage?.heading ?? '',
        dismissed: false,
        onClose: recentMessage?.onClose ?? (() => {}),
        id: Date.now(),
        //display time in local time with AM/PM format
        timestamp: new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
        ...(recentMessage.special && { special: recentMessage.special }),
        ...(recentMessage.meta && { meta: recentMessage.meta }),
      };

      if (recentMessage?.silent) {
        silent = true;
      }

      if (recentMessage?.timeout) {
        newMessage.timeout = recentMessage.timeout;
      }

      setMessages([...messages, newMessage]);
      setTimeout(() => {
        if (messagesColumn.current) {
          messagesColumn.current.scrollTop = messagesColumn.current.scrollHeight;
        }
      }, 50);

      //play notification sound
      if (!silent) {
        const audio = new Audio(notifySound);
        audio.play();
      }

      document.title = 'New Notification - ChameleonCloud';

      //update icon
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = '/favicon-notification.ico';
      document.getElementsByTagName('head')[0].appendChild(link);

      setRecentMessage('');
    }
  }, [recentMessage, messagesColumn]);

  useEffect(() => {
    // Event listener for new notifications
    const handleNewNotification = (event) => {
      const newNotification = event.detail;
      setMessages((currentMessages) => [...currentMessages, newNotification]);
      setTimeout(() => {
        if (messagesColumn.current) {
          messagesColumn.current.scrollTop = messagesColumn.current.scrollHeight;
        }
      }, 50);
    };

    window.addEventListener('new-notification', handleNewNotification);

    // Clean up the event listener
    return () => {
      window.removeEventListener('new-notification', handleNewNotification);
    };
  }, []);

  const resetTitle = () => {
    document.title = 'ChameleonCloud';

    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = '/favicon.ico';
    document.getElementsByTagName('head')[0].appendChild(link);
  };

  const removeMessage = (id) => {
    resetTitle();
    setMessages((prevMessages) =>
      prevMessages.map((message) => {
        if (message.id === id) {
          return {
            ...message,
            dismissed: true,
          };
        }

        return message;
      }),
    );
  };

  const activeMessages = messages.filter((el) => {
    if (el.dismissed) return false;
    if (el.prompt) return false;
    if (el.modal) return false;

    return true;
  });

  const promptMessages = messages.filter((el) => {
    if (el.dismissed) return false;
    if (!el.prompt) return false;
    if (el.modal) return false;

    return true;
  });

  const modalMessages = messages.filter((el) => {
    if (el.dismissed) return false;
    if (!el.modal) return false;

    return true;
  });

  const firstActiveModalMessage = modalMessages.filter((message) => !message.dismissed)[0] ?? null;

  const messageToJSX = (message) => {
    switch (message?.special) {
    case 'prompt':
      return (
        <ToastNotification
          key={message.id}
          timestamp={message.timestamp}
          message={message.text}
          prompt={message.prompt}
          promptAction={message?.action}
          type='info'
          animate={true}
          onClose={() => removeMessage(message.id)}
        />
      );
    case 'concede':
      return (
        <ConcedeSessionNotification
          key={message.id}
          timestamp={message.timestamp}
          message={message.text}
          meta={message.meta}
          onClose={() => removeMessage(message.id)}
          animate={true}
        />
      );
    case 'announcement':
      return (
        <CardToastNotification
          key={message.id}
          heading={message?.heading}
          timestamp={message.timestamp}
          message={message.text}
          type='info'
          onClose={() => removeMessage(message.id)}
          animate={true}
        />
      );
      // return <ToastNotification key={message.id} heading={message?.heading} timestamp={message.timestamp} message={message.text} type="warning" onClose={() => removeMessage(message.id)} animate={true} />
    case 'toast':
      return (
        <ToastNotification
          key={message.id}
          timeout={message?.timeout}
          heading={message?.heading}
          type={message?.type}
          timestamp={message.timestamp}
          message={message.text}
          onClose={() => {
            removeMessage(message.id);
            message.onClose();
          }}
          animate={true}
        />
      );
    default:
      return (
        <ToastNotification
          key={message.id}
          heading={message?.heading}
          timestamp={message.timestamp}
          message={message.text}
          onClose={() => removeMessage(message.id)}
          animate={true}
        />
      );
    }
  };

  return (
    <div className='Notifications'>
      <div className={'message-column'} ref={messagesColumn}>
        {activeMessages.map(messageToJSX)}
      </div>

      {promptMessages.map(messageToJSX)}

      {firstActiveModalMessage && (
        <div className={'modal active'}>
          <div className={'scrim'}></div>
          {messageToJSX(firstActiveModalMessage)}
        </div>
      )}

      {activeMessages.filter((message) => !message.persist).length > 0 && (
        <div className='clear-all'>
          <a
            onClick={() => {
              setMessages(
                messages.filter((message) => {
                  return message?.persist === true;
                }),
              );
              resetTitle();
            }}
          >
            Clear Notifications
          </a>
        </div>
      )}
    </div>
  );
};

export default Notifications;
