import React, { useEffect, useState } from 'react';
import './CardToastNotification.scss';

const CardToastNotification = props => {
  const { type = 'info', message = '', heading = '', onClose = null, animate = true, timestamp = null, prompt = null, promptAction = () => { console.error('no user prompt action provided'); } } = props;
  if (!message) return null;
  const [hidden, setHidden] = useState(animate);

  useEffect(() => {
    if (prompt) {
      setTimeout(() => {
        document.getElementById('promptDisplay').focus();
      }, 80);
    }

    if (animate) {
      setTimeout(() => {
        setHidden(false);
      }, 80);
    }
  }, []);

  const handleClose = () => {
    if (!onClose) return;
    if (!animate) {
      onClose();

      return;
    } else {
      setHidden(true);
      setTimeout(() => {
        onClose();
      }, 300);
    }
  };

  let correctedType = type;
  let correctedMessage = message;
  while (typeof correctedMessage !== 'string') {
    if (message?.message) {
      correctedMessage = message.message;
    }

    if (message?.error) {
      correctedMessage = message.error;
      correctedType = 'error';
    } else {
      correctedMessage = JSON.stringify(message, null, 2);
    }
  }

  return (
    <div className={`CardToastNotification ${correctedType} ${timestamp ? 'has-timestamp' : ''} ${hidden ? 'hide' : 'show'} ${(onClose == null) || (!!prompt) ? 'no-close' : ''}`} id="promptDisplay" tabIndex={prompt ? 0 : -1}>
      {
        timestamp && <div className="timestamp">{timestamp}</div>
      }
      {
        heading && <div className={'heading'}>{heading}</div>
      }
      <div className={'message'} dangerouslySetInnerHTML={{ __html: correctedMessage }}></div>

      {
        prompt && <div className="prompt">
          <div className="prompt-text">{prompt.text}</div>
          <div className="prompt-text">{prompt.confirmText}</div>
          <div className="prompt-options">
            {
              (prompt?.options ?? []).map(option => {
                return <button className='option' onClick={() => {
                  handleClose();
                  setTimeout(() => {
                    promptAction(option.value);
                  }, 500);
                }} key={`option-${option.value}`}>{option.text}</button>;
              })
            }
          </div>
        </div>
      }
      {
        props.children
      }
      {onClose && !prompt && <div className={'close-wrapper'}>

        <button onClick={handleClose} title="close">
          <svg version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <line x1="1" y1="11"
              x2="11" y2="1"
              stroke="black"
              strokeWidth="2" />
            <line x1="1" y1="1"
              x2="11" y2="11"
              stroke="black"
              strokeWidth="2" />
          </svg>
        </button>
      </div>
      }
    </div>
  );
};

export default CardToastNotification;
