import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk to fetch course issue Priorities
export const fetchCourseIssuePriorities = createAsyncThunk(
  'courseIssuePriorities/fetchCourseIssuePriorities',
  async(_, thunkAPI) => {
    try {
      const response = await axios.get('/api/course_issues_priorities');

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  },
);

const courseIssuePrioritiesSlice = createSlice({
  name: 'courseIssuePriorities',
  initialState: {
    priorities: [],
    loading: false,
    error: null,
  },
  reducers: {
    // Add synchronous actions if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourseIssuePriorities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCourseIssuePriorities.fulfilled, (state, action) => {
        state.loading = false;
        state.priorities = action.payload;
      })
      .addCase(fetchCourseIssuePriorities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default courseIssuePrioritiesSlice.reducer;
