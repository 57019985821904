import React, { useEffect, useState } from 'react';
import './PageLoadingBar.scss';

const PageLoadingBar = ({ loading, loop }) => {
  const [progress, setProgress] = useState(0);
  const [pageLoading, setPageLoading] = useState(true); // Track page load status

  useEffect(() => {
    let interval;

    if (loading || pageLoading) {
      setProgress(0); // Reset progress when loading starts
      interval = setInterval(() => {
        setProgress((prev) => {
          if (prev < 80) return prev + 3;
          if (prev < 98) return prev + 0.3;
          if (loop) return 0;

          return prev;
        });
      }, 100);
    } else {
      setProgress(100);
    }

    return () => {
      clearInterval(interval);
    };
  }, [loading, pageLoading]);

  useEffect(() => {
    const handleDOMContentLoaded = () => {
      setPageLoading(false);
    };

    const handleLoad = () => {
      setPageLoading(false);
    };

    if (document.readyState === 'complete') {
      setPageLoading(false);
    } else {
      document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
      window.addEventListener('load', handleLoad);
    }

    return () => {
      document.removeEventListener('DOMContentLoaded', handleDOMContentLoaded);
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  return (
    <div className={`PageLoadingBar ${loading || pageLoading ? 'loading' : ''}`}>
      <div
        className="loading-bar"
        style={{
          width: `${progress}%`,
          transition: loading || pageLoading ? 'width 0.5s' : 'width 0.7s ease-in-out',
        }}
      />
    </div>
  );
};

export default PageLoadingBar;
