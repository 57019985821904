import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CMLHeading } from '../index';
import iconToggleOn from './icon-toggle-on.svg';
import iconToggleOff from './icon-toggle-off.svg';

import './CMLToggle.scss';
const cb = 'cml-toggle';

class CMLToggle extends Component {
  constructor(props) {
    super(props);
    const methods = ['handleOnClick'];
    methods.forEach(method => (this[method] = this[method].bind(this)));
  }

  render() {
    return (
      <div className={`${cb}`}>
        <CMLHeading title={this.props.title} size="3" />
        <div className={`${cb}__toggle-wrapper`}>
          {this.props.toggleOffText && (
            <div className={`${cb}__toggle-off`}>
              {this.props.toggleOffText}
            </div>
          )}
          <div className={`${cb}__toggle`} onClick={this.handleOnClick} onKeyUp={(e) => {if (e.which==13) {this.handleOnClick();}}} tabIndex="0" title="Toggle">
            <img className={`${cb}__toggle-bar`} src={ this.props.isToggledOn ? iconToggleOn : iconToggleOff } />
          </div>
          {this.props.toggleOnText && (
            <div className={`${cb}__toggle-on`}>
              {this.props.toggleOnText}
            </div>
          )}
        </div>
      </div>
    );
  }

  handleOnClick() {
    if (this.props.handleToggle){
      this.props.handleToggle();
    } else {
      this.props.isToggledOn ? this.props?.handleToggleOff() : this.props.handleToggleOn();
    }
  }
}

CMLToggle.propTypes = {
  handleToggleOff: PropTypes.func,
  handleToggleOn: PropTypes.func,
  isToggledOn: PropTypes.bool,
  title: PropTypes.string,
  toggleOffText: PropTypes.string,
  toggleOnText: PropTypes.string,
};

CMLToggle.defaultProps = {
  handleToggleOff: () => {},
  handleToggleOn: () => {},
  isToggledOn: false,
  toggleOffText: 'Off',
  toggleOnText: 'On',
};

export default CMLToggle;
