import React from 'react';
import './IssueAttachments.scss';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchCourseIssues } from '@/review/redux/slices/courseIssuesSlice';

const IssueAttachments = (props) => {
  const { issue } = props;

  const attachments = issue.attachments;
  const inputRef = React.createRef();
  const dispatch = useDispatch();

  const refresh = () => {
    dispatch(fetchCourseIssues());
  };

  const uploadFile = (e) => {
    const file = e.target.files[0];
    const postBody = new FormData();
    postBody.append('file', file);
    // post 'api/course_issues/{issue}/attachment'
    axios
      .post(`/api/course_issues/${issue.id}/attachment`, postBody)
      .then((response) => {
        inputRef.current.value = '';
        refresh();
      })
      .catch((error) => {
        console.error('Error uploading issue attachment', error);
      });
  };

  const baseName = (url) => {
    return url.split('/').pop();
  };

  return (
    <div className='IssueAttachments'>
      <div className={'current-attachments'}>
        <div className={'title'}>Attachments:</div>
        <div className='attachments'>
          {attachments.map((attachment, index) => {
            return (
              <div key={index} className='attachment'>
                <a href={attachment.url} target='_blank' rel='noopener noreferrer'>
                  {baseName(attachment.url)}
                </a>
              </div>
            );
          })}
        </div>
      </div>
      <div className={'upload-new'}>
        <div className={'title'}>Upload a new attachment:</div>
        <input type={'file'} ref={inputRef} onChange={uploadFile} accept='jpg,jpeg,png,gif,pdf,doc,docx,xls,xlsx,ppt,pptx,txt' />
      </div>
    </div>
  );
};

export default IssueAttachments;
