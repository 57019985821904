import { configureStore } from '@reduxjs/toolkit';
import clientReducer from './slices/models/clientsSlice';
import favoritesReducer from './slices/models/favoritesSlice';
import breadcrumbsReducer from './slices/features/breadcrumbsFeatureSlice';
import profileFeatureReducer from './slices/features/profileFeatureSlice';

const store = configureStore({
  reducer: {
    clients: clientReducer,
    favorites: favoritesReducer,
    breadcrumbs: breadcrumbsReducer,
    profile: profileFeatureReducer,
  },
});

export default store;
