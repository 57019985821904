import React from 'react';

const HeaderAuth = (props) => {
  const { user, guestUserDetails } = props;

  const loggedIn = user || guestUserDetails;

  if (loggedIn) {
    return (
      <a href='/logout' className='signout-link'>
        SIGN OUT
      </a>
    );
  }
};

export default HeaderAuth;
