import 'vite/modulepreload-polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import config from './config';
import { Provider } from 'react-redux';
import mainStore from './main/redux/store';
import Main from './main/Main';
import HeaderBar from './header/HeaderBar';

document.addEventListener('DOMContentLoaded', () => {
  document.body.className += ' ready';
});

// Primary Root Components
const root = document.getElementById('app-root');
if (root) {
  createRoot(root).render(
    <Provider store={mainStore}>
      <Main />
    </Provider>,
  );
}

// Lazy loading for conditional renders
const loginRoot = document.getElementById('login-root');
if (loginRoot) {
  import('./auth/login/Login').then(({ default: Login }) => {
    createRoot(loginRoot).render(<Login />);
  });
}

const resetRoot = document.getElementById('reset-root');
if (resetRoot) {
  import('./auth/reset/ResetPassword').then(({ default: ResetPassword }) => {
    createRoot(resetRoot).render(<ResetPassword />);
  });
}

const headerRoot = document.getElementById('header-root');
if (headerRoot) {
  createRoot(headerRoot).render(<HeaderBar />);
}

const authStatusElement = document.getElementById('auth-status');
if (authStatusElement) {
  import('./authcheck/AuthCheck').then(({ default: AuthCheck }) => {
    createRoot(authStatusElement).render(<AuthCheck />);
  });
}

const chatMount = document.getElementById('chat-mount');
if (chatMount) {
  import('./chat/ChatOverlay').then(({ default: ChatOverlay }) => {
    createRoot(chatMount).render(<ChatOverlay />);
  });
}

const isSuperDuperAdmin = config.isSuperDuperAdmin;
const reviewMount = document.getElementById('review-mount');
if (reviewMount && isSuperDuperAdmin) {
  Promise.all([import('./review/redux/store'), import('./review/ReviewOverlay')]).then(
    ([storeModule, overlayModule]) => {
      const reviewStore = storeModule.default;
      const ReviewOverlay = overlayModule.default;

      createRoot(reviewMount).render(
        <Provider store={reviewStore}>
          <ReviewOverlay />
        </Provider>,
      );
    },
  );
}

const supportMount = document.getElementById('support-mount');
if (supportMount) {
  import('./support/SupportOverlay').then(({ default: SupportOverlay }) => {
    createRoot(supportMount).render(<SupportOverlay />);
  });
}

const frameworkFeedbackMount = document.getElementById('framework-feedback-mount');
if (frameworkFeedbackMount) {
  const url = frameworkFeedbackMount.getAttribute('data-url');
  const courseId = frameworkFeedbackMount.getAttribute('data-course-id');
  const courseGuid = frameworkFeedbackMount.getAttribute('data-course-guid');
  const stage = frameworkFeedbackMount.getAttribute('data-course-stage');

  import('./frameworkfeedback/FrameworkFeedback').then(({ default: FrameworkFeedback }) => {
    createRoot(frameworkFeedbackMount).render(
      <FrameworkFeedback url={url} courseId={courseId} courseGuid={courseGuid} stage={stage} />,
    );
  });
}

const feedbackLoginRoot = document.getElementById('feedback-login-root');
if (feedbackLoginRoot) {
  import('./auth/feedbacklogin/FeedbackLogin').then(({ default: FeedbackLogin }) => {
    createRoot(feedbackLoginRoot).render(<FeedbackLogin valid={true} />);
  });
}

const badFeedbackLinkRoot = document.getElementById('feedback-badlink-root');
if (badFeedbackLinkRoot) {
  import('./auth/feedbacklogin/FeedbackLogin').then(({ default: FeedbackLogin }) => {
    createRoot(badFeedbackLinkRoot).render(<FeedbackLogin valid={false} />);
  });
}
