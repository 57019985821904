import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CMLAccordionSection from './CMLAccordionSection';

import './CMLAccordion.scss';
const cb = 'cml-accordion';

class CMLAccordion extends Component {
  constructor(props) {
    super(props);
    let openSections = {};
    if (props.openSections) {
      openSections=props.openSections;
    }

    this.state = { openSections };
  }

  onClick = label => {
    const {
      state: { openSections },
    } = this;

    const isOpen = !!openSections[label];

    this.setState({
      openSections: {
        [label]: !isOpen,
      },
    });
  };

  render() {
    const {
      onClick,
      props: { children },
      state: { openSections },
    } = this;

    return (
      <div className={`${cb}`}>
        {children.map((child, index) => (
          <CMLAccordionSection
            disabled={child.props.disabled}
            isOpen={!!openSections[child.props.label]}
            key={child.props.label+'-'+index}
            label={child.props.label}
            onClick={onClick}
          >
            {child.props.children}
          </CMLAccordionSection>
        ))}
      </div>
    );
  }
}

CMLAccordion.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
};
CMLAccordion.defaultProps = {
  children: {},
};

export default CMLAccordion;
