import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './CMLInput.scss';
const cb = 'cml-input';

/**
 * A simple text input element.
 */
class CMLInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
      value: props.value,
    };
    const methods = ['handleOnChange', 'handleOnFocus', 'handleOnBlur', 'getOverrideClasses'];
    methods.forEach(method => (this[method] = this[method].bind(this)));
  }

  render() {
    const cbOverrides = this.getOverrideClasses();

    return (
      <div className={`${cb} ${cbOverrides}`}>
        <div className={`${cb}__input-wrapper`}>
          <div className={`${cb}__input-field-wrapper`}>
            <label className={`${cb}__input-placeholder`} translate="yes">
              {this.props.placeholder}
            </label>
            <input
              className={`${cb}__input-field`}
              ref={this.props.inputRef}
              type={this.props.protected ? 'password' : 'text'}
              name={this.props.name}
              onBlur={this.handleOnBlur}
              onChange={this.handleOnChange}
              onFocus={this.handleOnFocus}
              value={this.props.trackValue ? this.props.value : (this.state.value ? this.state.value : '')} />
          </div>
          <div className={`${cb}__input-message`}>
            {this.props.error || this.props.hint}
          </div>
        </div>
      </div>
    );
  }

  handleOnChange(e) {
    e.preventDefault();
    this.props.handleOnChange(e);

    if ( !this.props.trackValue ) {
      this.setState({
        value: e.target.value,
      });
    }
  };

  handleOnFocus() {
    this.setState({
      focus: true,
    });
  }

  handleOnBlur() {
    this.setState({
      focus: false,
    });
  }

  getOverrideClasses() {
    const cbOverrides = [];

    if ( ( this.props.trackValue && this.props.value.trim() ) || ( !this.props.trackValue && this.state.value ) ) {
      cbOverrides.push(`${cb}--has-value`);
    }

    if ( this.state.focus ) {
      cbOverrides.push(`${cb}--has-focus`);
    }

    if ( this.props.error ) {
      cbOverrides.push(`${cb}--error`);
    }

    return cbOverrides.join(' ');
  }
}

CMLInput.propTypes = {
  error: PropTypes.string,
  handleOnChange: PropTypes.func,
  hint: PropTypes.string,
  inputRef: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  protected: PropTypes.bool,
  trackValue: PropTypes.bool,
  value: PropTypes.string,
};

CMLInput.defaultProps = {
  handleOnChange: () => {},
  inputRef: () => {},
  trackValue: true,
  value: '',
};

export default CMLInput;
