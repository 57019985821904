import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk to fetch course issue statuses
export const fetchCourseIssueStatuses = createAsyncThunk(
  'courseIssueStatuses/fetchCourseIssueStatuses',
  async(_, thunkAPI) => {
    try {
      const response = await axios.get('/api/course_issues_statuses');

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  },
);

const courseIssueStatusesSlice = createSlice({
  name: 'courseIssueStatuses',
  initialState: {
    statuses: [],
    loading: false,
    error: null,
  },
  reducers: {
    // Add synchronous actions if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourseIssueStatuses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCourseIssueStatuses.fulfilled, (state, action) => {
        state.loading = false;
        state.statuses = action.payload;
      })
      .addCase(fetchCourseIssueStatuses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default courseIssueStatusesSlice.reducer;
