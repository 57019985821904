import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import config from '@/config';

const userID = config.my_user_id;

// Async thunk to fetch course favorites
export const fetchFavorites = createAsyncThunk(
  'favorites/fetchFavorites',
  async(_, thunkAPI) => {
    try {
      const response = await axios.get(`/api/favorites/user/${userID}`);

      return response.data;
    } catch (error) {
      console.error('fetchFavorites error', error);

      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  },
);

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    // Add synchronous actions if needed
  },
  extraReducers: (builder) => {
    // Handle fetchFavorites
    builder
      .addCase(fetchFavorites.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFavorites.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(fetchFavorites.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default favoritesSlice.reducer;
