import React from 'react';

export function svgBgColor(iconStyle) {
  return <g>
	        <path style={iconStyle} d="M 76.6 42.17 c 2.07 -2.07 2.07 -5.85 -0.34 -7.92 l -3.44 -3.44 l 20.31 -17.9 c 2.07 -2.07 2.07 -5.51 0 -7.57 l -3.79 -3.79 c -2.07 -2.07 -5.51 -2.07 -7.57 0 l -17.9 20.31 l -3.44 -3.44 c -2.41 -2.41 -5.85 -2.41 -7.92 -0.34 L 37.01 33.56 L 61.45 58 L 76.6 42.17 Z M 81.42 12.56 c -1.72 -1.72 -1.72 -4.82 0 -6.54 s 4.82 -1.72 6.54 0 c 1.72 1.72 1.72 4.82 0 6.54 C 86.24 14.63 83.14 14.63 81.42 12.56 Z" />
	        <rect style={iconStyle} transform="matrix(0.7071 -0.7071 0.7071 0.7071 -21.9915 46.0256)" x="41.46" y="32.18" width="6.2" height="34.77" />
	        <polygon style={iconStyle} points="52.5,66.26 28.06,41.82 15.33,47.33 15.33,48.71 18.43,48.71 18.43,51.81 21.52,51.81 21.52,54.9 24.28,54.9 24.28,58 27.38,58 27.38,61.1 30.47,61.1 30.47,64.2 33.57,64.2 33.57,66.95 36.67,66.95 36.67,70.05 39.77,70.05 39.77,73.15 42.87,73.15 42.87,75.9 45.28,75.9 45.28,79 46.65,79" />
	    </g>;
}

;

export function svgBgImage(iconStyle,pathStyle) {
  return <g>
    <rect style={pathStyle} x="13.47" y="8.62" width="83.12" height="61.48" />
    <path style={iconStyle} d="M 4.69 74.54 c 0 2.4 2.06 4.46 4.46 4.46 h 91.7 c 2.4 0 4.46 -2.06 4.46 -4.46 V 4.47 c 0 -2.4 -2.06 -4.46 -4.46 -4.46 H 9.15 c -2.4 0 -4.46 2.06 -4.46 4.46 V 74.54 Z M 96.38 70.07 H 13.27 V 8.6 h 83.11 V 70.07 Z M 72.69 26.11 c 0 -4.81 3.78 -8.93 8.59 -8.93 s 8.59 3.78 8.59 8.93 c 0 4.81 -3.78 8.93 -8.59 8.93 S 72.69 30.92 72.69 26.11 Z M 91.58 61.14 H 22.2 V 39.16 l 14.42 -21.98 l 23.7 29.54 l 9.96 -7.9 L 91.58 61.14 Z" />
  </g>;
}

;

export function svgBgVideo(iconStyle,pathStyle) {
  return <g>
    <path style={iconStyle} d="M 4.88 74.57 c 0 2.4 2.06 4.47 4.47 4.47 h 91.71 c 2.4 0 4.47 -2.06 4.47 -4.47 V 4.5 c 0 -2.4 -2.06 -4.47 -4.47 -4.47 H 9.35 c -2.4 0 -4.47 2.06 -4.47 4.47 V 74.57 Z" />
    <rect style={pathStyle} x="13.47" y="8.62" width="83.12" height="61.48" />
    <path style={iconStyle} d="M 87.66 22.01 v 37.1 c 0 1.37 -0.69 1.72 -1.72 0.69 L 68.08 45.71 v 12.37 c 0 1.03 -1.03 2.06 -2.06 2.06 H 24.46 c -1.03 0 -2.06 -1.03 -2.06 -2.06 V 22.7 c 0 -1.03 1.03 -2.06 2.06 -2.06 h 41.56 c 1.03 0 2.06 1.03 2.06 2.06 v 12.37 l 17.86 -14.08 C 86.97 20.3 87.66 20.64 87.66 22.01 Z" />
  </g>;
}

;
