const userDataHelper = {
  getUserData: () => {
    const userData = JSON.parse(document.getElementById('app-root').getAttribute('data-user'));
    window.getUserData = () => userData;

    return userData;
  },

  isSuperAdmin: () => {
    const userData = userDataHelper.getUserData();

    return !!userData?.is_super_admin;
  },

  isSuperDuperAdmin: () => {
    const userData = userDataHelper.getUserData();

    return !!userData?.is_super_duper_admin;
  },

  getClientsWhereAdmin: () => {
    const userData = userDataHelper.getUserData();

    if (userData?.is_super_admin) {
      //get api/clients
      return userData?.adminsClients ?? [];
    }

    return userData.clients.filter(client => client?.group?.name === 'Client Admin');
  },

  getAllClients: () => {
    const userData = userDataHelper.getUserData();

    return userData.clients;
  },

  canTrueDestroyCourse: () => {
    const userData = userDataHelper.getUserData();

    return userData?.can_true_destroy_course;
  },

  canImpersonate: () => {
    const userData = userDataHelper.getUserData();

    return userData?.can_impersonate;
  },

};

export default userDataHelper;
