import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AddSVG from '../../helpers/icons';

import './CMLModal.scss';
const cb = 'cml-modal';

class CMLModal extends Component {
  constructor(props) {
    super(props);
    const methods = ['handleBlurClick','keyHandler','setFocus'];
    methods.forEach(method => (this[method] = this[method].bind(this)));
    this.modalRef = React.createRef();
    this.timeout;
  }

  keyHandler(e,func) {
    if (this.props.disableKeyHandlers){
      return;
    }

    const key = e.which;
    if (key==13) {
      if (func=='handleOnClose') {
        this.props.handleOnClose();
      }
      else if (func=='handleBlurClick') {
        this.handleBlurClick(e);
      }
    }
    else if (key==27) {
      this.props.handleOnClose();
    }
  }

  setFocus() {
    clearTimeout(this.timeout);
    this.timeout=setTimeout(() => {
      this.modalRef.current.focus();
    },100);
  }

  render() {
    const displayClass = this.props.show ? `${cb}--show` : '';
    const wideClass = this.props.wide ? `${cb}--wide` : '';
    const height=this.props.height ? this.props.height : '';
    const contentStyle={};
    if (height) {
      contentStyle.height=height;
    }

    ;
    if (this.props.show) {
      if (!this.props.disableKeyHandlers && !this.props.disableFocus){
        this.setFocus();
      }
    }

    return (
      <div className={`${cb} ${displayClass} ${wideClass}`} onKeyUp={(e) => this.keyHandler(e,'handleBlurClick')} ref={this.modalRef} tabIndex={0}>
        <div className={`${cb}__outer`} onClick={this.handleBlurClick}></div>
        <div className={`${cb}__inner`}>
          <div className={`${cb}__header`}>
            {this.props.title && (
              <div className={`${cb}__title`}>
                { this.props.title }
              </div>
            )}
            {
              this.props.hideClose ? null :
                <div className={`${cb}__close`} onKeyUp={(e) => this.keyHandler(e,'handleOnClose')} onClick={this.props.handleOnClose} tabIndex="0" title="Close">
                  <AddSVG icon={'close'} size={17} resize={14} fill={'#fff'} />
                </div>
            }
          </div>
          <div className={`${cb}__content`} style={contentStyle}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }

  handleBlurClick(event) {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      this.props.handleOnClose();
    }
  }
}

CMLModal.propTypes = {
  handleOnClose: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string,
  wide: PropTypes.bool,
};

CMLModal.defaultProps = {
  handleOnClose: () => {},
  show: false,
  wide: false,
};

export default CMLModal;
