import React, { useEffect, useState } from 'react';
import config from '@/config';
import BoardColumn from './subcomponents/BoardColumn';
import IssueDetails from '../IssueDetails/IssueDetails';
import './IssueBoard.scss';
import StyledCheckbox from '@/shared/components/StyledCheckbox/StyledCheckbox';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCourseIssues } from '../../redux/slices/courseIssuesSlice';
import { fetchCourseIssueStatuses } from '../../redux/slices/courseIssueStatusesSlice';
import { fetchUsers } from '@/review/redux/slices/usersSlice';
import { fetchCourseIssuePriorities } from '@/review/redux/slices/courseIssuePrioritiesSlice';
import StyledButton from '@/shared/components/StyledButton/StyledButton';
import { CMLLoader } from '@/shared/library';
import StyledTextInput from '@/shared/components/StyledTextInput/StyledTextInput';

const IssueBoard = (props) => {
  const courseID = config.courseID;
  const dispatch = useDispatch();
  const issuesState = useSelector((state) => state.courseIssues);
  const statusesState = useSelector((state) => state.courseIssueStatuses);

  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState('');
  const [selectedIssueId, setSelectedIssueId] = useState(null);

  useEffect(() => {
    dispatch(fetchCourseIssueStatuses());
    dispatch(fetchUsers());
    dispatch(fetchCourseIssuePriorities());
  }, [dispatch]);

  useEffect(() => {
    if (courseID) {
      dispatch(fetchCourseIssues(courseID));
    }
  }, [dispatch, courseID]);

  const refresh = () => {
    dispatch(fetchCourseIssues());
  };

  const selectedIssue = issuesState.issues.filter((issue) => issue.id === selectedIssueId)[0];

  const filterTypes = [
    {
      key: 'my_issues',
      label: 'My Issues',
      disabled: filters.unassigned,
      rule: (issue) => issue.assignee_id === config.my_user_id,
    },
    {
      key: 'assigned',
      label: 'Assigned',
      disabled: filters.unassigned || filters.my_issues,
      rule: (issue) => issue.assignee_id,
    },
    {
      key: 'unassigned',
      label: 'Unassigned',
      disabled: filters.assigned || filters.my_issues,
      rule: (issue) => !issue.assignee_id,
    },
    {
      key: 'high_priority',
      label: 'High Priority',
      disabled: filters.low_priority || filters.no_priority,
      rule: (issue) => (issue.priority?.value ?? 0) >= 3,
    },
    {
      key: 'low_priority',
      label: 'Low Priority',
      disabled: filters.high_priority || filters.no_priority,
      rule: (issue) => (issue.priority?.value ?? 5) < 3,
    },
    {
      key: 'no_priority',
      label: 'No Priority',
      disabled: filters.high_priority || filters.low_priority,
      rule: (issue) => !issue.priority,
    },
    {
      key: 'incomplete',
      label: 'Incomplete',
      rule: (issue) => issue.status.prettyname !== 'Done',
    },
    {
      key: 'has_comments',
      label: 'Has Comments',
      rule: (issue) => issue.comments.length > 0,
    },
    {
      key: 'has_attachment',
      label: 'Has Attachment',
      rule: (issue) => issue.attachments.length > 0,
    },
  ];

  const filteredIssues = issuesState.issues.filter((issue) => {
    if (search) {
      const searchTrim = search.trim().toLowerCase();
      if (issue.title.toLowerCase().includes(searchTrim)) {
        return true;
      }

      if (issue.description.toLowerCase().includes(searchTrim)){
        return true;
      }

      if (issue.assignee){
        const bigsearch = issue.assignee.first_name + ' ' + issue.assignee.last_name + ' ' + issue.assignee.email;
        if (bigsearch.toLowerCase().includes(searchTrim)){
          return true;
        }
      }

      return false;
    }

    for (const filter of filterTypes) {
      if (filters[filter.key] && !filter.rule(issue)) {
        return false;
      }
    }

    return true;
  });

  if (issuesState.loading) {
    return <CMLLoader text={'Loading Issues'} />;
  }

  return (
    <div className='IssueBoard'>
      <StyledButton quiet icon={'refresh'} onClick={refresh}>
        Refresh
      </StyledButton>
      {selectedIssue && <IssueDetails issue={selectedIssue} backToBoard={() => setSelectedIssueId(null)} />}
      {!selectedIssue && (
        <div className={'board'}>
          <div className={'filters'}>
            <h2>Filters</h2>
            <StyledTextInput value={search} onChange={e => setSearch(e.currentTarget.value)} placeholder={'Search'} />
            {/* assigned */}
            {filterTypes.map((filter) => {
              return (
                <StyledCheckbox
                  key={filter.key}
                  label={filter?.label}
                  disabled={filter.disabled}
                  value={!!filters[filter.key]}
                  onChange={(val) => {
                    setFilters({
                      ...filters,
                      [filter.key]: !filters[filter.key],
                    });
                  }}
                />
              );
            })}
          </div>

          <div className={'columns'}>
            {statusesState.statuses
              .filter((status) => {
                //    show/hide completed toggle
                // if (!filters.show_completed && status.prettyname === 'Done') {
                //   return false;
                // }
                return true;
              })
              .map((status, index) => {
                return (
                  <BoardColumn
                    setSelectedIssueId={setSelectedIssueId}
                    key={status.id}
                    status={status}
                    issues={filteredIssues.filter((issue) => issue.status_id === status.id)}
                  />
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default IssueBoard;
