import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Preloader = () => {
  //   preload clients
  const clients = useSelector((state) => state.clients.list);

  // Preload client images
  useEffect(() => {
    if (clients.length > 0 && Array.isArray(clients)) {
      clients.forEach((client) => {
        if (client.image) {
          const img = new Image();
          img.src = client.image;
        }
      });
    }
  }, [clients]);

  return <div className='Preloader'></div>;
};

export default Preloader;
