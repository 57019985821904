import React, { useEffect, useState, useRef } from 'react';
import './ToastNotification.scss';

const ToastNotification = (props) => {
  const {
    type = 'info',
    message = '',
    heading = '',
    onClose = null,
    animate = true,
    timestamp = null,
    prompt = null,
    promptAction = () => {
      console.error('no user prompt action provided');
    },
  } = props;
  if (!message) return null;
  const [hidden, setHidden] = useState(animate);
  const [timeCreated, setTimeCreated] = useState(null);
  const [autoCloseDuration, setAutoCloseDuration] = useState(props.timeout);
  const [isHovered, setIsHovered] = useState(false);

  const intervalRef = useRef(null);

  const handleClose = () => {
    if (!onClose) return;
    if (!animate) {
      onClose();

      return;
    } else {
      setHidden(true);
      setTimeout(() => {
        onClose();
      }, 300);
    }
  };

  const startTimeout = () => {
    if (intervalRef.current) return;

    intervalRef.current = setInterval(() => {
      if (!timeCreated) return;
      if (autoCloseDuration && Date.now() - timeCreated > autoCloseDuration) {
        handleClose();
      }
    }, 1000);
  };

  const clearTimeoutInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  useEffect(() => {
    if (timeCreated == null) return;

    if (!isHovered) {
      startTimeout();
    }

    return () => clearTimeoutInterval();
  }, [timeCreated, isHovered]);

  useEffect(() => {
    if (isHovered) {
      setAutoCloseDuration(null);
      clearTimeoutInterval();
    }
  }, [isHovered]);

  useEffect(() => {
    setTimeCreated(Date.now());

    if (prompt) {
      setTimeout(() => {
        document.getElementById('promptDisplay').focus();
      }, 80);
    }

    if (animate) {
      setTimeout(() => {
        setHidden(false);
      }, 80);
    }
  }, []);

  let correctedType = type;
  let correctedMessage = message;
  while (typeof correctedMessage !== 'string') {
    if (message?.message) {
      correctedMessage = message.message;
    }

    if (message?.error) {
      correctedMessage = message.error;
      correctedType = 'error';
    } else {
      correctedMessage = JSON.stringify(message, null, 2);
    }
  }

  return (
    <div
      className={`ToastNotification ${correctedType} ${timestamp ? 'has-timestamp' : ''} ${hidden ? 'hide' : 'show'} ${
        onClose == null || !!prompt ? 'no-close' : ''
      }`}
      id='promptDisplay'
      tabIndex={prompt ? 0 : -1}
    >
      {timestamp && <div className='timestamp'>{timestamp}</div>}
      {heading && <div className={'heading'}>{heading}</div>}
      <div className={'message'} dangerouslySetInnerHTML={{ __html: correctedMessage }}></div>

      {prompt && (
        <div className='prompt'>
          <div className='prompt-text' dangerouslySetInnerHTML={{ __html: prompt.text }}></div>
          <div className='prompt-text'>{prompt.confirmText}</div>
          <div className='prompt-options'>
            {(prompt?.options ?? []).map((option) => {
              return (
                <button
                  className='option'
                  onClick={() => {
                    handleClose();
                    setTimeout(() => {
                      promptAction(option.value);
                    }, 500);
                  }}
                  key={`option-${option.value}`}
                >
                  {option.text}
                </button>
              );
            })}
          </div>
        </div>
      )}
      {props.children}
      {onClose && !prompt && (
        <div className={'close-wrapper'}>
          {autoCloseDuration && (
            <div
              className={'timeout-wrapper'}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <div
                className='timeout-top'
                style={{
                  transition: `width ${autoCloseDuration}ms linear`,
                  width: timeCreated ? '100%' : '0%',
                }}
              ></div>
              {/* <div className="timeout-right" style={{
                            // delayed transition to allow top to finish first
                            transition: `height ${autoCloseDuration / 4}ms linear ${autoCloseDuration / 4}ms`,
                            height: timeCreated ? '100%' : '0%',
                        }}></div>
                        <div className="timeout-bottom" style={{
                            transition: `width ${autoCloseDuration / 4}ms linear ${autoCloseDuration / 2}ms`,
                            width: timeCreated ? '100%' : '0%',
                        }}></div>
                        <div className="timeout-left" style={{
                            transition: `height ${autoCloseDuration / 4}ms linear ${3 * autoCloseDuration / 4}ms`,
                            height: timeCreated ? '100%' : '0%',
                        }}></div> */}
            </div>
          )}

          <button onClick={handleClose} title='close'>
            <svg version='1.1' xmlns='http://www.w3.org/2000/svg'>
              <line x1='1' y1='11' x2='11' y2='1' stroke='black' strokeWidth='2' />
              <line x1='1' y1='1' x2='11' y2='11' stroke='black' strokeWidth='2' />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
};

export default ToastNotification;
