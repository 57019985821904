import React from 'react';
import './StyledTextInput.scss';

const StyledTextInput = (props) => {
  const {
    name = '',
    placeholder = '',
    value = '',
    onChange = () => {
      console.error('no change handler for StyledTextInput provided');
    },
    required = false,
    disabled = false,
    label = '',
    heading = '',
    description = '',
  } = props;

  return (
    <div className={'StyledTextInput'}>
      {heading && <h3>{heading}</h3>}
      {label && <label htmlFor={name}>{label}</label>}
      {description && <p>{description}</p>}
      <input
        type={props.type ? props.type : 'text'}
        name={name}
        id={name}
        placeholder={placeholder}
        value={value || ''}
        onChange={onChange}
        required={required}
        disabled={disabled}
        aria-label={label}
      />
    </div>
  );
};

export default StyledTextInput;
