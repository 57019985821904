import React from 'react';

export function svgAdobeAnimate(iconStyle) {
  return <g>
    <path style={iconStyle} d="M 88.46 72.11 v -2.75 h -3.43 v 2.75 c 0 2.06 -1.37 3.78 -3.43 3.78 H 29.4 c -1.72 0 -3.43 -1.72 -3.43 -3.78 v -2.75 h -3.43 v 2.75 c 0 3.78 3.09 6.87 6.87 6.87 H 81.6 C 85.37 79.32 88.46 75.89 88.46 72.11 Z" />
    <rect style={iconStyle} x="42.45" y="32.62" width="26.1" height="8.58" />
    <rect style={iconStyle} x="42.45" y="44.3" width="26.1" height="8.58" />
    <rect style={iconStyle} x="42.45" y="56.31" width="26.1" height="8.58" />
    <path style={iconStyle} d="M 27.69 33.31 c 1.37 1.37 3.78 1.37 5.49 0 l 9.27 -9.27 v 5.15 h 26.1 v -5.15 l 9.27 9.27 c 1.37 1.37 3.78 1.37 5.49 0 c 1.37 -1.37 1.37 -3.78 0 -5.49 L 55.5 0 L 27.69 27.81 C 26.31 29.53 26.31 31.93 27.69 33.31 Z" />
  </g>;
}

;
export function svgAudio(iconStyle,pathStyle) {
  return <g>
    <circle style={iconStyle} cx="55.28" cy="39.5" r="39.5" />
    <path style={pathStyle} d="M 43.6 23.7 v 29.54 c -1.37 -0.69 -2.75 -0.69 -4.47 -0.69 c -5.15 0 -8.93 2.75 -8.93 6.18 s 4.12 6.18 8.93 6.18 c 4.81 0 8.93 -2.75 8.93 -5.84 l 0 0 V 35.72 l 20.27 -7.9 V 45 c -1.37 -0.69 -2.75 -1.03 -4.47 -1.03 c -5.15 0 -8.93 2.75 -8.93 6.18 s 4.12 6.18 8.93 6.18 c 4.81 0 8.93 -2.75 8.93 -6.18 l 0 0 V 25.76 l 0 0 V 12.71 L 43.6 23.7 Z" />
  </g>;
}

;
export function svgBeforeAfter(iconStyle) {
  return <g>
    <path style={iconStyle} d="M 89.69 20.27 c -1.03 -1.03 -2.75 -1.03 -3.78 0 c -1.03 1.03 -1.03 2.75 0 3.78 l 6.18 6.18 H 64.27 v 17.86 H 92.1 l -6.18 6.18 c -1.03 1.03 -1.03 2.75 0 3.78 c 1.03 1.03 2.75 1.03 3.78 0 l 18.89 -18.89 L 89.69 20.27 Z" />
    <path style={iconStyle} d="M 20.31 58.39 c 1.03 1.03 2.75 1.03 3.78 0 c 1.03 -1.03 1.03 -2.75 0 -3.78 l -6.18 -6.18 h 27.82 V 30.57 H 17.9 l 6.18 -6.18 c 1.03 -1.03 1.03 -2.75 0 -3.78 c -1.03 -1.03 -2.75 -1.03 -3.78 0 L 1.42 39.5 L 20.31 58.39 Z" />
    <rect style={iconStyle} x="53.28" width="3.43" height="79" />
  </g>;
}

;
export function svgEmbed(iconStyle) {
  return <g>
    <path style={iconStyle} d="M 31.01 0.77 l 12.74 12.74 l 5.85 -5.85 c 1.03 -1.03 1.72 -0.69 1.72 0.69 v 24.78 c 0 1.38 -1.03 2.41 -2.41 2.41 H 24.13 c -1.38 0 -1.72 -0.69 -0.69 -1.72 l 5.85 -5.85 L 16.55 15.23 c -1.03 -1.03 -1.03 -2.75 0 -3.44 L 27.57 0.77 C 28.6 -0.26 30.32 -0.26 31.01 0.77 Z" />
    <path style={iconStyle} d="M 94.01 63.77 L 81.27 51.03 l 5.85 -5.85 c 1.03 -1.03 0.69 -1.72 -0.69 -1.72 H 61.65 c -1.38 0 -2.41 1.03 -2.41 2.41 v 24.78 c 0 1.38 0.69 1.72 1.72 0.69 l 5.85 -5.85 l 12.74 12.74 c 1.03 1.03 2.75 1.03 3.44 0 l 11.02 -11.02 C 95.04 66.18 95.04 64.8 94.01 63.77 Z" />
    <path style={iconStyle} d="M 31.01 78.23 l 12.74 -12.74 l 5.85 5.85 c 1.03 1.03 1.72 0.69 1.72 -0.69 V 45.87 c 0 -1.38 -1.03 -2.41 -2.41 -2.41 H 24.13 c -1.38 0 -1.72 0.69 -0.69 1.72 l 5.85 5.85 L 16.55 63.77 c -1.03 1.03 -1.03 2.75 0 3.44 l 11.02 11.02 C 28.6 79.26 30.32 79.26 31.01 78.23 Z" />
    <path style={iconStyle} d="M 94.01 15.23 L 81.27 27.97 l 5.85 5.85 c 1.03 1.03 0.69 1.72 -0.69 1.72 H 61.65 c -1.38 0 -2.41 -1.03 -2.41 -2.41 V 8.35 c 0 -1.38 0.69 -1.72 1.72 -0.69 l 5.85 5.85 L 79.55 0.77 c 1.03 -1.03 2.75 -1.03 3.44 0 l 11.02 11.02 C 95.04 12.82 95.04 14.54 94.01 15.23 Z" />
  </g>;
}

;
export function svgFPO(iconStyle,pathStyle) {
  return <g>
    <path style={iconStyle} d="M 4.68 74.53 c 0 2.4 2.06 4.47 4.47 4.47 h 91.71 c 2.4 0 4.47 -2.06 4.47 -4.47 V 4.47 c 0 -2.4 -2.06 -4.47 -4.47 -4.47 H 9.15 c -2.4 0 -4.47 2.06 -4.47 4.47 V 74.53 Z" />
    <rect style={pathStyle} x="13.61" y="8.59" width="83.12" height="61.48" />
    <path style={iconStyle} d="M 39.37 43.28 h -9.96 v 13.05 h -6.53 v -31.6 H 40.4 v 5.5 H 29.07 v 8.24 h 9.96 v 4.81 H 39.37 Z" />
    <path style={iconStyle} d="M 49.33 45 v 11.33 h -6.53 v -31.6 h 10.65 c 3.09 0 5.5 1.03 7.56 3.09 c 1.72 2.06 2.75 4.47 2.75 7.56 c 0 3.09 -1.03 5.5 -2.75 7.21 c -1.72 1.72 -4.47 2.75 -7.56 2.75 h -4.12 V 45 Z M 49.33 39.84 h 4.47 c 1.37 0 2.06 -0.34 2.75 -1.03 c 0.69 -0.69 1.03 -2.06 1.03 -3.43 c 0 -1.72 -0.34 -2.75 -1.03 -3.78 c -0.69 -1.03 -1.72 -1.37 -2.75 -1.37 h -4.47 V 39.84 Z" />
    <path style={iconStyle} d="M 89.85 43.28 c 0 4.12 -1.36 7.56 -3.42 9.96 c -2.06 2.4 -4.81 3.43 -8.24 3.43 s -6.53 -1.03 -8.24 -3.43 c -2.06 -2.4 -3.09 -5.5 -3.09 -9.96 v -5.5 c 0 -4.47 1.03 -7.9 3.09 -10.3 s 4.81 -3.78 8.59 -3.78 c 3.43 0 6.18 1.37 8.24 3.78 c 2.06 2.4 3.09 5.84 3.09 9.96 v 5.84 H 89.85 Z M 83.34 37.78 c 0 -2.75 -0.34 -5.15 -1.37 -6.53 c -1.03 -1.37 -2.06 -2.06 -3.78 -2.06 s -3.09 0.69 -3.78 2.06 c -0.69 1.37 -1.37 3.43 -1.37 6.18 v 5.5 c 0 2.75 0.34 4.81 1.37 6.18 c 0.69 1.37 2.06 2.06 3.78 2.06 s 2.75 -0.69 3.78 -2.06 c 0.69 -1.37 1.37 -3.43 1.37 -5.84 V 37.78 Z" />
  </g>;
}

;
export function svgImage(iconStyle,pathStyle) {
  return <g>
    <rect style={pathStyle} x="13.47" y="8.62" width="83.12" height="61.48" />
    <path style={iconStyle} d="M 4.69 74.54 c 0 2.4 2.06 4.46 4.46 4.46 h 91.7 c 2.4 0 4.46 -2.06 4.46 -4.46 V 4.47 c 0 -2.4 -2.06 -4.46 -4.46 -4.46 H 9.15 c -2.4 0 -4.46 2.06 -4.46 4.46 V 74.54 Z M 96.38 70.07 H 13.27 V 8.6 h 83.11 V 70.07 Z M 72.69 26.11 c 0 -4.81 3.78 -8.93 8.59 -8.93 s 8.59 3.78 8.59 8.93 c 0 4.81 -3.78 8.93 -8.59 8.93 S 72.69 30.92 72.69 26.11 Z M 91.58 61.14 H 22.2 V 39.16 l 14.42 -21.98 l 23.7 29.54 l 9.96 -7.9 L 91.58 61.14 Z" />
  </g>;
}

;
export function svgPanorama(iconStyle) {
  return <g>
    <path style={iconStyle} d="M 7.08 65.55 c 0 3.1 1.38 5.17 3.45 5.17 h 10 c 1.72 0 3.45 -2.41 3.45 -5.17 V 13.45 c 0 -3.1 -1.38 -5.17 -3.45 -5.17 h -10 c -1.72 0 -3.45 2.41 -3.45 5.17 V 65.55 Z" />
    <path style={iconStyle} d="M 86.77 65.55 c 0 3.1 1.38 5.17 3.45 5.17 h 10 c 1.72 0 3.45 -2.41 3.45 -5.17 V 13.45 c 0 -3.1 -1.38 -5.17 -3.45 -5.17 h -10 c -1.72 0 -3.45 2.41 -3.45 5.17 L 86.77 65.55 L 86.77 65.55 Z" />
    <path style={iconStyle} d="M 27.09 69.69 c 0 3.1 1.38 5.17 3.45 5.17 h 10 c 1.72 0 3.45 -2.41 3.45 -5.17 V 9.31 c 0 -3.1 -1.38 -5.17 -3.45 -5.17 h -10 c -1.72 0 -3.45 2.41 -3.45 5.17 V 69.69 Z" />
    <path style={iconStyle} d="M 66.76 69.69 c 0 3.1 1.38 5.17 3.45 5.17 h 10 c 1.72 0 3.45 -2.41 3.45 -5.17 V 9.31 c 0 -3.1 -1.38 -5.17 -3.45 -5.17 h -10 c -1.72 0 -3.45 2.41 -3.45 5.17 V 69.69 Z" />
    <path style={iconStyle} d="M 46.75 73.83 c 0 3.1 1.38 5.17 3.45 5.17 h 10 c 1.72 0 3.45 -2.41 3.45 -5.17 V 5.17 c 0 -3.1 -1.38 -5.17 -3.45 -5.17 h -10 c -1.72 0 -3.45 2.41 -3.45 5.17 V 73.83 Z" />
  </g>;
}

;

export function svgPullquote(iconStyle) {
  return <g>
    <rect style={iconStyle} y="18.61" width="110" height="9.82" />
    <rect style={iconStyle} y="34.98" width="110" height="9.82" />
    <rect style={iconStyle} y="51.34" width="110" height="9.82" />
    <rect style={iconStyle} y="2" width="110" height="1.5" />
    <rect style={iconStyle} y="77.02" width="110" height="1.5" />
  </g>;
}

;
export function svgText(iconStyle,pathStyle,textDashStyle) {
  return <g>
    <path style={iconStyle} d="M 74.05 14.07 v 10.85 h -1.02 c -0.68 -2.37 -1.36 -4.41 -2.03 -5.43 c -0.68 -1.02 -1.7 -2.03 -3.05 -2.71 c -0.68 -0.34 -2.03 -0.68 -4.07 -0.68 h -3.05 v 31.2 c 0 2.03 0 3.39 0.34 3.73 c 0.34 0.34 0.68 1.02 1.36 1.36 c 0.68 0.34 1.7 0.68 2.71 0.68 h 1.36 v 1.02 H 45.57 V 53.4 h 1.36 c 1.02 0 2.03 -0.34 2.71 -0.68 c 0.68 -0.34 1.02 -0.68 1.36 -1.36 c 0.34 -0.34 0.34 -1.7 0.34 -3.73 v -31.2 h -3.05 c -2.71 0 -4.75 0.68 -5.77 1.7 c -1.7 1.7 -2.71 4.07 -3.39 6.78 H 38.1 V 14.07 L 74.05 14.07 L 74.05 14.07 Z" />
    <polyline style={pathStyle} points="94.4,75.79 94.4,78.5 92.03,78.5" />
    <line style={textDashStyle} x1="86.94" y1="78.5" x2="21.49" y2="78.5" />
    <polyline style={pathStyle} points="18.77,78.5 16.4,78.5 16.4,75.79" />
    <line style={textDashStyle} x1="16.4" y1="71.04" x2="16.4" y2="5.25" />
    <polyline style={pathStyle} points="16.4,2.87 16.4,0.5 18.77,0.5" />
    <line style={textDashStyle} x1="23.86" y1="0.5" x2="89.65" y2="0.5" />
    <polyline style={pathStyle} points="92.03,0.5 94.4,0.5 94.4,2.87" />
    <line style={textDashStyle} x1="94.4" y1="7.62" x2="94.4" y2="73.41" />
    <path style={pathStyle} d="M 84.23 69 c 0 0 1.36 -7.12 -6.44 -7.12 H 35.39 c -7.8 0 -6.44 7.12 -6.44 7.12 M 84.23 54.76 c 0 0 1.36 7.12 -6.44 7.12 H 35.39 c -7.8 0 -6.44 -7.12 -6.44 -7.12" />
  </g>;
}

;
export function svgTimedCarousel(iconStyle) {
  return <g>
    <path style={iconStyle} d="M 80.77 5.84 c 0 1.72 -1.37 3.09 -3.09 3.09 h -45 c -1.72 0 -3.09 -1.37 -3.09 -3.09 V 3.09 c 0 -1.72 1.37 -3.09 3.09 -3.09 h 45 c 1.72 0 3.09 1.37 3.09 3.09 V 5.84 Z" />
    <path style={iconStyle} d="M 80.77 75.91 c 0 1.72 -1.37 3.09 -3.09 3.09 h -45 c -1.72 0 -3.09 -1.37 -3.09 -3.09 v -2.75 c 0 -1.72 1.37 -3.09 3.09 -3.09 h 45 c 1.72 0 3.09 1.37 3.09 3.09 V 75.91 Z" />
    <path style={iconStyle} d="M 34.06 5.5 v 2.06 c 0 18.2 9.27 32.63 20.95 32.63 c 11.68 0 20.95 -14.43 20.95 -32.63 V 5.5 H 34.06 Z M 71.49 9.62 c -0.69 14.77 -7.9 26.45 -16.83 26.45 c -8.59 0 -15.8 -11.68 -16.83 -26.45 H 71.49 Z" />
    <path style={iconStyle} d="M 34.06 71.07 v 2.06 h 41.9 v -2.06 c 0 -18.2 -9.27 -32.63 -20.95 -32.63 C 43.33 38.78 34.06 52.87 34.06 71.07 M 55.01 42.9 c 8.59 0 15.8 11.68 16.83 26.45 H 38.18 C 38.86 54.58 46.08 42.9 55.01 42.9" />
    <path style={iconStyle} d="M 42.64 20.27 c 0 0 9.62 27.13 24.73 2.4 C 67.37 22.67 56.38 26.1 42.64 20.27" />
    <path style={iconStyle} d="M 62.91 60.08 c -4.12 -2.06 -6.18 -4.81 -6.18 -4.81 l -1.72 -9.96 l 0 0 l 0 0 l -1.72 9.96 c 0 0 -2.06 2.75 -6.18 4.81 c 0 0 -6.53 2.75 -4.81 5.84 h 25.07 C 69.09 62.83 62.91 60.08 62.91 60.08" />
  </g>;
}

;
export function svgVideo(iconStyle,pathStyle) {
  return <g>
    <path style={iconStyle} d="M 4.88 74.57 c 0 2.4 2.06 4.47 4.47 4.47 h 91.71 c 2.4 0 4.47 -2.06 4.47 -4.47 V 4.5 c 0 -2.4 -2.06 -4.47 -4.47 -4.47 H 9.35 c -2.4 0 -4.47 2.06 -4.47 4.47 V 74.57 Z" />
    <rect style={pathStyle} x="13.47" y="8.62" width="83.12" height="61.48" />
    <path style={iconStyle} d="M 87.66 22.01 v 37.1 c 0 1.37 -0.69 1.72 -1.72 0.69 L 68.08 45.71 v 12.37 c 0 1.03 -1.03 2.06 -2.06 2.06 H 24.46 c -1.03 0 -2.06 -1.03 -2.06 -2.06 V 22.7 c 0 -1.03 1.03 -2.06 2.06 -2.06 h 41.56 c 1.03 0 2.06 1.03 2.06 2.06 v 12.37 l 17.86 -14.08 C 86.97 20.3 87.66 20.64 87.66 22.01 Z" />
  </g>;
}

;
export function svgZoom(iconStyle) {
  return <g>
    <path style={iconStyle} d="M 25.46 9.56 c -12.75 12.75 -12.75 33.43 0 46.18 c 11.37 11.37 29.29 12.75 42.04 3.45 l 18.26 18.26 c 2.07 2.07 5.51 2.07 7.58 0 c 2.07 -2.07 2.07 -5.51 0 -7.58 L 75.09 51.6 c 8.96 -12.75 7.93 -30.67 -3.45 -42.04 C 58.89 -3.19 38.21 -3.19 25.46 9.56 Z M 65.09 49.19 c -8.96 8.96 -23.78 8.96 -33.08 0 c -8.96 -8.96 -8.96 -23.78 0 -33.08 c 8.96 -8.96 23.78 -8.96 33.08 0 C 74.4 25.41 74.4 40.23 65.09 49.19 Z M 46.83 12.66 C 35.8 13.7 27.88 23.35 28.91 34.03 c 0 1.03 1.03 1.72 1.72 1.38 c 1.03 0 1.72 -1.03 1.38 -1.72 c -0.69 -8.96 5.86 -17.23 15.16 -17.92 c 1.03 0 1.72 -1.03 1.38 -1.72 C 48.55 13.35 47.52 12.66 46.83 12.66 Z" />
    <rect style={iconStyle} x="36.83" y="31.62" width="24.12" height="4.48" />
    <rect style={iconStyle} x="46.94" y="21.77" width="4.48" height="24.12" />
  </g>;
}

;
