import React, { Suspense, useState } from 'react';
import './SupportOverlay.scss';
// import Support from './Support.jsx';
import SVGIcon from '@/shared/components/SVGIcon/SVGIcon';
import TopbarButtonTooltip from '@/shared/components/TopbarButtonTooltip/TopbarButtonTooltip';

const Support = React.lazy(() => import('./Support'));

const SupportOverlay = (props) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className='SupportOverlay'>
      <button className={'support-toggle-button '} onClick={toggleOpen}>
        <SVGIcon type={'support'} fill={'#fff'} />
        <TopbarButtonTooltip text='Support' />
      </button>

      <div className={'support-display'}>
        <Suspense fallback={null}>{open && <Support open={open} setOpen={setOpen} />}</Suspense>
      </div>
    </div>
  );
};

export default SupportOverlay;
