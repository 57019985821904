import React from 'react';

export function svgAccordionDragToOrder(iconStyle) {//change color of draggables
  const noDragStyle={ fill:'#637c84' };
  const arrowStyle={ fill:'#ffffff' };

  return <g>
    <defs>
      <filter id="acc-arrow-drop" width="110" height="67">
        <feDropShadow dx="0" dy="1" stdDeviation="2" />
      </filter>
    </defs>
    <rect style={noDragStyle} width="110" height="8.28 "/>
    <rect style={noDragStyle} y="9.8" width="110" height="8.28 "/>
    <rect style={noDragStyle} y="19.59" width="110" height="8.28" />
    <rect style={noDragStyle} y="39.18" width="110" height="8.28" />
    <rect style={noDragStyle} y="48.88" width="110" height="8.28" />
    <rect style={noDragStyle} y="58.58" width="110" height="8.28" />
    <path className="acc-arrow-dropshadow" d="M 61 8.2 l -6.35 6.33 A 1 1 0 1 0 56.06 16 L 60 12 v 9.2 H 39 v 2 H 83 v -2 H 62 V 12 l 3.94 4 a 1 1 0 1 0 1.44 -1.44 L 61 8.2 Z" />
    <path style={arrowStyle} d="M 61 8.2 l -6.35 6.33 A 1 1 0 1 0 56.06 16 L 60 12 v 9.2 H 39 v 2 H 83 v -2 H 62 V 12 l 3.94 4 a 1 1 0 1 0 1.44 -1.44 L 61 8.2 Z" />
    <rect className="acc-arrow-dropshadow" y="23.25" width="110" height="8.28"/>
    <rect style={iconStyle} y="23.25" width="110" height="8.28"/>
    <path className="acc-arrow-dropshadow" d="M 61 46.6 l 6.35 -6.33 a 1 1 0 0 0 -1.43 -1.44 L 62 42.76 V 33.6 H 83 v -2 H 39 v 2 H 60 v 9.16 l -3.94 -3.93 a 1 1 0 0 0 -1.44 1.44 L 61 46.6 Z" />
    <path style={arrowStyle} d="M 61 46.6 l 6.35 -6.33 a 1 1 0 0 0 -1.43 -1.44 L 62 42.76 V 33.6 H 83 v -2 H 39 v 2 H 60 v 9.16 l -3.94 -3.93 a 1 1 0 0 0 -1.44 1.44 L 61 46.6 Z" />
  </g>;
}

;

export function svgCategoryMatchingL(iconStyle,pathStyle) {
  const noDragStyle={ fill:'#637c84' };
  const inactiveStyle={ fill:'#637c84' };

  return <g>
    <defs>
      <filter id="icon-drag" width="110" height="67">
        <feDropShadow dx="0" dy="1.7" stdDeviation="2" />
      </filter>
    </defs>
    <polygon style={inactiveStyle} points="0.3,10 0.7,0.1 36.6,2.4 36.3,12.3" />
    <polygon style={inactiveStyle} points="1.1,12.3 0,2.5 35.9,0 36.9,9.9" />
    <rect style={noDragStyle} x="38.3" width="35" height="10" />
    <rect style={noDragStyle} x="75" width="35" height="10" />
    <rect style={inactiveStyle} x="38.3" y="16.8" width="35" height="10" />
    <rect style={inactiveStyle} x="38.3" y="29.9" width="35" height="10" />
    <rect style={inactiveStyle} x="38.3" y="42.9" width="35" height="10" />
    <rect style={inactiveStyle} x="38.3" y="56" width="35" height="10" />
    <rect className="icon-drop" style={pathStyle} x="39.3" y="70" width="33" height="8" />
    <rect style={inactiveStyle} x="75" y="16.8" width="35" height="10" />
    <rect style={inactiveStyle} x="75" y="29.9" width="35" height="10" />
    <rect className="icon-drop" style={pathStyle} x="76" y="43.9" width="33" height="8" />
    <polygon className="icon-drag" style={iconStyle} points="11,17.2 10.7,7.3 46.7,7.2 47,17.1" />
  </g>;
}

;

export function svgCategoryMatchingR(iconStyle,pathStyle) {
  const noDragStyle={ fill:'#637c84' };
  const inactiveStyle={ fill:'#637c84' };

  return <g>
    <defs>
      <filter id="icon-drag" width="110" height="67">
        <feDropShadow dx="0" dy="1.7" stdDeviation="2" />
      </filter>
    </defs>
    <polygon style={inactiveStyle} points="73.1,9.9 74.1,0 110,2.5 108.9,12.3" />
    <polygon style={inactiveStyle} points="73.7,12.3 73.4,2.4 109.3,0.1 109.7,10" />
    <rect style={noDragStyle} x="36.7" width="35" height="10" />
    <rect style={noDragStyle} width="35" height="10" />
    <rect style={inactiveStyle} x="36.7" y="16.8" width="35" height="10" />
    <rect style={inactiveStyle} x="36.7" y="29.9" width="35" height="10" />
    <rect style={inactiveStyle} x="36.7" y="42.9" width="35" height="10" />
    <rect style={inactiveStyle} x="36.7" y="56" width="35" height="10" />
    <rect className="icon-drop" style={pathStyle} x="37.7" y="70" width="33" height="8" />
    <rect style={inactiveStyle} y="16.8" width="35" height="10" />
    <rect style={inactiveStyle} y="29.9" width="35" height="10" />
    <rect className="icon-drop" style={pathStyle} x="1" y="43.9" width="33" height="8" />
    <polygon className="icon-drag" style={iconStyle} points="63,17.1 63.3,7.2 99.3,7.3 99,17.2" />
  </g>;
}

;

export function svgDragImageMatchingL(iconStyle,pathStyle) {
  const noDragStyle={ fill:'#637c84' };

  return <g>
    <defs>
      <filter id="icon-drag" width="110" height="67">
        <feDropShadow dx="0" dy="1.7" stdDeviation="2" />
      </filter>
    </defs>
    <rect className="icon-drop" style={pathStyle} x="39.3" y="4.8" width="33" height="33" />
    <rect className="icon-drop" style={pathStyle} x="75.8" y="4.8" width="33" height="33" />
    <rect className="icon-drop" style={pathStyle} x="39.3" y="41.2" width="33" height="33" />
    <rect className="icon-drop" style={pathStyle} x="75.8" y="41.2" width="33" height="33" />
    <rect style={noDragStyle} transform="matrix(0.0667455 -0.9978 0.9978 0.0667455 -3.5207 38.8359)" x="2" y="4.3" width="34" height="34" />
    <rect style={noDragStyle} transform="matrix(0.9967 -0.0817344 0.0817344 0.9967 -1.6774 1.6242)" x="2" y="4.3" width="34" height="34" />
    <rect style={noDragStyle} transform="matrix(1 -0.00901855 0.00901855 1 -0.1913 0.1722)" x="2" y="4.3" width="34" height="34" />
    <rect className="icon-drag" style={iconStyle} transform="matrix(1 -0.00865937 0.00865937 1 -0.2362 0.2558)" x="12.4" y="10.4" width="34" height="34" />
  </g>;
}

;

export function svgDragImageMatchingR(iconStyle,pathStyle) {
  const noDragStyle={ fill:'#637c84' };

  return <g>
    <defs>
      <filter id="icon-drag" width="110" height="67">
        <feDropShadow dx="0" dy="1.7" stdDeviation="2" />
      </filter>
    </defs>
    <rect className="icon-drop" style={pathStyle} x="37.7" y="4.8" width="33" height="33" />
    <rect className="icon-drop" style={pathStyle} x="1.2" y="4.8" width="33" height="33" />
    <rect className="icon-drop" style={pathStyle} x="37.7" y="41.2" width="33" height="33" />
    <rect className="icon-drop" style={pathStyle} x="1.2" y="41.2" width="33" height="33" />
    <rect style={noDragStyle} transform="matrix(0.9978 -0.0667455 0.0667455 0.9978 -1.219 6.1152)" x="73.9" y="4.3" width="34" height="34" />
    <rect style={noDragStyle} transform="matrix(0.0817344 -0.9967 0.9967 0.0817344 62.2496 110.164)" x="73.9" y="4.3" width="34" height="34" />
    <rect style={noDragStyle} transform="matrix(0.00901855 -1 1 0.00901855 68.7897 112.013)" x="73.9" y="4.3" width="34" height="34" />
    <rect className="icon-drag" style={iconStyle} transform="matrix(0.00865937 -1 1 0.00865937 52.3917 107.649)" x="63.5" y="10.4" width="34" height="34" />
  </g>;
}

;

export function svgDragMatchingL(iconStyle,pathStyle) {
  const noDragStyle={ fill:'#637c84' };

  return <g>
    <defs>
      <filter id="icon-drag" width="110" height="67">
        <feDropShadow dx="0" dy="1.7" stdDeviation="2" />
      </filter>
    </defs>
    <rect style={pathStyle} x="27" width="82.9" height="79" />
    <path style={noDragStyle} d="M 27 0 v 79 h 83 V 0 L 27 0 Z M 101.6 9.4 H 63.4 V 7.1 h 38.2 V 9.4 Z M 66.9 69.6 h 31.2 v 2.3 H 66.9 V 69.6 Z M 61.4 54 h 42.2 v 2.3 H 61.4 V 54 Z M 69 40.7 v -2.3 H 96 v 2.3 H 69 Z M 59 22.7 h 46.9 V 25 H 59 V 22.7 Z M 29.1 66.6 h 25.8 v 8.3 H 29.1 V 66.6 Z M 29.1 51 h 25.8 v 8.3 H 29.1 V 51 Z M 29.1 35.4 h 25.8 v 8.3 H 29.1 V 35.4 Z M 29.1 19.7 h 25.8 V 28 H 29.1 V 19.7 Z M 29.1 4.1 h 25.8 v 8.3 H 29.1 V 4.1 Z" />
    <rect style={noDragStyle} x="0" y="4.1" width="25.8" height="8.3" />
    <rect style={noDragStyle} x="0" y="19.7" width="25.8" height="8.3" />
    <rect style={noDragStyle} x="0" y="51" width="25.8" height="8.3" />
    <rect style={noDragStyle} x="0" y="66.7" width="25.8" height="8.3" />
    <rect className="icon-drag" style={iconStyle} x="8.5" y="30.6" width="25.8" height="8.3" />

  </g>;
}

;

export function svgDragMatchingR(iconStyle,pathStyle) {
  const noDragStyle={ fill:'#637c84' };

  return <g>
    <defs>
      <filter id="icon-drag" width="110" height="67">
        <feDropShadow dx="0" dy="1.7" stdDeviation="2" />
      </filter>
    </defs>
    <rect style={pathStyle} width="82.9" height="79" />
    <path style={noDragStyle} d="M 0 0 v 79 h 83 V 0 H 0 Z M 8.4 7.1 h 38.2 v 2.3 H 8.4 V 7.1 Z M 43.1 71.9 H 11.9 v -2.3 h 31.2 V 71.9 Z M 48.6 56.3 H 6.4 V 54 h 42.2 V 56.3 Z M 14 40.7 v -2.3 H 41 v 2.3 H 14 Z M 51 25 H 4.1 v -2.3 H 51 V 25 Z M 80.9 74.9 H 55.1 v -8.3 h 25.8 V 74.9 Z M 80.9 59.3 H 55.1 V 51 h 25.8 V 59.3 Z M 80.9 43.6 H 55.1 v -8.3 h 25.8 V 43.6 Z M 80.9 28 H 55.1 v -8.3 h 25.8 V 28 Z M 80.9 12.3 H 55.1 V 4.1 h 25.8 V 12.3 Z" />
    <rect style={noDragStyle} x="84.2" y="4.1" width="25.8" height="8.3" />
    <rect style={noDragStyle} x="84.2" y="19.7" width="25.8" height="8.3" />
    <rect style={noDragStyle} x="84.2" y="51" width="25.8" height="8.3" />
    <rect style={noDragStyle} x="84.2" y="66.7" width="25.8" height="8.3" />
    <rect className="icon-drag" style={iconStyle} x="75.7" y="30.6" width="25.8" height="8.3" />
  </g>;
}

;

export function svgDragMediaToMediaL(iconStyle,pathStyle) {
  const noDragStyle={ fill:'#637c84' };

  return <g>
    <defs>
      <filter id="icon-drag" width="110" height="67">
        <feDropShadow dx="0" dy="1.7" stdDeviation="2" />
      </filter>
    </defs>
    <rect style={noDragStyle} x="1.2" y="1.3" width="68.8" height="76.4" />
    <path className="icon-drop" style={pathStyle} d="M 1.2 1.3 v 76.4 H 70 V 1.3 H 1.2 Z M 49.4 28.3 c 0 -4.2 3.2 -7.7 7.5 -7.7 s 7.5 3.2 7.5 7.5 v 0.2 c 0.2 4.2 -3.1 7.7 -7 7.9 c -4.2 0.2 -7.7 -3.1 -7.9 -7 C 49.4 29.1 49.4 28.6 49.4 28.3 Z M 5.4 58.7 V 39.6 L 18 20.3 l 20.7 25.8 l 8.7 -7 l 18.5 19.5 L 5.4 58.7 L 5.4 58.7 Z" />
    <rect style={noDragStyle} x="73.4" width="36.6" height="10" />
    <rect style={noDragStyle} x="73.4" y="12" width="36.6" height="10" />
    <rect style={noDragStyle} x="73.4" y="24" width="36.6" height="10" />
    <rect className="icon-drag" style={iconStyle} x="53.2" y="30.5" width="36.6" height="10" />
    <rect style={noDragStyle} x="73.4" y="48" width="36.6" height="10" />
    <rect style={noDragStyle} x="73.4" y="60" width="36.6" height="10" />
  </g>;
}

;

export function svgDragMediaToMediaR(iconStyle,pathStyle) {
  const noDragStyle={ fill:'#637c84' };

  return <g>
    <defs>
      <filter id="icon-drag" width="110" height="67">
        <feDropShadow dx="0" dy="1.7" stdDeviation="2" />
      </filter>
    </defs>
    <rect style={noDragStyle} x="41.3" y="3" width="66.6" height="72.9" />
    <path className="icon-drop" style={pathStyle} d="M 40 1.3 v 76.4 h 68.8 V 1.3 H 40 Z M 88.2 28.3 c 0 -4.2 3.2 -7.7 7.5 -7.7 s 7.5 3.2 7.5 7.5 v 0.2 c 0.2 4.2 -3.1 7.7 -7 7.9 c -4.2 0.2 -7.7 -3.1 -7.9 -7 C 88.2 29.1 88.2 28.6 88.2 28.3 Z M 44.2 58.7 V 39.6 l 12.5 -19.2 l 20.7 25.8 l 8.7 -7 l 18.5 19.5 L 44.2 58.7 L 44.2 58.7 Z" />
    <rect style={noDragStyle} x="0" y="0" width="36.6" height="10" />
    <rect style={noDragStyle} x="0" y="12" width="36.6" height="10" />
    <rect style={noDragStyle} x="0" y="24" width="36.6" height="10" />
    <rect className="icon-drag" style={iconStyle} x="20.2" y="30.5" width="36.6" height="10" />
    <rect style={noDragStyle} x="0" y="48" width="36.6" height="10" />
    <rect style={noDragStyle} x="0" y="60" width="36.6" height="10" />
  </g>;
}

;

export function svgDragToLabelImageMediaR(iconStyle,pathStyle) {
  const noDragStyle={ fill:'#637c84' };
  const noDragStyleActive={ fill:'#637c84', opacity:.5 };

  return <g>
    <defs>
      <filter id="icon-drag" width="110" height="67" y="-20" x="-20">
        <feDropShadow dx="0" dy="1.7" stdDeviation="2" />
      </filter>
    </defs>
    <rect style={pathStyle} x="36" width="73.9" height="79" />
    <path style={noDragStyle} d="M 36 0 v 79 h 74 V 0 H 36 Z M 48.2 59.1 c -1.7 0 -3 -1.3 -3 -3 s 1.3 -3 3 -3 c 1.7 0 3 1.3 3 3 S 49.9 59.1 48.2 59.1 Z M 69.8 14.8 c -1.7 0 -3 -1.3 -3 -3 s 1.3 -3 3 -3 s 3 1.3 3 3 S 71.4 14.8 69.8 14.8 Z M 80.5 72.3 c -1.7 0 -3 -1.3 -3 -3 s 1.3 -3 3 -3 s 3 1.3 3 3 S 82.2 72.3 80.5 72.3 Z M 100.1 38.6 c -1.7 0 -3 -1.3 -3 -3 s 1.3 -3 3 -3 s 3 1.3 3 3 S 101.7 38.6 100.1 38.6 Z" />
    <rect style={noDragStyle} x="7" width="27.8" height="8.3" />
    <circle style={noDragStyle} cx="3" cy="4.1" r="3" />
    <rect style={noDragStyle} x="7" y="12.7" width="27.8" height="8.3" />
    <circle style={noDragStyle} cx="3" cy="16.8" r="3" />
    <rect style={noDragStyle} x="7" y="25.4" width="27.8" height="8.3" />
    <circle style={noDragStyle} cx="3" cy="29.5" r="3" />
    <rect style={noDragStyle} x="7" y="38" width="27.8" height="8.3" />
    <circle style={noDragStyleActive} cx="3" cy="42.2" r="3" />
    <circle className="icon-drag" style={iconStyle} cx="45.8" cy="54.6" r="3" />
    <rect style={noDragStyle} x="7" y="50.7" width="27.8" height="8.3" />
    <circle style={noDragStyle} cx="3" cy="54.8" r="3" />
  </g>;
}

;

export function svgDragToLabelImageMediaL(iconStyle,pathStyle) {
  const noDragStyle={ fill:'#637c84' };
  const noDragStyleActive={ fill:'#637c84', opacity:.5 };

  return <g>
    <defs>
      <filter id="icon-drag" width="110" height="67">
        <feDropShadow dx="0" dy="1.7" stdDeviation="2" />
      </filter>
    </defs>
    <rect style={pathStyle} width="73.9" height="79" />
    <path style={noDragStyle} d="M 0 0 l 0 79 h 74 V 0 L 0 0 Z M 58.8 56.1 c 0 -1.7 1.3 -3 3 -3 c 1.7 0 3 1.3 3 3 s -1.3 3 -3 3 C 60.1 59.1 58.8 57.7 58.8 56.1 Z M 37.2 11.8 c 0 -1.7 1.3 -3 3 -3 s 3 1.3 3 3 s -1.3 3 -3 3 S 37.2 13.4 37.2 11.8 Z M 26.5 69.3 c 0 -1.7 1.3 -3 3 -3 s 3 1.3 3 3 s -1.3 3 -3 3 S 26.5 70.9 26.5 69.3 Z M 6.9 35.6 c 0 -1.7 1.3 -3 3 -3 s 3 1.3 3 3 s -1.3 3 -3 3 S 6.9 37.2 6.9 35.6 Z" />
    <rect style={noDragStyle} x="75.2" y="0" width="27.8" height="8.3" />
    <circle style={noDragStyle} cx="107" cy="4.2" r="3" />
    <rect style={noDragStyle} x="75.2" y="12.7" width="27.8" height="8.3" />
    <circle style={noDragStyle} cx="107" cy="16.8" r="3" />
    <rect style={noDragStyle} x="75.2" y="25.4" width="27.8" height="8.3" />
    <circle style={noDragStyle} cx="107" cy="29.5" r="3" />
    <rect style={noDragStyle} x="75.2" y="38" width="27.8" height="8.3" />
    <circle style={noDragStyleActive} cx="107" cy="42.2" r="3" />
    <circle className="icon-drag" style={iconStyle} cx="64.2" cy="54.6" r="3" />
    <rect style={noDragStyle} x="75.2" y="50.7" width="27.8" height="8.3" />
    <circle style={noDragStyle} cx="107" cy="54.8" r="3" />
  </g>;
}

;

export function svgDragToListMediaR(iconStyle,pathStyle) {
  const noDragStyle={ fill:'#637c84' };

  return <g>
    <defs>
      <filter id="icon-drag" width="110" height="67">
        <feDropShadow dx="0" dy="1.7" stdDeviation="2" />
      </filter>
    </defs>
    <rect className="icon-drop" style={pathStyle} x="55.7" y="1.3" width="53.1" height="76.4" />
    <rect style={noDragStyle} x="0" y="0" width="53.1" height="10" />
    <rect style={noDragStyle} x="0" y="12" width="53.1" height="10" />
    <rect style={noDragStyle} x="0" y="24" width="53.1" height="10" />
    <rect className="icon-drag" style={iconStyle} x="15.6" y="30.5" width="53.1" height="10" />
    <rect style={noDragStyle} x="0" y="48" width="53.1" height="10" />
    <rect style={noDragStyle} x="0" y="60" width="53.1" height="10" />
  </g>;
}

;

export function svgDragToListMediaL(iconStyle,pathStyle) {
  const noDragStyle={ fill:'#637c84' };

  return <g>
    <defs>
      <filter id="icon-drag" width="110" height="67">
        <feDropShadow dx="0" dy="1.7" stdDeviation="2" />
      </filter>
    </defs>
    <rect className="icon-drop" style={pathStyle} x="1.2" y="1.3" width="53.1" height="76.4" />
    <rect style={noDragStyle} x="56.9" width="53.1" height="10" />
    <rect style={noDragStyle} x="56.9" y="12" width="53.1" height="10" />
    <rect style={noDragStyle} x="56.9" y="24" width="53.1" height="10" />
    <rect className="icon-drag" style={iconStyle} x="41.3" y="30.5" width="53.1" height="10" />
    <rect style={noDragStyle} x="56.9" y="48" width="53.1" height="10" />
    <rect style={noDragStyle} x="56.9" y="60" width="53.1" height="10" />
  </g>;
}

;
