import React, { useEffect, useState } from 'react';
import './ReviewOverlay.scss';
import ReviewPanel from './components/ReviewPanel/ReviewPanel';
import SVGIcon from '@/shared/components/SVGIcon/SVGIcon';
import TopbarButtonTooltip from '@/shared/components/TopbarButtonTooltip/TopbarButtonTooltip';
import ErrorBoundary from '@/shared/utilities/ErrorBoundary';

const ReviewOverlay = (props) => {
  const BOUNCE_TIME = 2000;

  const [open, setOpen] = useState(false);
  const [hasUnread, setHasUnread] = useState(false);
  const [bounce, setBounce] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (hasUnread) {
      setBounce(true);
      setTimeout(() => {
        setBounce(false);
      }, BOUNCE_TIME);
    }
  }, [hasUnread]);

  return (
    <div className='ReviewOverlay'>
      <button className={`review-toggle-button ${bounce ? 'bounce' : ''}`} onClick={toggleOpen}>
        <SVGIcon type={'review'} fill={'#fff'} />
        {hasUnread && <div className={'unread'}></div>}
        <TopbarButtonTooltip text='Review' />
      </button>

      <div className={'review-display'}>
        <ErrorBoundary>
          <ReviewPanel open={open} setOpen={setOpen} setHasUnread={setHasUnread} />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default ReviewOverlay;
