import React, { useEffect, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '@/main/pages/home/Home';
import notify from '@/shared/helpers/notification_helper';
import Notifications from '@/notifications/Notifications';
import { useDispatch } from 'react-redux';
import { fetchClients } from './redux/slices/models/clientsSlice';
import { fetchFavorites } from './redux/slices/models/favoritesSlice';
import Preloader from './components/Preloader/Preloader';
import PageLoadingBar from './components/PageLoadingBar/PageLoadingBar';
import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs';
import { setBreadcrumbs } from './redux/slices/features/breadcrumbsFeatureSlice';

// import Editor from '@/editor/Index';
// import Clients from '@/main/pages/clients/Clients';
// import Admin from '@/admin/Admin';
// import Changelog from '@/changelog/Changelog';
// import Profile from '@/profile/Profile';
// import FAQs from '@/faqs/Faqs';
// import Languages from '@/languages/Languages';
// import ManageLanguage from '@/languages/partials/ManageLanguage';
// import CreateLanguageForm from '@/languages/partials/CreateLanguageForm';

const Admin = React.lazy(() => import('@/admin/Admin'));
const Clients = React.lazy(() => import('@/main/pages/clients/Clients'));
Clients.preload = () => import('@/main/pages/clients/Clients');
const Editor = React.lazy(() => import('@/editor/Index'));
const Changelog = React.lazy(() => import('@/changelog/Changelog'));
const Profile = React.lazy(() => import('@/profile/Profile'));
const FAQs = React.lazy(() => import('@/faqs/Faqs'));
const Languages = React.lazy(() => import('@/languages/Languages'));
const ManageLanguage = React.lazy(() => import('@/languages/partials/ManageLanguage'));
const CreateLanguageForm = React.lazy(() => import('@/languages/partials/CreateLanguageForm'));

const Main = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFavorites());
    dispatch(fetchClients());
  }, [dispatch]);

  useEffect(() => {
    notify.init();

    return () => {
      window.notifications = null;
    };
  }, []);

  const homeWithProps = (
    <Home
      preload={() => {
        Clients.preload();
      }}
    />
  );

  return (
    <Suspense fallback={<PageLoadingBar loading={true} />}>
      {/* <PageLoadingBar loading={true} loop /> */}
      <Notifications />
      <Preloader />
      <BrowserRouter basename={'/'}>
        <Breadcrumbs />
        <Routes>
          <Route exact path='*' element={homeWithProps} />
          <Route path='/home' element={homeWithProps} />
          <Route path='/clients/*' element={<Clients />} />

          <Route path='/profile/*' element={<Profile />} />
          <Route
            path='/courses/:courseID/*'
            element={
              <Editor
                clearBreadcrumbs={() => {
                  dispatch(setBreadcrumbs(null));
                }}
              />
            }
          />
          <Route path='/languages/create' element={<CreateLanguageForm />} />
          <Route path='/languages/:languageID/edit' element={<ManageLanguage />} />
          <Route path='/languages' element={<Languages />} />
          {/* <Route path="/tutorials">
                        <Tutorials />
                        </Route> */}
          <Route path='/faqs' element={<FAQs />} />
          <Route path='/changelog' element={<Changelog />} />
          <Route path='/admin' element={<Admin />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default Main;
