import React from 'react';

const HeaderLogo = () => {
  return (
    <div className='HeaderLogo'>
      <a className='logo' href='/home' title='Home'></a>
      <span className='logo-text' title='Chameleon'></span>
    </div>
  );
};

export default HeaderLogo;
