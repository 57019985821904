import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './Breadcrumbs.scss';

const Breadcrumbs = ({ children }) => {
  const breadcrumbs = useSelector((state) => state?.breadcrumbs?.list);

  if (!breadcrumbs) return null;

  return (
    <div className='Breadcrumbs' name="breadcrumbs-nav">
      <div className='sub-nav'>
        <nav className='sub-nav-left'>
          <ul>
            {Array.isArray(breadcrumbs) &&
              breadcrumbs
                .map((breadcrumb, index) => {
                  // if rrlink is current location, remove it
                  if (breadcrumb.rrlink === window.location.pathname) {
                    return { ...breadcrumb, rrlink: null };
                  }

                  return breadcrumb;
                })
                .map((breadcrumb, index) => {
                  const liClass = [index === 0 ? 'strong' : '', breadcrumb?.skipTranslate ? 'notranslate' : '']
                    .filter(Boolean)
                    .join(' ');

                  return (
                    <li className={liClass} key={index}>
                      {breadcrumb.link && <a href={breadcrumb.link}>{breadcrumb.text}</a>}
                      {breadcrumb.rrlink && <Link to={breadcrumb.rrlink}>{breadcrumb.text}</Link>}
                      {breadcrumb.onClick && <a onClick={breadcrumb.onClick}>{breadcrumb.text}</a>}
                      {!breadcrumb.link && !breadcrumb.rrlink && !breadcrumb.onClick && <span>{breadcrumb.text}</span>}
                    </li>
                  );
                })}
          </ul>
        </nav>
        {children && <div className='sub-nav-right'>{children}</div>}
      </div>
    </div>
  );
};

Breadcrumbs.propTypes = {
  children: PropTypes.node,
};

export default Breadcrumbs;
