import ToastNotification from '@/shared/components/ToastNotification/ToastNotification';
import React from 'react';
import axios from 'axios';

const ConcedeSessionNotification = props => {
  const { message, onClose, meta, timestamp } = props;
  const deleteAndRedirect = async() => {
    try {
      await axios.post('/api/acceptboot', {
        notify:meta?.requested_by,
      });
      window.location.href = '/home';
    } catch (error) {
      console.error('Error deleting resource:', error);
    }
  };

  const handleClose = async() => {
    try {
      await axios.post('/api/declineboot', {
        notify:meta?.requested_by,
      });
      onClose();
    } catch (error) {
      console.error('Error deleting resource:', error);
    }
  };

  return <ToastNotification type={'warning'} message={message} timestamp={timestamp} onClose={handleClose}>
    <div className="link"><a onClick={deleteAndRedirect}>Concede Session</a></div>
  </ToastNotification>;
};

export default ConcedeSessionNotification;
