import React, { useState } from 'react';
import './ReviewPanel.scss';
import StyledTabs from '@/shared/components/StyledTabs/StyledTabs';
import PageTitle from '@/shared/components/PageTitle/PageTitle';
import CommentsBoard from '../CommentsBoard/CommentsBoard';
import IssueBoard from '../IssueBoard/IssueBoard';
const ReviewPanel = (props) => {
  const { open } = props;

  const [selectedTab, setSelectedTab] = useState('general');

  if (!open) {
    return null;
  }

  return (
    <div className='ReviewPanel'>
      <div className={'review-display'}>
        <div className={'heading'}>
          <PageTitle
            title={'Chameleon Review'}
            text={'Leave feedback, create and assign issues, or take notes here. '}
          />
        </div>
        <div className={'tabs'}>
          <StyledTabs
            activeTab={selectedTab}
            setActiveTab={setSelectedTab}
            tabs={[
              { key: 'general', label: 'General Comments' },
              { key: 'issues', label: 'Issue Board' },
            ]}
          />
        </div>
        <div className={'content'}>
          {selectedTab == 'general' && <CommentsBoard />}
          {selectedTab == 'issues' && <IssueBoard />}
        </div>
      </div>
    </div>
  );
};

export default ReviewPanel;
