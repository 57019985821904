import { configureStore } from '@reduxjs/toolkit';
import courseIssuesReducer from './slices/courseIssuesSlice';
import courseIssueStatusesReducer from './slices/courseIssueStatusesSlice';
import courseIssuePrioritiesReducer from './slices/courseIssuePrioritiesSlice';
import usersReducer from './slices/usersSlice';

const store = configureStore({
  reducer: {
    courseIssues: courseIssuesReducer,
    courseIssueStatuses: courseIssueStatusesReducer,
    courseIssuePriorities: courseIssuePrioritiesReducer,
    users: usersReducer,
  },
});

export default store;
