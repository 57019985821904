import React from 'react';

export function svgVerticalList(iconStyle) {
  return <g>
    <rect style={{ 'fill':'#374F5A' }} width="110" height="79"/>
    <rect x="5" y="5" style={{ 'fill':'#FFFFFF' }} width="50" height="3"/>
    <rect x="5" y="10" style={{ 'fill':'#FFFFFF' }} width="100" height="1"/>
    <rect x="5" y="20" style={{ 'fill':'#FFFFFF' }} width="100" height="1"/>
    <rect x="5" y="13" style={{ 'fill':'#FFFFFF' }} width="50" height="5"/>
    <rect x="13.8" y="63.1" style={{ 'fill':'#FFFFFF' }} width="50" height="3"/>
    <rect x="13.8" y="33.1" style={{ 'fill':'#FFFFFF' }} width="50" height="3"/>
    <rect x="13.8" y="43.1" style={{ 'fill':'#FFFFFF' }} width="50" height="3"/>
    <rect x="13.8" y="53.1" style={{ 'fill':'#FFFFFF' }} width="50" height="3"/>
    <path style={{ 'fill':'#FFFFFF' }} d="M8,73.6V25.2h1v48.4H8z M8.5,23.9c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5S7,26.2,7,25.4S7.7,23.9,8.5,23.9z
            M8.5,33.1c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5S7,35.4,7,34.6S7.7,33.1,8.5,33.1z M8.5,43.1c0.8,0,1.5,0.7,1.5,1.5
            s-0.7,1.5-1.5,1.5S7,45.4,7,44.6S7.7,43.1,8.5,43.1z M8.5,53.1c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5S7,55.4,7,54.6
            S7.7,53.1,8.5,53.1z M8.5,63.1c0.8,0,1.5,0.7,1.5,1.5c0,0.8-0.7,1.5-1.5,1.5S7,65.4,7,64.6C7,63.8,7.7,63.1,8.5,63.1z M8.5,71.8
            c0.8,0,1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5S7,74.1,7,73.3S7.7,71.8,8.5,71.8z"/>
  </g>;
}

;

export function svgHorizontalMedia(iconStyle){
  return <g>
    <rect style={{ 'fill':'#374F5A' }} width="110" height="79"/>
    <rect x="5" y="5" style={{ 'fill':'#FFFFFF' }} width="28" height="20.1"/>
    <rect x="5" y="28.6" style={{ 'fill':'#FFFFFF' }} width="20.5" height="2.7"/>
    <rect x="5" y="33.5" style={{ 'fill':'#FFFFFF' }} width="28" height="1.7"/>
    <rect x="41" y="5" style={{ 'fill':'#FFFFFF' }} width="28" height="20.1"/>
    <rect x="41" y="28.6" style={{ 'fill':'#FFFFFF' }} width="20.5" height="2.7"/>
    <rect x="41" y="33.5" style={{ 'fill':'#FFFFFF' }} width="28" height="1.7"/>
    <rect x="77" y="5" style={{ 'fill':'#FFFFFF' }} width="28" height="20.1"/>
    <rect x="77" y="28.6" style={{ 'fill':'#FFFFFF' }} width="20.5" height="2.7"/>
    <rect x="77" y="33.5" style={{ 'fill':'#FFFFFF' }} width="28" height="1.7"/>
    <rect x="5" y="43.8" style={{ 'fill':'#FFFFFF' }} width="28" height="20.1"/>
    <rect x="5" y="67.3" style={{ 'fill':'#FFFFFF' }} width="20.5" height="2.7"/>
    <rect x="5" y="72.3" style={{ 'fill':'#FFFFFF' }} width="28" height="1.7"/>
    <rect x="41" y="43.8" style={{ 'fill':'#FFFFFF' }} width="28" height="20.1"/>
    <rect x="41" y="67.3" style={{ 'fill':'#FFFFFF' }} width="20.5" height="2.7"/>
    <rect x="41" y="72.3" style={{ 'fill':'#FFFFFF' }} width="28" height="1.7"/>
  </g>;
}

export function svgVerticalMedia(iconStyle){
  return <g>
    <rect style={{ 'fill':'#374F5A' }} width="110" height="79"/>
    <rect x="5" y="5" style={{ 'fill':'#FFFFFF' }} width="28" height="15"/>
    <rect x="36.7" y="5" style={{ 'fill':'#FFFFFF' }} width="37.7" height="2.7"/>
    <rect x="36.7" y="18.1" style={{ 'fill':'#FFFFFF' }} width="9" height="4"/>
    <rect x="36.7" y="10" style={{ 'fill':'#FFFFFF' }} width="68.3" height="1.7"/>
    <rect x="36.7" y="13" style={{ 'fill':'#FFFFFF' }} width="68.3" height="1.7"/>
    <rect x="5" y="26" style={{ 'fill':'#FFFFFF' }} width="100" height="1"/>

    <rect x="5" y="31" style={{ 'fill':'#FFFFFF' }} width="28" height="15"/>
    <rect x="36.7" y="31" style={{ 'fill':'#FFFFFF' }} width="37.7" height="2.7"/>
    <rect x="36.7" y="44.1" style={{ 'fill':'#FFFFFF' }} width="9" height="4"/>
    <rect x="36.7" y="36" style={{ 'fill':'#FFFFFF' }} width="68.3" height="1.7"/>
    <rect x="36.7" y="39" style={{ 'fill':'#FFFFFF' }} width="68.3" height="1.7"/>

    <rect x="5" y="52" style={{ 'fill':'#FFFFFF' }} width="100" height="1"/>
    <rect x="5" y="56.9" style={{ 'fill':'#FFFFFF' }} width="28" height="15"/>
    <rect x="36.7" y="56.9" style={{ 'fill':'#FFFFFF' }} width="37.7" height="2.7"/>
    <rect x="36.7" y="70" style={{ 'fill':'#FFFFFF' }} width="9" height="4"/>
    <rect x="36.7" y="61.9" style={{ 'fill':'#FFFFFF' }} width="68.3" height="1.7"/>
    <rect x="36.7" y="64.9" style={{ 'fill':'#FFFFFF' }} width="68.3" height="1.7"/>
  </g>;
}
