import ChatAvatar from '@/chat/ChatAvatar';
import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchCourseIssues } from '@/review/redux/slices/courseIssuesSlice';
import './IssueCommentThread.scss';

const IssueCommentThread = (props) => {
  const { issue } = props;

  const [newCommentText, setNewCommentText] = useState('');

  const dispatch = useDispatch();
  const refresh = () => {
    dispatch(fetchCourseIssues());
  };

  const submitComment = () => {
    //api/course_issue_comments/
    axios
      .post('/api/course_issue_comments/', {
        course_issue_id: issue.id,
        comment: newCommentText,
      })
      .then((response) => {
        setNewCommentText('');
        refresh();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className='IssueCommentThread'>
      <div className={'comments'}>
        {(issue?.comments ?? []).map((comment, index) => {
          return (
            <div className={'comment'} key={index}>
              <div className={'commenter'}>
                <ChatAvatar user={comment.user} />
                <div className={'user'}>
                  {comment?.user?.email}
                </div>
              </div>
              <div className={'comment-body'}>{comment.comment}</div>
            </div>
          );
        })}
        {issue?.comments?.length === 0 && <div className={'no-comments'}>No comments yet.</div>}
      </div>
      <div className={'leave-comment'}>
        <textarea
          placeholder={'Leave a comment'}
          value={newCommentText}
          onChange={(e) => setNewCommentText(e.currentTarget.value)}
        />
        <button onClick={submitComment}>Submit</button>
      </div>
    </div>
  );
};

export default IssueCommentThread;
