import React from 'react';

export function svgAccordion(iconStyle) {//default and mediaL
  return <g>
    <rect style={iconStyle} x="51.51" width="58.49" height="8.28" />
    <rect style={iconStyle} x="51.51" y="9.8" width="58.49" height="8.28" />
    <rect style={iconStyle} x="51.51" y="19.59" width="58.49" height="8.28" />
    <rect style={iconStyle} x="51.51" y="29.39" width="58.49" height="8.28" />
    <rect style={iconStyle} x="51.51" y="39.18" width="58.49" height="8.28" />
    <rect style={iconStyle} x="51.51" y="48.88" width="58.49" height="8.28" />
    <rect style={iconStyle} x="51.51" y="58.58" width="58.49" height="8.28" />
    <rect style={iconStyle} width="49.99" height="48.56" />
  </g>;
}

;

export function svgAccordionMediaR(iconStyle) {
  return <g>
    <rect style={iconStyle} width="58.49" height="8.28" />
    <rect style={iconStyle} y="9.8" width="58.49" height="8.28" />
    <rect style={iconStyle} y="19.59" width="58.49" height="8.28" />
    <rect style={iconStyle} y="29.39" width="58.49" height="8.28" />
    <rect style={iconStyle} y="39.18" width="58.49" height="8.28" />
    <rect style={iconStyle} y="48.88" width="58.49" height="8.28" />
    <rect style={iconStyle} y="58.58" width="58.49" height="8.28" />
    <rect style={iconStyle} x="60.01" width="49.99" height="48.56" />
  </g>;
}

;

export function svgAccordionZoomL(iconStyle) {
  const arrowStyle={ fill:'#ffffff' };

  return <g>
    <rect style={iconStyle} x="51.51" width="58.49" height="8.28"/>
    <rect style={iconStyle} x="51.51" y="9.8" width="58.49" height="8.28" />
    <rect style={iconStyle} x="51.51" y="19.59" width="58.49" height="8.28" />
    <rect style={iconStyle} x="51.51" y="29.39" width="58.49" height="8.28" />
    <rect style={iconStyle} x="51.51" y="39.18" width="58.49" height="8.28" />
    <rect style={iconStyle} x="51.51" y="48.88" width="58.49" height="8.28" />
    <rect style={iconStyle} x="51.51" y="58.58" width="58.49" height="8.28" />
    <rect style={iconStyle} width="49.99" height="48.56" />
    <path style={arrowStyle} d="M13.23,12.51A12.83,12.83,0,0,0,29.75,32l7.18,7.17a2.11,2.11,0,1,0,3-3L32.73,29a12.82,12.82,0,0,0-19.5-16.52ZM28.8,28.09a9.19,9.19,0,0,1-13-13,9.31,9.31,0,0,1,13,0A9.07,9.07,0,0,1,28.8,28.09ZM21.63,13.73a7.73,7.73,0,0,0-7,8.4c0,.41.4.68.67.54s.68-.4.55-.67a6.52,6.52,0,0,1,6-7c.41,0,.68-.4.55-.67S21.9,13.73,21.63,13.73Z"/>
    <rect style={arrowStyle} x="17.7" y="21.18" width="9.48" height="1.76" />
    <rect style={arrowStyle} x="21.67" y="17.31" width="1.76" height="9.48" />
  </g>;
}

;

export function svgAccordionZoomR(iconStyle) {
  const arrowStyle={ fill:'#ffffff' };

  return <g>
    <rect style={iconStyle} width="58.49" height="8.28" />
    <rect style={iconStyle} y="9.8" width="58.49" height="8.28" />
    <rect style={iconStyle} y="19.59" width="58.49" height="8.28" />
    <rect style={iconStyle} y="29.39" width="58.49" height="8.28" />
    <rect style={iconStyle} y="39.18" width="58.49" height="8.28" />
    <rect style={iconStyle} y="48.88" width="58.49" height="8.28" />
    <rect style={iconStyle} y="58.58" width="58.49" height="8.28" />
    <rect style={iconStyle} x="60.01" width="49.99" height="48.56" />
    <path style={arrowStyle} d="M73.24,12.51A12.83,12.83,0,0,0,89.76,32l7.18,7.17a2.11,2.11,0,1,0,3-3L92.74,29a12.82,12.82,0,0,0-19.5-16.52ZM88.81,28.09a9.19,9.19,0,0,1-13-13,9.31,9.31,0,0,1,13,0A9.07,9.07,0,0,1,88.81,28.09ZM81.64,13.73a7.73,7.73,0,0,0-7,8.4c0,.41.4.68.67.54s.68-.4.55-.67a6.52,6.52,0,0,1,6-7c.41,0,.68-.4.55-.67S81.91,13.73,81.64,13.73Z" />
    <rect style={arrowStyle} x="77.71" y="21.18" width="9.48" height="1.76" />
    <rect style={arrowStyle} x="81.68" y="17.31" width="1.76" height="9.48" />
  </g>;
}

;

export function svgAccordionNone(iconStyle) {
  return <g>
    <rect style={iconStyle} width="110" height="8.28 "/>
    <rect style={iconStyle} y="9.8" width="110" height="8.28 "/>
    <rect style={iconStyle} y="19.59" width="110" height="8.28" />
    <rect style={iconStyle} y="29.39" width="110" height="8.28" />
    <rect style={iconStyle} y="39.18" width="110" height="8.28" />
    <rect style={iconStyle} y="48.88" width="110" height="8.28" />
    <rect style={iconStyle} y="58.58" width="110" height="8.28" />
  </g>;
}

;

export function svgAttestation(iconStyle) {
  return <g>
    <path style={iconStyle} d="M 37 31.34 l -6.16 6.11 l 19.7 19.92 l 44 -44 L 88.13 7.29 L 50.49 44.66 Z M 85.49 70.25 H 24.24 V 9 h 44 V 0.25 h -44 A 8.8 8.8 0 0 0 15.49 9 V 70.25 A 8.75 8.75 0 0 0 24.24 79 H 85.49 a 8.75 8.75 0 0 0 8.75 -8.75 h 0 V 35.36 H 85.49 Z" />
  </g>;
}

;

export function svgCube(iconStyle,pathStyle) {
  return <g>
    <path style={iconStyle} d="M90.1,20C90.1,20,90.1,20,90.1,20C90.1,20,90.1,20,90.1,20c-0.4-0.7-1-1.2-1.6-1.6L57.1,1c-1.3-0.7-2.9-0.7-4.2,0L21.5,18.5
    c-0.7,0.4-1.2,0.9-1.6,1.6c0,0,0,0,0,0c0,0,0,0,0,0c-0.4,0.6-0.6,1.4-0.6,2.1v34.7c0,1.5,0.8,2.9,2.2,3.7L52.9,78
    c0.6,0.3,1.3,0.5,2,0.5c0,0,0.1,0,0.1,0h0c0,0,0.1,0,0.1,0c0.7,0,1.4-0.2,2-0.5l31.4-17.4c1.3-0.7,2.2-2.2,2.2-3.7V22.1
    C90.6,21.4,90.4,20.7,90.1,20z M54.3,3.5c0.4-0.2,1-0.2,1.4,0l30.6,17l-31,17.4L23.7,20.5L54.3,3.5z M22.9,58.1
    c-0.4-0.2-0.7-0.7-0.7-1.2v-34l31.7,17.4l-0.3,34.8L22.9,58.1z M87.1,58.1l-30.6,17l0.3-34.8l31-17.4v33.9
    C87.8,57.4,87.5,57.8,87.1,58.1z" />
  </g>;
}

;

export function svgFullWidthBgAccordion(iconStyle,pathStyle) {
  return <g>
    <rect style={iconStyle} x="0" width="110" height="8.8" />
    <path style={pathStyle} d="M 73.8 5.8 H 36.2 V 2.9 h 37.5 C 73.8 2.9 73.8 5.8 73.8 5.8 Z" />
    <rect style={iconStyle} x="0" y="57.6" width="110" height="8.8" />
    <path style={pathStyle} d="M 77.9 63.5 H 32.1 v -2.9 h 45.7 C 77.9 60.6 77.9 63.5 77.9 63.5 Z" />
    <rect style={iconStyle} x="0" y="70.2" width="110" height="8.8" />
    <path style={pathStyle} d="M 71.9 76.1 H 38.1 v -2.9 h 33.7 C 71.9 73.2 71.9 76.1 71.9 76.1 Z" />
    <rect style={iconStyle} x="0" y="12.9" width="110" height="41.2" />
    <path style={pathStyle} d="M 67.5 18.4 H 42.5 v -2.9 h 25 C 67.5 15.5 67.5 18.4 67.5 18.4 Z M 23.3 23.3 v 27.3 h 27.3 V 23.3 H 23.3 Z M 42.1 32.7 c 0 -1.6 1.3 -2.9 2.8 -2.9 c 1.6 0 2.8 1.3 2.8 2.8 v 0.1 c 0.1 1.6 -1.1 2.9 -2.6 3 c -1.6 0.1 -2.9 -1.1 -3 -2.6 C 42.1 32.9 42.1 32.7 42.1 32.7 Z M 25.7 44.1 v -7.3 l 4.6 -7.3 l 7.8 9.6 l 3.3 -2.6 l 6.9 7.3 L 25.7 44.1 L 25.7 44.1 Z M 86.7 32.5 h -32 v -2.9 h 31.9 L 86.7 32.5 L 86.7 32.5 Z M 83 45.3 H 54.7 v -2.9 h 28.2 L 83 45.3 L 83 45.3 Z M 80.7 26.2 h -26 v -2.9 h 26 C 80.7 23.3 80.7 26.2 80.7 26.2 Z M 76.1 38.9 H 54.7 V 36 h 21.4 C 76.1 36 76.1 38.9 76.1 38.9 Z" />
  </g>;
}

;

export function svgGraduatedParallax(iconStyle,pathStyle) {
  return <g>
    <rect style={iconStyle} width="110" height="79" />
    <rect style={pathStyle} x="13.2" y="15.5" width="39.4" height="48" />
    <rect style={pathStyle} x="57.4" y="23.6" width="36.6" height="4.8" />
    <rect style={pathStyle} x="57.4" y="32.6" width="39.4" height="4.8" />
    <rect style={pathStyle} x="57.4" y="41.6" width="30.4" height="4.8" />
    <rect style={pathStyle} x="57.4" y="50.6" width="33.1" height="4.8" />
    <circle style={pathStyle} cx="103.6" cy="30" r="3" />
    <circle style={pathStyle} cx="103.6" cy="39.5" r="3" />
    <circle style={pathStyle} cx="103.6" cy="49" r="3" />
  </g>;
}

;

export function svgHotspotClicktell1(iconStyle,pathStyle) {
  return <g>
    <rect style={iconStyle} width="110" height="79" />
    <circle style={pathStyle} cx="93.4" cy="28.8" r="3" />
    <circle style={pathStyle} cx="65.3" cy="62.9" r="3" />
    <circle style={pathStyle} cx="21.5" cy="18.9" r="3" />
    <path style={pathStyle} d="M 5.4 25 v 47.1 h 32.3 V 25 H 5.4 Z M 30.6 28.6 c 1.7 0 3.1 1.4 3.1 3.1 c 0 1.7 -1.4 3.1 -3.1 3.1 s -3.1 -1.4 -3.1 -3.1 C 27.5 30 28.9 28.6 30.6 28.6 Z M 8.7 49.4 h 23.1 v 4.2 H 8.7 V 49.4 Z M 27.9 67.1 H 8.7 v -4.2 h 19.2 V 67.1 Z M 34.4 60.4 H 8.7 v -4.2 h 25.7 V 60.4 Z M 8.7 44 v -7.7 l 5.4 -7.7 l 8.7 10.3 l 3.8 -2.7 l 7.8 7.8 H 8.7 Z" />
  </g>;
}

;

export function svgHotspotClicktell2(iconStyle,pathStyle) {
  return <g>
    <rect style={iconStyle} x="68" width="42" height="72" />
    <rect style={iconStyle} x="78.1" y="74.2" width="21.7" height="4.8" />
    <path style={pathStyle} d="M 71 3 v 66 h 36 V 3 H 71 Z M 99.1 7 c 1.9 0 3.5 1.6 3.5 3.5 S 101 14 99.1 14 c -1.9 0 -3.5 -1.6 -3.5 -3.5 S 97.2 7 99.1 7 Z M 74.7 30.2 h 25.7 v 4.7 H 74.7 V 30.2 Z M 74.7 45.3 h 21.4 V 50 H 74.7 V 45.3 Z M 101.3 57.6 H 74.7 v -4.7 h 26.6 V 57.6 Z M 103.3 42.5 H 74.7 v -4.7 h 28.6 V 42.5 Z M 74.7 24.2 v -8.6 l 6 -8.6 l 9.7 11.5 l 4.2 -3 l 8.7 8.7 H 74.7 Z" />
    <rect style={iconStyle} width="65.9" height="79" />
    <circle style={pathStyle} cx="52.6" cy="30.7" r="3" />
    <circle style={pathStyle} cx="15" cy="14" r="3" />
    <circle style={pathStyle} cx="36.1" cy="66.6" r="3" />
  </g>;
}

;

export function svgHotspotClicktell3(iconStyle,pathStyle) {
  return <g>
    <rect style={iconStyle} width="42" height="72" />
    <rect style={iconStyle} x="10.1" y="74.2" width="21.7" height="4.8" />
    <path style={pathStyle} d="M 3 3 v 66 h 36 V 3 H 3 Z M 31.1 7.1 c 2 0 3.5 1.5 3.5 3.5 S 33.1 14 31.1 14 c -2 0 -3.5 -1.5 -3.5 -3.5 S 29.1 7.1 31.1 7.1 Z M 6.7 30.2 h 25.7 v 4.7 H 6.7 V 30.2 Z M 6.7 45.3 h 21.4 V 50 H 6.7 V 45.3 Z M 33.3 57.6 H 6.7 v -4.7 h 26.6 V 57.6 Z M 35.2 42.5 H 6.7 v -4.7 h 28.6 V 42.5 Z M 6.7 24.2 v -8.6 l 6 -8.6 l 9.8 11.5 l 4.1 -3.1 l 8.8 8.7 H 6.7 Z" />
    <rect style={iconStyle} x="44.1" width="65.9" height="79" />
    <circle style={pathStyle} cx="96.7" cy="30.7" r="3" />
    <circle style={pathStyle} cx="59.1" cy="14" r="3" />
    <circle style={pathStyle} cx="80.2" cy="66.6" r="3" />
  </g>;
}

;

export function svgMultipleImageCarousel(iconStyle,pathStyle) {
  return <g>
    <polygon style={iconStyle} points="110.2,62 0,62 0,17 110,17 110,62" />
    <polygon style={pathStyle} points="48.3,58.8 -0.1,58.8 -0.1,20.2 48.2,20.2 48.2,58.8" />
    <rect style={pathStyle} x="52" y="20.2" width="50.5" height="38.6" />
    <rect style={pathStyle} x="106.2" y="20.2" width="3.8" height="38.6" />
    <rect style={iconStyle} x="4.2" y="11.1" width="40.2" height="56.8" />
  </g>;
}

;

export function svgPanoramavr(iconStyle,pathStyle) {
  const circStyle={ fill:'#ffffff' };

  return <g>
    <path style={iconStyle} d="M 7.08 65.55 c 0 3.1 1.38 5.17 3.45 5.17 h 10 c 1.72 0 3.45 -2.41 3.45 -5.17 V 13.45 c 0 -3.1 -1.38 -5.17 -3.45 -5.17 h -10 c -1.72 0 -3.45 2.41 -3.45 5.17 V 65.55 Z" />
    <path style={iconStyle} d="M 86.77 65.55 c 0 3.1 1.38 5.17 3.45 5.17 h 10 c 1.72 0 3.45 -2.41 3.45 -5.17 V 13.45 c 0 -3.1 -1.38 -5.17 -3.45 -5.17 h -10 c -1.72 0 -3.45 2.41 -3.45 5.17 L 86.77 65.55 L 86.77 65.55 Z" />
    <path style={iconStyle} d="M 27.09 69.69 c 0 3.1 1.38 5.17 3.45 5.17 h 10 c 1.72 0 3.45 -2.41 3.45 -5.17 V 9.31 c 0 -3.1 -1.38 -5.17 -3.45 -5.17 h -10 c -1.72 0 -3.45 2.41 -3.45 5.17 V 69.69 Z" />
    <path style={iconStyle} d="M 66.76 69.69 c 0 3.1 1.38 5.17 3.45 5.17 h 10 c 1.72 0 3.45 -2.41 3.45 -5.17 V 9.31 c 0 -3.1 -1.38 -5.17 -3.45 -5.17 h -10 c -1.72 0 -3.45 2.41 -3.45 5.17 V 69.69 Z" />
    <path style={iconStyle} d="M 46.75 73.83 c 0 3.1 1.38 5.17 3.45 5.17 h 10 c 1.72 0 3.45 -2.41 3.45 -5.17 V 5.17 c 0 -3.1 -1.38 -5.17 -3.45 -5.17 h -10 c -1.72 0 -3.45 2.41 -3.45 5.17 V 73.83 Z" />
    <circle style={circStyle} cx="25" cy="39.5" r="10" />
    <circle style={circStyle} cx="85" cy="39.5" r="10" />
  </g>;
}

;

export function svgProgressionClicktell(iconStyle,pathStyle) {//default and type1
  return <g>
    <rect style={iconStyle} width="110" height="79" />
    <rect style={pathStyle} x="33" y="6.6" width="44" height="7.6" />
    <rect style={pathStyle} x="7.1" y="19.5" width="45.9" height="39.6" />
    <rect style={pathStyle} x="57.1" y="19.5" width="45.9" height="39.6" />
    <rect style={pathStyle} x="7.1" y="64.4" width="19.6" height="7.6" />
  </g>;
}

;

export function svgProgressionClicktell2(iconStyle,pathStyle) {//type2
  return <g>
    <rect style={iconStyle} width="110" height="79" />
    <rect style={pathStyle} x="7" y="7.3" width="96" height="46.8" />
    <rect style={pathStyle} x="7" y="59.4" width="40" height="12.6" />
    <rect style={pathStyle} x="89" y="64.4" width="14" height="7.6" />
    <rect style={pathStyle} x="70" y="64.4" width="14" height="7.6" />
  </g>;
}

;

export function svgProgressionGuided(iconStyle,pathStyle) {
  return <g>
    <rect x="2" y="2" style={pathStyle} width="78" height="77"/>
    <polygon style={iconStyle} points="46,37.4 38.6,43.2 20.9,21.4 10.4,37.7 10.4,53.9 61.7,53.9" />
    <path style={iconStyle} d="M0,0v79h72.2V0H0z M68.2,75h-64V3.9h64V75z" />
    <path style={iconStyle} d="M54.6,34.9c3.4-0.3,6-3.3,5.9-6.8v-0.2c0-3.6-2.8-6.4-6.4-6.4c-3.6,0-6.4,2.9-6.4,6.5c0,0.2,0,0.6,0,0.9
                C48,32.4,51,35,54.6,34.9z" />
    <rect x="73.5" style={iconStyle} width="36.5" height="79"/>
    <rect x="77.3" y="3.9" style={pathStyle} width="28.9" height="5.2" />
    <rect x="77.3" y="11.9" style={pathStyle} width="28.9" height="5.2" />
    <rect x="77.3" y="19.9" style={pathStyle} width="28.9" height="5.2" />
    <rect x="84.7" y="69.8" style={pathStyle} width="14" height="5.2" />
  </g>;
}

;

export function svgQuickHitMinTextClicktell(iconStyle) {//media right
  return <g>
    <rect style={iconStyle} x="0" y="0" width="8.3" height="8.3" />
    <rect style={iconStyle} x="0" y="9.8" width="8.3" height="8.3" />
    <rect style={iconStyle} x="0" y="19.6" width="8.3" height="8.3" />
    <rect style={iconStyle} x="0" y="29.4" width="8.3" height="8.3" />
    <rect style={iconStyle} x="9.9" y="0" width="44.5" height="8.3" />
    <rect style={iconStyle} x="9.9" y="9.8" width="44.5" height="8.3" />
    <rect style={iconStyle} x="9.9" y="19.6" width="44.5" height="8.3" />
    <rect style={iconStyle} x="9.9" y="29.4" width="44.5" height="8.3" />
    <rect style={iconStyle} x="0" y="39.2" width="8.3" height="8.3" />
    <rect style={iconStyle} x="9.9" y="39.2" width="44.5" height="8.3" />
    <rect style={iconStyle} x="56" y="0" width="54" height="66.9" />
  </g>;
}

;

export function svgQuickHitMinTextClicktell2(iconStyle) {//media left
  return <g>
    <rect style={iconStyle} x="101.7" width="8.3" height="8.3" />
    <rect style={iconStyle} x="101.7" y="9.7" width="8.3" height="8.3" />
    <rect style={iconStyle} x="101.7" y="19.6" width="8.3" height="8.3" />
    <rect style={iconStyle} x="101.7" y="29.4" width="8.3" height="8.3" />
    <rect style={iconStyle} x="55.6" y="0" width="44.5" height="8.3" />
    <rect style={iconStyle} x="55.6" y="9.7" width="44.5" height="8.3" />
    <rect style={iconStyle} x="55.6" y="19.6" width="44.5" height="8.3" />
    <rect style={iconStyle} x="55.6" y="29.4" width="44.5" height="8.3" />
    <rect style={iconStyle} x="101.7" y="39.2" width="8.3" height="8.3" />
    <rect style={iconStyle} x="55.6" y="39.2" width="44.5" height="8.3" />
    <rect style={iconStyle} width="54" height="66.9" />
  </g>;
}

;

export function svgQuickHitMinTextNoExtMedia(iconStyle) {//no ext media
  return <g>
    <rect style={iconStyle} x="0" y="0" width="8.3" height="8.3" />
    <rect style={iconStyle} x="0" y="9.8" width="8.3" height="8.3" />
    <rect style={iconStyle} x="0" y="19.6" width="8.3" height="8.3" />
    <rect style={iconStyle} x="0" y="29.4" width="8.3" height="8.3" />
    <rect style={iconStyle} x="0" y="39.2" width="8.3" height="8.3" />
    <rect style={iconStyle} x="0" y="49" width="8.3" height="8.3" />
    <rect style={iconStyle} x="0" y="58.8" width="8.3" height="8.3" />

    <rect style={iconStyle} x="9.9" y="0" width="100.1" height="8.3" />
    <rect style={iconStyle} x="9.9" y="9.8" width="100.1" height="8.3" />
    <rect style={iconStyle} x="9.9" y="19.6" width="100.1" height="8.3" />
    <rect style={iconStyle} x="9.9" y="29.4" width="100.1" height="8.3" />
    <rect style={iconStyle} x="9.9" y="39.2" width="100.1" height="8.3" />
    <rect style={iconStyle} x="9.9" y="49" width="100.1" height="8.3" />
    <rect style={iconStyle} x="9.9" y="58.8" width="100.1" height="8.3" />
  </g>;
}

;

export function svgQuickHitTile(iconStyle,pathStyle) {
  return <g>
    <path style={iconStyle} d="M 0 36.1 c 0 1.1 0.7 1.9 1.5 2 h 31.6 c 0.8 0 1.5 -0.9 1.5 -2 V 5.3 c 0 -1.1 -0.7 -1.9 -1.5 -2 H 1.5 C 0.7 3.4 0 4.3 0 5.3 V 36.1 Z" />
    <path style={pathStyle} d="M 2.7 6.1 v 29.2 h 29.2 V 6.1 H 2.7 Z M 22.8 16.1 c 0 -1.7 1.3 -3.1 3 -3.1 c 1.7 0 3 1.3 3 3 c 0 0 0 0.1 0 0.1 c 0.1 1.7 -1.2 3.1 -2.8 3.2 c -1.7 0.1 -3.1 -1.2 -3.2 -2.8 C 22.8 16.4 22.8 16.2 22.8 16.1 Z M 5.2 28.3 v -7.7 l 5 -7.7 l 8.3 10.3 l 3.5 -2.8 l 7.4 7.8 H 5.2 Z" />
    <path style={iconStyle} d="M 75.4 36.1 c 0 1.1 0.7 1.9 1.5 2 h 31.6 c 0.8 0 1.5 -0.9 1.5 -2 V 5.3 c 0 -1.1 -0.7 -1.9 -1.5 -2 H 76.9 c -0.8 0 -1.5 0.9 -1.5 2 V 36.1 Z" />
    <path style={pathStyle} d="M 78.1 6.1 v 29.2 h 29.2 V 6.1 H 78.1 Z M 98.2 16.1 c 0 -1.7 1.3 -3.1 3 -3.1 c 1.7 0 3 1.3 3 3 c 0 0 0 0.1 0 0.1 c 0.1 1.7 -1.2 3.1 -2.8 3.2 c -1.7 0.1 -3.1 -1.2 -3.2 -2.8 C 98.2 16.4 98.2 16.2 98.2 16.1 Z M 80.6 28.3 v -7.7 l 5 -7.7 l 8.3 10.3 l 3.5 -2.8 l 7.4 7.8 H 80.6 Z" />
    <path style={iconStyle} d="M 37.7 36 c 0 1.1 0.7 1.9 1.5 2 h 31.6 c 0.8 0 1.5 -0.9 1.5 -2 V 5.2 c 0 -1.1 -0.7 -1.9 -1.5 -2 H 39.2 c -0.8 0 -1.5 0.9 -1.5 2 V 36 Z" />
    <path style={pathStyle} d="M 40.4 6.1 v 29.2 h 29.2 V 6.1 H 40.4 Z M 59.5 26 H 42.9 v -4.2 h 16.6 V 26 Z M 64.6 19.4 H 42.9 v -4.2 h 21.7 V 19.4 Z" />
    <path style={iconStyle} d="M 0 73.7 c 0 1.1 0.7 1.9 1.5 2 h 31.6 c 0.8 0 1.5 -0.9 1.5 -2 V 43 c 0 -1.1 -0.7 -1.9 -1.5 -2 H 1.5 c -0.8 0 -1.5 0.9 -1.5 2 V 73.7 Z" />
    <path style={pathStyle} d="M 3 43.8 V 73 h 29.2 V 43.8 H 3 Z M 23.1 63.8 H 6.5 v -4.2 h 16.6 V 63.8 Z M 28.2 57.1 H 6.5 v -4.2 h 21.7 V 57.1 Z" />
    <path style={iconStyle} d="M 37.7 73.7 c 0 1.1 0.7 1.9 1.5 2 h 31.6 c 0.8 0 1.5 -0.9 1.5 -2 V 43 c 0 -1.1 -0.7 -1.9 -1.5 -2 H 39.2 c -0.8 0 -1.5 0.9 -1.5 2 V 73.7 Z" />
    <path style={pathStyle} d="M 40.4 43.8 V 73 h 29.2 V 43.8 H 40.4 Z M 60.5 53.8 c 0 -1.7 1.3 -3.1 3 -3.1 c 1.7 0 3 1.3 3 3 c 0 0 0 0.1 0 0.1 c 0.1 1.7 -1.2 3.1 -2.8 3.2 c -1.7 0.1 -3.1 -1.2 -3.2 -2.8 C 60.5 54 60.5 53.9 60.5 53.8 Z M 42.9 66 v -7.7 l 5 -7.7 L 56.2 61 l 3.5 -2.8 l 7.4 7.8 H 42.9 Z" />
  </g>;
}

;

export function svgSlideshowGallery1(iconStyle,pathStyle) {//default and mediaL
  return <g>
    <rect style={iconStyle} width="110" height="79" />
    <circle style={pathStyle} cx="105" cy="5" r="3" />
    <rect style={pathStyle} x="21.5" y="11.2" width="67" height="60.6" />
    <circle style={pathStyle} cx="94.5" cy="41.5" r="3" />
    <circle style={pathStyle} cx="15.5" cy="41.5" r="3" />
  </g>;
}

;

export function svgSlideshowGallery2(iconStyle,pathStyle) {//default and mediaL
  return <g>
    <rect style={iconStyle} width="110" height="79" />
    <rect style={pathStyle} x="7.2" y="11.1" width="45.5" height="48" />
    <rect style={pathStyle} x="57.3" y="11.1" width="45.5" height="48" />
    <rect style={pathStyle} x="7" y="64.4" width="19.6" height="7.6" />
    <rect style={pathStyle} x="31" y="64.4" width="19.6" height="7.6" />
    <circle style={pathStyle} cx="105" cy="5" r="3" />
  </g>;
}

;

export function svgTab(iconStyle) {//default and mediaL
  return <g>
    <rect style={iconStyle} width="21.6" height="11" />
    <rect style={iconStyle} x="23.1" width="21.6" height="8.3" />
    <rect style={iconStyle} x="46.2" width="21.6" height="8.3" />
    <rect style={iconStyle} y="9.8" width="110" height="57.1" />
  </g>;
}

;

export function svgTabVertical(iconStyle) {//default and mediaL
  return <g>
    <rect style={iconStyle} width="36" height="8.3" />
    <rect style={iconStyle} y="9.8" width="33.5" height="8.3" />
    <rect style={iconStyle} y="19.6" width="33.5" height="8.3" />
    <rect style={iconStyle} x="34.9" width="75.1" height="66.9" />
  </g>;
}

;

export function svgThumbnailMosaicClicktellHorizontalLeft(iconStyle,pathStyle) {//media right
  return <g>
    <path style={iconStyle} d="M 0 24.5 c 0 0.8 0.5 1.4 1.1 1.5 h 23.6 c 0.6 0 1.1 -0.7 1.1 -1.5 v -23 c 0 -0.8 -0.5 -1.4 -1.1 -1.5 H 1.1 C 0.5 0.1 0 0.7 0 1.5 V 24.5 Z" />
    <path style={pathStyle} d="M 2 2.1 v 21.8 h 21.8 V 2.1 H 2 Z M 17 9.6 c 0 -1.3 1 -2.3 2.2 -2.3 c 1.3 0 2.2 1 2.2 2.2 v 0.1 c 0.1 1.3 -0.9 2.3 -2.1 2.4 c -1.3 0.1 -2.3 -0.9 -2.4 -2.1 C 17 9.8 17 9.6 17 9.6 Z M 3.9 18.7 v -5.8 l 3.7 -5.8 l 6.2 7.7 l 2.6 -2.1 l 5.5 5.8 L 3.9 18.7 L 3.9 18.7 Z" />
    <path style={iconStyle} d="M 0 52.6 c 0 0.8 0.5 1.4 1.1 1.5 h 23.6 c 0.6 0 1.1 -0.7 1.1 -1.5 v -23 c 0 -0.8 -0.5 -1.4 -1.1 -1.5 H 1.1 C 0.5 28.2 0 28.8 0 29.6 V 52.6 Z" />
    <path style={pathStyle} d="M 2 30.2 V 52 h 21.8 V 30.2 H 2 Z M 17 37.6 c 0 -1.3 1 -2.3 2.2 -2.3 c 1.3 0 2.2 1 2.2 2.2 v 0.1 c 0.1 1.3 -0.9 2.3 -2.1 2.4 c -1.3 0.1 -2.3 -0.9 -2.4 -2.1 C 17 37.9 17 37.7 17 37.6 Z M 3.9 46.8 V 41 l 3.7 -5.8 l 6.2 7.7 l 2.6 -2.1 l 5.5 5.8 L 3.9 46.8 L 3.9 46.8 Z" />
    <path style={iconStyle} d="M 28.2 24.5 c 0 0.8 0.5 1.4 1.1 1.5 h 23.6 c 0.6 0 1.1 -0.7 1.1 -1.5 v -23 c 0 -0.8 -0.5 -1.4 -1.1 -1.5 H 29.3 c -0.6 0 -1.1 0.7 -1.1 1.5 C 28.2 1.5 28.2 24.5 28.2 24.5 Z" />
    <path style={pathStyle} d="M 30.2 2.1 v 21.8 H 52 V 2.1 H 30.2 Z M 45.2 9.6 c 0 -1.3 1 -2.3 2.2 -2.3 c 1.3 0 2.2 1 2.2 2.2 v 0.1 c 0.1 1.3 -0.9 2.3 -2.1 2.4 c -1.3 0.1 -2.3 -0.9 -2.4 -2.1 C 45.2 9.8 45.2 9.6 45.2 9.6 Z M 32 18.7 v -5.8 l 3.7 -5.8 l 6.2 7.7 l 2.6 -2.1 l 5.5 5.8 L 32 18.7 L 32 18.7 Z" />
    <path style={iconStyle} d="M 28.2 52.6 c 0 0.8 0.5 1.4 1.1 1.5 h 23.6 c 0.6 0 1.1 -0.7 1.1 -1.5 V 29.7 c 0 -0.8 -0.5 -1.4 -1.1 -1.5 H 29.3 c -0.6 0 -1.1 0.7 -1.1 1.5 C 28.2 29.7 28.2 52.6 28.2 52.6 Z" />
    <path style={pathStyle} d="M 30.2 30.2 V 52 H 52 V 30.2 H 30.2 Z M 45.2 37.7 c 0 -1.3 1 -2.3 2.2 -2.3 c 1.3 0 2.2 1 2.2 2.2 v 0.1 c 0.1 1.3 -0.9 2.3 -2.1 2.4 c -1.3 0.1 -2.3 -0.9 -2.4 -2.1 C 45.2 37.9 45.2 37.8 45.2 37.7 Z M 32 46.8 V 41 l 3.7 -5.8 l 6.2 7.8 l 2.6 -2.1 l 5.5 5.8 L 32 46.8 L 32 46.8 Z" />
    <rect style={pathStyle} x="56" width="54" height="79" />
    <path style={iconStyle} d="M 56 0 v 79 h 54 V 0 H 56 Z M 63 60.3 h 25.8 l 0.1 4.2 H 63 V 60.3 Z M 63 72 v -4.2 h 33.8 c 0.1 0 0.1 4.2 0.1 4.2 H 63 Z M 63 57 v -4.2 h 37.9 c 0.1 0 0.1 4.2 0.1 4.2 H 63 Z M 103 47 H 63 V 7 h 40 V 47 Z" />
    <polygon style={iconStyle} points="89.4,26.7 84.6,30.5 73.2,16.4 66.4,26.9 66.4,37.4 99.5,37.4" />
    <path style={iconStyle} d="M 94.9 25.1 c 2.2 -0.2 3.9 -2.1 3.8 -4.4 v -0.1 c 0 -2.3 -1.8 -4.1 -4.1 -4.1 c -2.3 0 -4.1 1.9 -4.1 4.2 c 0 0.1 0 0.4 0 0.6 C 90.7 23.5 92.6 25.2 94.9 25.1 Z" />
  </g>;
}

;

export function svgThumbnailMosaicClicktellHorizontalRight(iconStyle,pathStyle) {//media left
  return <g>
    <path style={iconStyle} d="M 56 24.5 c 0 0.8 0.5 1.4 1.1 1.5 h 23.6 c 0.6 0 1.1 -0.7 1.1 -1.5 v -23 c 0 -0.8 -0.5 -1.4 -1.1 -1.5 H 57.1 C 56.5 0.1 56 0.7 56 1.5 V 24.5 Z" />
    <path style={pathStyle} d="M 58 2.1 v 21.8 h 21.8 V 2.1 H 58 Z M 73 9.6 c 0 -1.3 1 -2.3 2.2 -2.3 c 1.3 0 2.2 1 2.2 2.2 v 0.1 c 0.1 1.3 -0.9 2.3 -2.1 2.4 c -1.3 0.1 -2.3 -0.9 -2.4 -2.1 C 73 9.8 73 9.6 73 9.6 Z M 59.9 18.7 v -5.8 l 3.7 -5.8 l 6.2 7.7 l 2.6 -2.1 l 5.5 5.8 L 59.9 18.7 L 59.9 18.7 Z" />
    <path style={iconStyle} d="M 56 52.6 c 0 0.8 0.5 1.4 1.1 1.5 h 23.6 c 0.6 0 1.1 -0.7 1.1 -1.5 v -23 c 0 -0.8 -0.5 -1.4 -1.1 -1.5 H 57.1 c -0.6 0.1 -1.1 0.7 -1.1 1.5 V 52.6 Z" />
    <path style={pathStyle} d="M 58 30.2 V 52 h 21.8 V 30.2 H 58 Z M 73 37.6 c 0 -1.3 1 -2.3 2.2 -2.3 c 1.3 0 2.2 1 2.2 2.2 v 0.1 c 0.1 1.3 -0.9 2.3 -2.1 2.4 c -1.3 0.1 -2.3 -0.9 -2.4 -2.1 C 73 37.9 73 37.7 73 37.6 Z M 59.9 46.8 V 41 l 3.7 -5.8 l 6.2 7.7 l 2.6 -2.1 l 5.5 5.8 L 59.9 46.8 L 59.9 46.8 Z" />
    <path style={iconStyle} d="M 84.2 24.5 c 0 0.8 0.5 1.4 1.1 1.5 h 23.6 c 0.6 0 1.1 -0.7 1.1 -1.5 v -23 c 0 -0.8 -0.5 -1.4 -1.1 -1.5 H 85.3 c -0.6 0 -1.1 0.7 -1.1 1.5 C 84.2 1.5 84.2 24.5 84.2 24.5 Z" />
    <path style={pathStyle} d="M 86.2 2.1 v 21.8 H 108 V 2.1 H 86.2 Z M 101.2 9.6 c 0 -1.3 1 -2.3 2.2 -2.3 s 2.2 1 2.2 2.2 v 0.1 c 0.1 1.3 -0.9 2.3 -2.1 2.4 c -1.3 0.1 -2.3 -0.9 -2.4 -2.1 C 101.2 9.8 101.2 9.6 101.2 9.6 Z M 88 18.7 v -5.8 l 3.7 -5.8 l 6.2 7.7 l 2.6 -2.1 l 5.5 5.8 L 88 18.7 L 88 18.7 Z" />
    <path style={iconStyle} d="M 84.2 52.6 c 0 0.8 0.5 1.4 1.1 1.5 h 23.6 c 0.6 0 1.1 -0.7 1.1 -1.5 V 29.7 c 0 -0.8 -0.5 -1.4 -1.1 -1.5 H 85.3 c -0.6 0 -1.1 0.7 -1.1 1.5 C 84.2 29.7 84.2 52.6 84.2 52.6 Z" />
    <path style={pathStyle} d="M 86.2 30.2 V 52 H 108 V 30.2 H 86.2 Z M 101.2 37.7 c 0 -1.3 1 -2.3 2.2 -2.3 s 2.2 1 2.2 2.2 v 0.1 c 0.1 1.3 -0.9 2.3 -2.1 2.4 c -1.3 0.1 -2.3 -0.9 -2.4 -2.1 C 101.2 37.9 101.2 37.8 101.2 37.7 Z M 88 46.8 V 41 l 3.7 -5.8 l 6.2 7.8 l 2.6 -2.1 l 5.5 5.8 L 88 46.8 L 88 46.8 Z" />
    <rect style={pathStyle} width="54" height="79" />
    <polygon style={iconStyle} points="33.4,26.7 28.6,30.5 17.2,16.4 10.4,26.9 10.4,37.4 43.5,37.4" />
    <path style={iconStyle} d="M 0 0 v 79 h 54 V 0 H 0 Z M 7 60.3 h 25.8 l 0.1 4.2 H 7 V 60.3 Z M 7 72 v -4.2 h 33.8 c 0.1 0 0.1 4.2 0.1 4.2 H 7 Z M 7 57 v -4.2 h 37.9 C 45 52.8 45 57 45 57 H 7 Z M 47 47 H 7 V 7 h 40 V 47 Z" />
    <path style={iconStyle} d="M 38.9 25.1 c 2.2 -0.2 3.9 -2.1 3.8 -4.4 v -0.1 c 0 -2.3 -1.8 -4.1 -4.1 -4.1 c -2.3 0 -4.1 1.9 -4.1 4.2 c 0 0.1 0 0.4 0 0.6 C 34.7 23.5 36.6 25.2 38.9 25.1 Z" />
  </g>;
}

;

export function svgThumbnailMosaicClicktellVertical(iconStyle,pathStyle) {//media bottom
  return <g>
    <path style={iconStyle} d="M 56.1 24.5 c 0 0.8 0.5 1.4 1.1 1.5 h 23.6 c 0.6 0 1.1 -0.7 1.1 -1.5 v -23 c 0 -0.8 -0.5 -1.4 -1.1 -1.5 H 57.2 c -0.6 0.1 -1.1 0.7 -1.1 1.5 C 56.1 1.5 56.1 24.5 56.1 24.5 Z" />
    <path style={pathStyle} d="M 58.1 2.1 v 21.8 h 21.8 V 2.1 H 58.1 Z M 73.1 9.6 c 0 -1.3 1 -2.3 2.2 -2.3 c 1.3 0 2.2 1 2.2 2.2 v 0.1 c 0.1 1.3 -0.9 2.3 -2.1 2.4 c -1.3 0.1 -2.3 -0.9 -2.4 -2.1 C 73.1 9.8 73.1 9.6 73.1 9.6 Z M 60 18.7 v -5.8 l 3.7 -5.8 l 6.2 7.7 l 2.6 -2.1 l 5.5 5.8 L 60 18.7 L 60 18.7 Z" />
    <path style={iconStyle} d="M 0 24.5 c 0 0.8 0.5 1.4 1.1 1.5 h 23.6 c 0.6 0 1.1 -0.7 1.1 -1.5 v -23 c 0 -0.8 -0.5 -1.4 -1.1 -1.5 H 1.1 C 0.5 0.1 0 0.7 0 1.5 V 24.5 Z" />
    <path style={pathStyle} d="M 2 2.1 v 21.8 h 21.8 V 2.1 H 2 Z M 17 9.6 c 0 -1.3 1 -2.3 2.2 -2.3 c 1.3 0 2.2 1 2.2 2.2 v 0.1 c 0.1 1.3 -0.9 2.3 -2.1 2.4 c -1.3 0.1 -2.3 -0.9 -2.4 -2.1 C 17 9.8 17 9.6 17 9.6 Z M 3.9 18.7 v -5.8 l 3.7 -5.8 l 6.2 7.7 l 2.6 -2.1 l 5.5 5.8 L 3.9 18.7 L 3.9 18.7 Z" />
    <path style={iconStyle} d="M 84.2 24.5 c 0 0.8 0.5 1.4 1.1 1.5 h 23.6 c 0.6 0 1.1 -0.7 1.1 -1.5 v -23 c 0 -0.8 -0.5 -1.4 -1.1 -1.5 H 85.3 c -0.6 0 -1.1 0.7 -1.1 1.5 C 84.2 1.5 84.2 24.5 84.2 24.5 Z" />
    <path style={pathStyle} d="M 86.2 2.1 v 21.8 H 108 V 2.1 H 86.2 Z M 101.2 9.6 c 0 -1.3 1 -2.3 2.2 -2.3 s 2.2 1 2.2 2.2 v 0.1 c 0.1 1.3 -0.9 2.3 -2.1 2.4 c -1.3 0.1 -2.3 -0.9 -2.4 -2.1 C 101.2 9.8 101.2 9.6 101.2 9.6 Z M 88 18.7 v -5.8 l 3.7 -5.8 l 6.2 7.7 l 2.6 -2.1 l 5.5 5.8 L 88 18.7 L 88 18.7 Z" />
    <path style={iconStyle} d="M 28.1 24.5 c 0 0.8 0.5 1.4 1.1 1.5 h 23.6 c 0.6 0 1.1 -0.7 1.1 -1.5 V 1.6 c 0 -0.8 -0.5 -1.4 -1.1 -1.5 H 29.2 c -0.6 0 -1.1 0.7 -1.1 1.5 V 24.5 Z" />
    <path style={pathStyle} d="M 30.1 2.2 V 24 h 21.8 V 2.2 H 30.1 Z M 45.1 9.6 c 0 -1.3 1 -2.3 2.2 -2.3 c 1.3 0 2.2 1 2.2 2.2 v 0.1 c 0.1 1.3 -0.9 2.3 -2.1 2.4 c -1.3 0.1 -2.3 -0.9 -2.4 -2.1 C 45.1 9.8 45.1 9.7 45.1 9.6 Z M 31.9 18.7 V 13 l 3.7 -5.8 l 6.2 7.8 l 2.6 -2.1 l 5.5 5.8 H 31.9 Z" />
    <rect style={pathStyle} y="27.9" width="110" height="51.1" />
    <polygon style={iconStyle} points="31.4,53.2 27,56.7 16.5,43.5 10.2,53.3 10.2,63.1 40.8,63.1" />
    <path style={iconStyle} d="M 36.6 51.8 c 2 -0.2 3.6 -1.9 3.5 -4.1 v -0.1 c 0 -2.2 -1.6 -3.8 -3.8 -3.8 s -3.8 1.7 -3.8 3.9 c 0 0.1 0 0.4 0 0.6 C 32.7 50.3 34.4 51.9 36.6 51.8 Z" />
    <path style={iconStyle} d="M 0 27.9 V 79 h 110 V 27.9 H 0 Z M 49.3 42.4 h 36.4 l 0.2 4.2 H 49.3 V 42.4 Z M 44 72 H 7 V 35 h 37 V 72 Z M 49.3 61.6 v -4.2 h 41.8 c 0.1 0 0.1 4.2 0.1 4.2 H 49.3 Z M 49.3 54.1 v -4.2 H 97 c 0.1 0 0.1 4.2 0.1 4.2 H 49.3 Z M 49.3 39.2 V 35 h 53.5 c 0.1 0 0.1 4.2 0.1 4.2 H 49.3 Z" />
  </g>;
}

;

export function svgThumbnailMosaicClicktellVerticalMulti(iconStyle,pathStyle) {//media bottom, nulti-level
  return <g>
    <path style={iconStyle} d="M 56.1 24.5 c 0 0.8 0.5 1.4 1.1 1.5 h 23.6 c 0.6 0 1.1 -0.7 1.1 -1.5 v -23 c 0 -0.8 -0.5 -1.4 -1.1 -1.5 H 57.2 c -0.6 0.1 -1.1 0.7 -1.1 1.5 C 56.1 1.5 56.1 24.5 56.1 24.5 Z" />
    <path style={pathStyle} d="M 58.1 2.1 v 21.8 h 21.8 V 2.1 H 58.1 Z M 73.1 9.6 c 0 -1.3 1 -2.3 2.2 -2.3 c 1.3 0 2.2 1 2.2 2.2 v 0.1 c 0.1 1.3 -0.9 2.3 -2.1 2.4 c -1.3 0.1 -2.3 -0.9 -2.4 -2.1 C 73.1 9.8 73.1 9.6 73.1 9.6 Z M 60 18.7 v -5.8 l 3.7 -5.8 l 6.2 7.7 l 2.6 -2.1 l 5.5 5.8 L 60 18.7 L 60 18.7 Z" />
    <path style={iconStyle} d="M 0 24.5 c 0 0.8 0.5 1.4 1.1 1.5 h 23.6 c 0.6 0 1.1 -0.7 1.1 -1.5 v -23 c 0 -0.8 -0.5 -1.4 -1.1 -1.5 H 1.1 C 0.5 0.1 0 0.7 0 1.5 V 24.5 Z" />
    <path style={pathStyle} d="M 2 2.1 v 21.8 h 21.8 V 2.1 H 2 Z M 17 9.6 c 0 -1.3 1 -2.3 2.2 -2.3 c 1.3 0 2.2 1 2.2 2.2 v 0.1 c 0.1 1.3 -0.9 2.3 -2.1 2.4 c -1.3 0.1 -2.3 -0.9 -2.4 -2.1 C 17 9.8 17 9.6 17 9.6 Z M 3.9 18.7 v -5.8 l 3.7 -5.8 l 6.2 7.7 l 2.6 -2.1 l 5.5 5.8 L 3.9 18.7 L 3.9 18.7 Z" />
    <path style={iconStyle} d="M 84.2 24.5 c 0 0.8 0.5 1.4 1.1 1.5 h 23.6 c 0.6 0 1.1 -0.7 1.1 -1.5 v -23 c 0 -0.8 -0.5 -1.4 -1.1 -1.5 H 85.3 c -0.6 0 -1.1 0.7 -1.1 1.5 C 84.2 1.5 84.2 24.5 84.2 24.5 Z" />
    <path style={pathStyle} d="M 86.2 2.1 v 21.8 H 108 V 2.1 H 86.2 Z M 101.2 9.6 c 0 -1.3 1 -2.3 2.2 -2.3 s 2.2 1 2.2 2.2 v 0.1 c 0.1 1.3 -0.9 2.3 -2.1 2.4 c -1.3 0.1 -2.3 -0.9 -2.4 -2.1 C 101.2 9.8 101.2 9.6 101.2 9.6 Z M 88 18.7 v -5.8 l 3.7 -5.8 l 6.2 7.7 l 2.6 -2.1 l 5.5 5.8 L 88 18.7 L 88 18.7 Z" />
    <path style={iconStyle} d="M 28.1 24.5 c 0 0.8 0.5 1.4 1.1 1.5 h 23.6 c 0.6 0 1.1 -0.7 1.1 -1.5 V 1.6 c 0 -0.8 -0.5 -1.4 -1.1 -1.5 H 29.2 c -0.6 0 -1.1 0.7 -1.1 1.5 V 24.5 Z" />
    <path style={pathStyle} d="M 30.1 2.2 V 24 h 21.8 V 2.2 H 30.1 Z M 45.1 9.6 c 0 -1.3 1 -2.3 2.2 -2.3 c 1.3 0 2.2 1 2.2 2.2 v 0.1 c 0.1 1.3 -0.9 2.3 -2.1 2.4 c -1.3 0.1 -2.3 -0.9 -2.4 -2.1 C 45.1 9.8 45.1 9.7 45.1 9.6 Z M 31.9 18.7 V 13 l 3.7 -5.8 l 6.2 7.8 l 2.6 -2.1 l 5.5 5.8 H 31.9 Z" />
    <rect style={pathStyle} y="27.9" width="110" height="51.1" />
    <path style={iconStyle} d="M 62.5 51.7 c 2 -0.1 3.7 -1.9 3.5 -4.1 v -0.1 c 0 -2.2 -1.6 -3.8 -3.8 -3.8 s -3.8 1.8 -3.8 3.9 c 0 0.1 0 0.4 0 0.5 C 58.6 50.2 60.4 51.9 62.5 51.7 Z" />
    <polygon style={iconStyle} points="57.5,53.1 53,56.7 42.5,43.6 36.2,53.4 36.2,63 66.8,63" />
    <path style={iconStyle} d="M 0 27.9 V 79 h 110 V 27.9 H 0 Z M 75.4 42.4 h 18.7 l 0.1 4.2 H 75.4 V 42.4 Z M 7 54.1 v -4.2 h 20.9 c 0.1 0 0.1 4.2 0.1 4.2 H 7 Z M 7 46.6 v -4.2 h 20.9 l 0.1 4.2 H 7 Z M 7 39.2 V 35 h 20.9 l 0.1 4.2 H 7 Z M 70 72 H 33 V 35 h 37 V 72 Z M 75.4 61.6 v -4.2 l 21.5 0 c 0.1 0 0.1 4.2 0.1 4.2 H 75.4 Z M 75.4 54.1 v -4.2 h 24.5 c 0.1 0 0.1 4.2 0.1 4.2 H 75.4 Z M 75.4 39.2 V 35 h 27.5 c 0.1 0 0.1 4.2 0.1 4.2 H 75.4 Z" />
  </g>;
}

;

export function svgTimelineHorizontal(iconStyle,pathStyle) {
  return <g>
    <path style={iconStyle} d="M 0 21.5 V 18 h 110 v 3.5 H 0 Z M 56.7 18 h -3.5 v -7.3 h 3.5 V 18 Z M 29.6 10.8 V -0.1 h 50.8 v 10.9 L 29.6 10.8 Z" />
    <path style={iconStyle} d="M 0 76 c 0 1.6 1.1 2.8 2.2 3 h 46.3 c 1.2 0 2.2 -1.3 2.2 -3 V 31 c 0 -1.6 -1.1 -2.8 -2.2 -3 H 2.2 C 1.1 28.1 0 29.5 0 31 V 76 Z" />
    <path style={pathStyle} d="M 2.9 31 v 44.4 h 44.4 V 31 H 2.9 Z M 34.7 44.3 c 0 -2.3 1.9 -4.4 4.4 -4.4 s 4.4 1.8 4.4 4.1 v 0.1 c 0.1 2.3 -1.7 4.4 -4 4.5 c -2.4 0.1 -4.6 -1.7 -4.7 -3.9 C 34.7 44.7 34.7 44.5 34.7 44.3 Z M 5.6 66.6 V 53.1 l 8 -13.3 L 27 57.7 l 5.6 -4.9 l 11.9 13.4 H 5.6 V 66.6 Z" />
    <path style={iconStyle} d="M 59.3 76 c 0 1.6 1.1 2.8 2.2 3 h 46.3 c 1.2 0 2.2 -1.3 2.2 -3 V 31 c 0 -1.6 -1.1 -2.8 -2.2 -3 H 61.5 c -1.2 0.1 -2.2 1.5 -2.2 3 V 76 Z" />
    <path style={pathStyle} d="M 62.2 31 v 44.4 h 44.4 V 31 H 62.2 Z M 99.2 46.1 H 64.9 v -4.5 h 34 L 99.2 46.1 L 99.2 46.1 Z M 103.8 39.2 H 64.9 v -4.5 h 38.8 C 103.8 34.7 103.8 39.2 103.8 39.2 Z M 97.2 57.4 H 64.9 v -4.5 H 97 L 97.2 57.4 L 97.2 57.4 Z M 94.7 71.3 H 64.9 v -4.5 h 29.6 L 94.7 71.3 L 94.7 71.3 Z M 100.8 64.3 H 64.9 v -4.5 h 35.8 C 100.8 59.8 100.8 64.3 100.8 64.3 Z" />
  </g>;
}

;

export function svgTimelineVisual1(iconStyle,pathStyle) {
  if (!pathStyle?.fill) pathStyle.fill = '#FFFFFF';

  return <g>
    <rect style={pathStyle} width="110" height="79"/>
    <path style={iconStyle} d="M0,0v79h110V0H0z M7,56.2h39.7l0.2,3H7V56.2z M7,64.7v-3h25.8c0.1,0,0.1,3,0.1,3H7z M52.9,44.2H7V14.7h45.9
                V44.2z M20,49.8h32.8l0.1,1H20V49.8z M7,52.3v-4h10.4c0,0,0,4,0,4H7z M57.4,56.2h39.7l0.2,3H57.4V56.2z M57.4,64.7v-3h43.4
                c0.1,0,0.1,3,0.1,3H57.4z M103.3,44.2H57.4V14.7h45.9V44.2z M76.7,49.8h26.6l0.1,1H76.7V49.8z M57.4,52.3v-4H74c0,0,0,4,0,4H57.4z"
    />
    <polygon style={iconStyle} points="37.7,28.7 32,32.5 18.5,18.4 10.4,28.9 10.4,39.4 49.7,39.4 "/>
    <path style={iconStyle} d="M45.9,27.1c2.2-0.2,3.9-2.1,3.8-4.4v-0.1c0-2.3-1.8-4.1-4.1-4.1c-2.3,0-4.1,1.9-4.1,4.2c0,0.1,0,0.4,0,0.6
                C41.7,25.5,43.6,27.2,45.9,27.1z"/>
    <polygon style={iconStyle} points="88.3,28.7 82.6,32.5 69.1,18.4 61,28.9 61,39.4 100.3,39.4 "/>
    <path style={iconStyle} d="M96.5,27.1c2.2-0.2,3.9-2.1,3.8-4.4v-0.1c0-2.3-1.8-4.1-4.1-4.1c-2.3,0-4.1,1.9-4.1,4.2c0,0.1,0,0.4,0,0.6
                C92.3,25.5,94.2,27.2,96.5,27.1z"/>
    <rect x="55.1" y="-0.1" style={{ ...iconStyle,opacity:0.7 }} width="54.9" height="79"/>
  </g>;
}

;

export function svgTimelineVisual2(iconStyle,pathStyle) {
  if (!pathStyle?.fill) pathStyle.fill = '#FFFFFF';

  return <g>
    <rect style={pathStyle} width="110" height="79"/>
    <g>
      <path style={iconStyle} d="M93.3,42v-4h10.4v4H93.3z M30.2,42v-4h10.4v4H30.2z M0,0v79h110V0H0z M6.3,42v-4h10.4v4H6.3z M23,78.9V0.1h1
                    v78.8H23z M47,78.9V0.1h39v78.8H47z M86,78.9V0.1h1v78.8H86z"/>
    </g>
    <rect x="48" y="0.1" style={{ fill:'#637C84' }} width="38" height="78.9"/>
    <g>
      <path style={pathStyle} d="M58.2,49.3v-2.5h17.4c0.1,0,0.1,2.5,0.1,2.5H58.2z M60.2,54.5V52h13.5c0.1,0,0.1,2.5,0.1,2.5H60.2z M59.6,59.7
                    v-2.5h14.8c0.1,0,0.1,2.5,0.1,2.5H59.6z M61.8,42v-4h10.4v4H61.8z"/>
    </g>
  </g>;
}

;

export function svgTipButton(iconStyle,pathStyle,tipStyle) {
  return <g>
    <rect style={tipStyle} x="1.18" y="8.58" width="53.96" height="13.42" />
    <rect style={iconStyle} x="0.5" y="22.1" width="109" height="48.33" />
    <rect style={pathStyle} x="11.65" y="31.93" width="73.29" height="4.83" />
    <rect style={pathStyle} x="11.65" y="40.26" width="69.27" height="4.83" />
    <rect style={pathStyle} x="11.65" y="48.31" width="56.65" height="4.83" />
	    </g>;
}

;

export function svgTipResponse(iconStyle,pathStyle) {
  return <g>
    <rect style={iconStyle} width="110" height="79"/>
    <rect style={pathStyle} x="7.8" y="27.1" width="94.5" height="30.7"/>
    <rect style={pathStyle} x="7.8" y="64.4" width="19.6" height="7.6"/>
    <rect style={pathStyle} x="7.8" y="7" width="69.3" height="4.8"/>
    <rect style={pathStyle} x="7.8" y="15.8" width="74.6" height="4.8"/>
  </g>;
}

;

export function svgTipResponse2(iconStyle,pathStyle) {
  return <g>
    <rect style={iconStyle} width="55" height="79"/>
    <rect style={pathStyle} x="5.8" y="25.3" width="43.5" height="33.5"/>
    <rect style={pathStyle} x="5.8" y="65.2" width="19.6" height="7.6"/>
    <rect style={pathStyle} x="5.8" y="6.2" width="33.3" height="4.8"/>
    <rect style={pathStyle} x="5.8" y="14" width="35.8" height="4.8"/>
    <rect style={iconStyle} x="60.7" y="39.9" width="39.3" height="4.8"/>
    <rect style={iconStyle} x="60.7" y="47.7" width="43.2" height="4.8"/>
    <rect style={iconStyle} x="60.7" y="55.5" width="32.9" height="4.8"/>
    <rect style={pathStyle} x="60.7" y="4.2" width="43.2" height="27.5"/>
    <path style={iconStyle} d="M 60.7 32.1 c 0 1 0.9 1.9 1.9 1.9 h 39.5 c 1 0 1.9 -0.9 1.9 -1.9 V 1.9 c 0 -1 -0.9 -1.9 -1.9 -1.9 H 62.7 c -1 0 -1.9 0.9 -1.9 1.9 C 60.7 1.9 60.7 32.1 60.7 32.1 Z M 100.2 30.2 H 64.4 V 3.7 h 35.8 C 100.2 3.7 100.2 30.2 100.2 30.2 Z M 90 11.3 c 0 -2.1 1.6 -3.8 3.7 -3.8 s 3.7 1.6 3.7 3.8 c 0 2.1 -1.6 3.8 -3.7 3.8 S 90 13.3 90 11.3 Z M 98.2 26.3 H 68.3 v -9.5 l 6.2 -9.5 l 10.2 12.7 l 4.3 -3.4 L 98.2 26.3 Z" />
  </g>;
}

;

export function svgTipResponseOverview(iconStyle,pathStyle) {
  return <g>
    <path style={iconStyle} transform="translate(-3.89 -0.09)" d="M 12.25 26.94 h 85.5 c 3.75 0 6.8 2.52 6.8 5.64 V 54.21 c 0 3.11 -3 5.64 -6.8 5.64 H 12.25 c -3.75 0 -6.8 -2.53 -6.8 -5.64 V 32.58 C 5.45 29.46 8.5 26.94 12.25 26.94 Z" />
    <rect style={pathStyle} x="21.43" y="54.98" width="59.35" height="22.27" />
    <rect style={pathStyle} x="21.38" y="3.56" width="59.46" height="25.14" />
    <path style={pathStyle} transform="translate(-3.89 -0.09)" d="M 32.48 71.08 h 45" />
    <path style={pathStyle} transform="translate(-3.89 -0.09)" d="M 32.48 67.33 h 45" />
    <path style={pathStyle} transform="translate(-3.89 -0.09)" d="M 32.48 63.59 h 45" />
    <path style={pathStyle} transform="translate(-3.89 -0.09)" d="M 32.48 59.86 h 45" />
  </g>;
}

;
