import ChatAvatar from '@/chat/ChatAvatar';
import notify from '@/shared/helpers/notification_helper';
import React from 'react';

const HeaderUserDetails = (props) => {
  const { user } = props;
  // const user = {
  //     first_name: "John",
  //     last_name: "Doe",
  //     email: "john.doe@example.com",
  //     id: 1,
  // }

  const handleClick = () => {
    // if url is /courses/x, we want to notify the user that they might lose course updates
    if (window.location.pathname.includes('/courses/')) {
      notify.confirm(
        'Navigating away from this page could lose course updates. Make sure to save your course first. Are you sure you want to navigate to the profile page? ',
        (res) => {
          if (!res) return;
          window.location.href = '/profile';
        },
      );
    } else {
      window.location.href = '/profile';
    }
  };

  const keyHandler = (e) => {
    if (e.key === 'Enter') {
      handleClick();
    }

    if (e.key === ' ') {
      handleClick();
    }
  };

  if (!user) return null;

  return (
    <div className='HeaderUserDetails'>
      <a className='click-wrapper' onClick={handleClick} tabIndex={0} onKeyUp={keyHandler}>
        <div className={'user-text'}>
          <div className={'name'}>
            {user.first_name} {user.last_name}
          </div>
          <div className={'email'}>{user.email}</div>
        </div>
        <ChatAvatar size={34} user={user} />
      </a>
    </div>
  );
};

export default HeaderUserDetails;
