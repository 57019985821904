import React from 'react';

export function svgBranchingSummary(iconStyle,pathStyle,lineStyle) {
  return <g>
    <rect style={iconStyle} width="110" height="79" />
    <line x1="55" y1="26" x2="33" y2="53.8" style={lineStyle} />
    <line x1="55" y1="26" x2="77" y2="53.8" style={lineStyle} />
    <rect style={pathStyle} x="44" y="13.3" width="22" height="13.3" />
    <rect style={pathStyle} x="22" y="52.4" width="22" height="13.3" />
    <rect style={pathStyle} x="66" y="52.4" width="22" height="13.3" />

  </g>;
}

;

export function svgMemory(iconStyle,pathStyle) {
  const memoryStyle={ ...pathStyle };
  memoryStyle.opacity=.2;

  return <g>
    <path style={iconStyle} d="M0,36.1c0,1.1,0.7,1.9,1.5,2h31.6c0.8,0,1.5-0.9,1.5-2V5.3c0-1.1-0.7-1.9-1.5-2H1.5C0.7,3.4,0,4.3,0,5.3V36.1 z"/>
    <path style={pathStyle} d="M2.7,6.1v29.2h29.2V6.1H2.7z M22.8,16.1c0-1.7,1.3-3.1,3-3.1s3,1.3,3,3v0.1c0.1,1.7-1.2,3.1-2.8,3.2c-1.7,0.1-3.1-1.2-3.2-2.8C22.8,16.4,22.8,16.2,22.8,16.1z M5.2,28.3v-7.7l5-7.7l8.3,10.3l3.5-2.8l7.4,7.8L5.2,28.3L5.2,28.3z"/>
    <path style={iconStyle} d="M37.3,36c0,1.1,0.7,1.9,1.5,2h31.6c0.8,0,1.5-0.9,1.5-2V5.2c0-1.1-0.7-1.9-1.5-2H38.8c-0.8,0-1.5,0.9-1.5,2 V36z"/>
    <path style={pathStyle} d="M40,6.1v29.2h29.2V6.1H40z M60.2,16.1c0-1.7,1.3-3.1,3-3.1c1.7,0,3,1.3,3,3v0.1c0.1,1.7-1.2,3.1-2.8,3.2 c-1.7,0.1-3.1-1.2-3.2-2.8C60.2,16.4,60.2,16.2,60.2,16.1z M42.5,28.2v-7.7l5-7.7l8.3,10.3l3.5-2.8l7.4,7.8L42.5,28.2L42.5,28.2z"/>
    <path style={iconStyle} d="M37.7,73.8c0,1.1,0.7,1.9,1.5,2h31.6c0.8,0,1.5-0.9,1.5-2V43.1c0-1.1-0.7-1.9-1.5-2H39.2 c-0.8,0-1.5,0.9-1.5,2V73.8z"/>
    <path style={pathStyle} d="M40.4,43.9v29.2h29.2V43.9H40.4z M60.5,53.9c0-1.7,1.3-3.1,3-3.1c1.7,0,3,1.3,3,3v0.1
			c0.1,1.7-1.2,3.1-2.8,3.2c-1.7,0.1-3.1-1.2-3.2-2.8C60.5,54.1,60.5,54,60.5,53.9z M42.9,66.1v-7.7l5-7.7l8.3,10.4l3.5-2.8l7.4,7.8
			H42.9z"/>
		    <path style={iconStyle} d="M75.4,36.1c0,1.1,0.7,1.9,1.5,2h31.6c0.8,0,1.5-0.9,1.5-2V5.3c0-1.1-0.7-1.9-1.5-2H76.9c-0.8,0-1.5,0.9-1.5,2 V36.1z"/>
    <path style={memoryStyle} d="M78.1,6.2v29.2h29.2V6.2H78.1z M99.6,26.1h-19v-4.2h19V26.1z M92.7,19.5H80.6v-4.2h12.1V19.5z"/>
		    <path style={iconStyle} d="M0,73.8c0,1.1,0.7,1.9,1.5,2h31.6c0.8,0,1.5-0.9,1.5-2V43c0-1.1-0.7-1.9-1.5-2H1.5C0.7,41,0,41.9,0,43V73.8z" />
		    <path style={memoryStyle} d="M2.7,43.9v29.2h29.2V43.9H2.7z M24.2,63.8h-19v-4.2h19V63.8z M17.3,57.2H5.2V53h12.1V57.2z"/>
		    <path style={iconStyle} d="M75.4,73.8c0,1.1,0.7,1.9,1.5,2h31.6c0.8,0,1.5-0.9,1.5-2V43c0-1.1-0.7-1.9-1.5-2H76.9c-0.8,0-1.5,0.9-1.5,2 V73.8z"/>
		    <path style={pathStyle} d="M78.1,43.8v29.2h29.2V43.8H78.1z M98.2,53.9c0-1.7,1.3-3.1,3-3.1c1.7,0,3,1.3,3,3v0.1 c0.1,1.7-1.2,3.1-2.8,3.2c-1.7,0.1-3.1-1.2-3.2-2.8C98.2,54.2,98.2,54,98.2,53.9z M80.6,66.1v-7.7l5-7.7L93.9,61l3.5-2.8l7.4,7.8 L80.6,66.1L80.6,66.1z"/>
  </g>;
}

;

export function svgPeril(iconStyle,pathStyle) {
  const bgStyle={ fill:'#637c84' };

  return <g>
    <rect style={pathStyle} width="110" height="79" />
    <path style={iconStyle} d="M 0 0 v 79 h 92 V 0 H 0 Z M 64.9 4.2 h 17 v 3.5 h -17 V 4.2 Z M 36.9 4.2 h 17 v 3.5 h -17 V 4.2 Z M 8.9 4.2 h 17 v 3.5 h -17 V 4.2 Z M 29.8 46.6 H 5 v -6 h 24.8 V 46.6 Z M 29.8 37.1 H 5 v -6 h 24.8 V 37.1 Z M 29.8 27.7 H 5 v -6 h 24.8 V 27.7 Z M 29.8 18.2 H 5 v -6 h 24.8 V 18.2 Z M 57.8 37.1 H 33 v -6 h 24.8 V 37.1 Z M 57.8 27.7 H 33 v -6 h 24.8 V 27.7 Z M 57.8 18.2 H 33 v -6 h 24.8 V 18.2 Z M 85.8 75 H 61 v -6 h 24.8 V 75 Z M 85.8 56.1 H 61 v -6 h 24.8 V 56.1 Z M 85.8 65.5 H 61 v -6 h 24.8 V 65.5 Z M 85.8 46.6 H 61 v -6 h 24.8 V 46.6 Z M 85.8 37.1 H 61 v -6 h 24.8 V 37.1 Z M 85.8 27.7 H 61 v -6 h 24.8 V 27.7 Z M 85.8 18.2 H 61 v -6 h 24.8 V 18.2 Z" />
    <polygon style={bgStyle} points="102.3,57.2 100.9,58.3 97.7,54.3 95.7,57.3 95.7,60.3 105.2,60.3" />
    <path style={bgStyle} d="M 103.8 56.8 c 0.6 0 1.1 -0.6 1.1 -1.2 v 0 c 0 -0.7 -0.5 -1.2 -1.2 -1.2 s -1.2 0.5 -1.2 1.2 c 0 0.1 0 0.1 0 0.2 C 102.6 56.3 103.2 56.8 103.8 56.8 Z" />
    <path style={bgStyle} d="M 90.8 0 v 79 H 110 V 0 H 90.8 Z M 94 2.4 h 12.9 v 5.5 H 94 V 2.4 Z M 95.9 13.6 v -3.5 h 9 v 3.5 H 95.9 Z M 102.5 15.7 v 5.5 h -4.1 v -5.5 H 102.5 Z M 105.8 53.2 v 8.1 H 95 v -8.1 H 105.8 Z M 106.9 68.9 H 94 v -4.8 h 12.9 V 68.9 Z" />
  </g>;
}

;

export function svgQuizListenUntimed(iconStyle,pathStyle) {
  const bgStyle={ fill:'#637c84' };

  return <g>
    <rect style={pathStyle} y="11.3" width="72.6" height="56.4" />
    <path style={bgStyle} d="M 0 11.3 v 11.9 h 72.6 V 11.3 H 0 Z M 21.2 19.8 H 3.8 v -6.5 h 17.3 V 19.8 Z M 68.2 19.8 H 26.1 v -3 h 42.1 V 19.8 Z" />
    <path style={iconStyle} d="M 24 36.3 c 2.2 -0.1 4.2 -2.2 4 -4.6 v -0.1 c 0 -2.4 -1.9 -4.3 -4.3 -4.3 s -4.3 2 -4.3 4.5 c 0 0.2 0 0.5 0 0.6 C 19.6 34.5 21.6 36.5 24 36.3 Z" />
    <polygon style={iconStyle} points="17.8,43.1 9.9,28.2 5.1,39.3 5.1,50.4 28.1,50.4 21.1,39.1" />
    <path style={iconStyle} d="M 0 21.8 v 45.9 h 72.6 V 21.8 H 0 Z M 33.7 32 h 28.8 V 35 H 33.7 V 32 Z M 2.7 24.3 h 28 v 30 h -28 V 24.3 Z M 6.7 62.2 l -4 -1.2 l 4 -1.2 V 62.2 Z M 16.5 65.1 H 8.1 v -8.4 h 8.4 V 65.1 Z M 26.1 65.1 h -8.4 v -8.4 h 8.4 V 65.1 Z M 35.7 65.1 h -8.4 v -8.4 h 8.4 V 65.1 Z M 45.3 65.1 h -8.4 v -8.4 h 8.4 V 65.1 Z M 54.9 65.1 h -8.4 v -8.4 h 8.4 V 65.1 Z M 64.5 65.1 h -8.4 v -8.4 h 8.4 V 65.1 Z M 65.9 62.2 v -2.5 l 4 1.2 L 65.9 62.2 Z M 69.9 30.1 H 33.7 v -2.9 h 36.2 V 30.1 Z" />
    <rect style={iconStyle} x="74.3" y="11.3" width="35.7" height="9.8" />
    <rect style={iconStyle} x="74.3" y="22.9" width="35.7" height="9.8" />
    <rect style={iconStyle} x="74.3" y="34.6" width="35.7" height="9.8" />
    <rect style={iconStyle} x="74.3" y="46.2" width="35.7" height="9.8" />
    <rect style={iconStyle} x="74.3" y="57.9" width="35.7" height="9.8" />
  </g>;
}

;

export function svgScratchReveal(iconStyle,pathStyle) {
  return <g>
    <path style={iconStyle} d="M105,5.01V73.99h-.01s-99.97,.01-99.97,.01h-.01V5.01h.01s99.97-.01,99.97-.01h.01Zm-1.88-5H6.88C3.09,0,0,2.54,0,5.64V73.36c0,3.1,3.09,5.64,6.88,5.64H103.12c3.78,0,6.88-2.54,6.88-5.64V5.64c0-3.1-3.09-5.64-6.88-5.64h0Z"/>
    <path style={pathStyle} d="M85.84,14.6l-10.72,12.22s-2.64-2.11-2.63-6.5,3.85-8.09,8.43-7.91c3.7,.15,4.81,1.91,4.94,2.14,0,.02,0,.03,0,.05Z"/>
    <path style={iconStyle} d="M41.89,66.6H11.96v-10.88L37.07,23.06l22.98,21.8"/>
    <line style={iconStyle} x1="95.14" y1="2.85" x2="33.46" y2="76.69"/>
  </g>;
}

;

export function svgStandardQuiz(iconStyle,pathStyle) {
  const bgStyle={ fill:'#637c84' };

  return <g>
    <rect style={iconStyle} width="110" height="79" />
    <rect style={pathStyle} x="3.6" y="3.6" width="55.4" height="4.5" />
    <rect style={pathStyle} x="3.6" y="11" width="44" height="4.5" />
    <rect style={pathStyle} x="3.6" y="70.6" width="12.9" height="4.8" />
    <path style={pathStyle} d="M 3.6 20.8 v 41.7 H 59 V 20.8 H 3.6 Z M 42.4 32.6 c 0 -3.4 2.6 -6.2 6 -6.2 s 6 2.6 6 6 v 0.2 c 0.2 3.4 -2.5 6.2 -5.6 6.4 c -3.4 0.2 -6.2 -2.5 -6.4 -5.6 C 42.4 33.3 42.4 32.9 42.4 32.6 Z M 7 57.1 V 41.7 l 10.1 -15.5 L 33.8 47 l 7 -5.6 l 14.9 15.7 L 7 57.1 L 7 57.1 Z" />
    <rect style={bgStyle} x="66.6" width="39.8" height="79" />
    <rect style={pathStyle} x="66.6" y="18.9" width="39.8" height="8.3" />
    <rect style={pathStyle} x="66.6" y="29.9" width="39.8" height="8.3" />
    <rect style={pathStyle} x="66.6" y="40.8" width="39.8" height="8.3" />
    <rect style={pathStyle} x="66.6" y="51.8" width="39.8" height="8.3" />
  </g>;
}

;
export function svgStandardQuiz2(iconStyle,pathStyle) {
  return <g>
    <rect style={iconStyle} width="110" height="79" />
    <path style={pathStyle} d="M 3.6 45.2 v 19.7 H 55 V 45.2 H 3.6 Z M 21.6 50.8 c 0 -1.6 1.2 -3 2.8 -3 s 2.8 1.2 2.8 2.9 v 0.1 c 0.1 1.6 -1.1 3 -2.6 3.1 c -1.6 0.1 -2.9 -1.1 -3 -2.7 C 21.6 51 21.6 50.9 21.6 50.8 Z M 5.2 62.3 v -7.3 l 4.7 -7.4 l 7.8 9.8 l 3.3 -2.7 l 6.9 7.5 L 5.2 62.3 L 5.2 62.3 Z M 47.3 50.8 c 0 -1.6 1.2 -3 2.8 -3 s 2.8 1.2 2.8 2.9 v 0.1 c 0.1 1.6 -1.1 3 -2.6 3.1 c -1.6 0.1 -2.9 -1.1 -3 -2.7 C 47.3 51 47.3 50.9 47.3 50.8 Z M 30.8 62.3 v -7.3 l 4.7 -7.4 l 7.8 9.8 l 3.3 -2.7 l 6.9 7.5 L 30.8 62.3 L 30.8 62.3 Z" />
    <path style={pathStyle} d="M 3.6 21.9 v 19.7 h 102.8 V 21.9 H 3.6 Z M 21.6 27.4 c 0 -1.6 1.2 -3 2.8 -3 s 2.8 1.3 2.8 3 v 0.1 c 0.1 1.6 -1.1 3 -2.6 3.1 c -1.6 0.1 -2.9 -1.1 -3 -2.7 C 21.6 27.7 21.6 27.6 21.6 27.4 Z M 5.2 39.1 v -7.4 l 4.7 -7.4 l 7.8 9.8 l 3.3 -2.7 l 6.9 7.5 L 5.2 39.1 L 5.2 39.1 Z M 47.3 27.4 c 0 -1.6 1.2 -3 2.8 -3 s 2.8 1.2 2.8 2.9 v 0.1 c 0.1 1.6 -1.1 3 -2.6 3.1 c -1.6 0.1 -2.9 -1.1 -3 -2.7 C 47.3 27.7 47.3 27.6 47.3 27.4 Z M 30.8 39.1 v -7.4 l 4.7 -7.4 l 7.8 9.8 l 3.3 -2.7 l 6.9 7.5 L 30.8 39.1 L 30.8 39.1 Z M 73 27.4 c 0 -1.6 1.2 -3 2.8 -3 c 1.6 0 2.8 1.2 2.8 2.9 v 0.1 c 0.1 1.6 -1.1 3 -2.6 3.1 c -1.6 0.1 -2.9 -1.1 -3 -2.7 C 73 27.7 73 27.6 73 27.4 Z M 56.6 39.1 v -7.4 l 4.7 -7.4 l 7.8 9.8 l 3.3 -2.7 l 6.9 7.5 L 56.6 39.1 L 56.6 39.1 Z M 98.7 27.4 c 0 -1.6 1.2 -3 2.8 -3 c 1.6 0 2.8 1.2 2.8 2.9 v 0.1 c 0.1 1.6 -1.1 3 -2.6 3.1 c -1.6 0.1 -2.9 -1.1 -3 -2.7 C 98.7 27.7 98.7 27.6 98.7 27.4 Z M 82.2 39.1 v -7.4 l 4.7 -7.4 l 7.8 9.8 l 3.3 -2.7 l 6.8 7.6 H 82.2 L 82.2 39.1 Z" />
    <rect style={pathStyle} x="3.6" y="3.6" width="93.9" height="4.8" />
    <rect style={pathStyle} x="3.6" y="11.4" width="74.6" height="4.8" />
    <rect style={pathStyle} x="3.6" y="70.6" width="12.9" height="4.8" />
  </g>;
}

;
export function svgStandardQuiz3(iconStyle,pathStyle) {
  return <g>
    <rect style={iconStyle} width="110" height="79" />
    <rect style={pathStyle} x="3.6" y="3.6" width="46.1" height="4.8" />
    <rect style={pathStyle} x="3.6" y="70.6" width="12.9" height="4.8" />
    <rect style={pathStyle} x="3.6" y="12.6" width="102.8" height="53.9" />
    <circle style={iconStyle} cx="93.4" cy="40.2" r="3" />
    <circle style={iconStyle} cx="75.3" cy="59.3" r="3" />
    <circle style={iconStyle} cx="21.5" cy="19.3" r="3" />
    <path style={iconStyle} d="M 27.6 16.4 v 40 h 32.3 v -40 C 59.9 16.4 27.6 16.4 27.6 16.4 Z M 52.8 20 c 1.7 0 3.1 1.4 3.1 3.1 s -1.4 3.1 -3.1 3.1 c -1.7 0 -3.1 -1.4 -3.1 -3.1 S 51.1 20 52.8 20 Z M 30.9 40.8 H 54 V 45 H 30.9 V 40.8 Z M 56.6 51.8 H 30.9 v -4.2 h 25.7 V 51.8 Z M 30.9 35.4 v -7.7 l 5.4 -7.7 L 45 30.3 l 3.8 -2.7 l 7.8 7.8 H 30.9 Z" />
    <circle style={iconStyle} cx="10.1" cy="51.8" r="3" />
  </g>;
}

;

export function svgWordFrenzy(iconStyle,pathStyle) {
  const bgStyle={ fill:'#425d63' };
  const bgCat={ fill:'#afbfbe' };

  return <g>
    <defs>
      <filter id="wf-dropshadow" width="110" height="67">
        <feDropShadow dx="0" dy="1.7" stdDeviation="2" />
      </filter>
    </defs>
    <rect style={iconStyle} width="110" height="79" />
    <path style={bgStyle} d="M 36.1 27.2 H 47 v 17.3 H 36.1 V 27.2 Z M 40 40.9 h 9.8 v 17.3 H 40 V 40.9 Z M 47 3.5 h 13.2 v 17.3 H 47 V 3.5 Z M 55.4 16.2 h 9.5 v 17.3 h -9.5 V 16.2 Z M 46.7 58.2 H 62 v 17.3 H 46.7 V 58.2 Z M 49.1 31.1 H 62 v 17.3 H 49.1 V 31.1 Z M 90.2 2.8 h 17.3 v 17.3 H 90.2 V 2.8 Z M 58.3 41.9 h 12.3 v 17.3 H 58.3 V 41.9 Z M 66.5 33.3 h 7.5 v 17.3 h -7.5 V 33.3 Z M 51.5 22.5 h 3 v 17.3 h -3 V 22.5 Z M 37 49.6 h 6 v 17.3 h -6 V 49.6 Z M 76.9 53.3 h 17.3 v 17.3 H 76.9 V 53.3 Z M 1.9 13.8 h 17.3 v 17.3 H 1.9 V 13.8 Z M 17.9 48.4 h 14.8 v 17.3 H 17.9 V 48.4 Z M 64.5 20.1 h 14.4 v 17.3 H 64.5 V 20.1 Z" />
    <rect className="wf-dropshadow" style={bgCat} transform="matrix(0.9769 -0.2139 0.2139 0.9769 -7.1749 12.6758)" x="31.7" y="30.5" width="46.7" height="18" />
    <rect className="wf-dropshadow" style={pathStyle} transform="matrix(0.1904 -0.9817 0.9817 0.1904 1.5002 55.8718)" x="29.9" y="14.8" width="9.4" height="24.5" />
  </g>;
}

;
