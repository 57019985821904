import React, { useEffect, useState } from 'react';
import './ChatOverlay.scss';
import Chat from './Chat';
import SVGIcon from '@/shared/components/SVGIcon/SVGIcon';
import notifySound from './notification-sound.mp3';
import TopbarButtonTooltip from '@/shared/components/TopbarButtonTooltip/TopbarButtonTooltip';

const ChatOverlay = props => {
  const BOUNCE_TIME = 2000;

  const [open, setOpen] = useState(false);
  const [hasUnread, setHasUnread] = useState(false);
  const [bounce, setBounce] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const resetTitle = () => {
    document.title = 'ChameleonCloud';

    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = '/favicon.ico';
    document.getElementsByTagName('head')[0].appendChild(link);
  };

  useEffect(() => {
    if (hasUnread) {
      setBounce(true);
      setTimeout(() => {
        setBounce(false);
      }, BOUNCE_TIME);

      if (open) {
        return;
      }

      const appRoot = document.querySelector('#app-root');
      const currentUser = JSON.parse(appRoot.getAttribute('data-user')) || {};
      let silent = false;
      if (currentUser.silent_notifications == true) {
        silent = true;
      }

      //play notification sound
      if (!silent) {
        const audio = new Audio(notifySound);
        audio.play();
      }

      document.title = 'New Message - ChameleonCloud';

      //update icon
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = '/favicon-notification.ico';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    else {
      resetTitle();
    }
  }, [hasUnread]);

  useEffect(() => {
    if (open) {
      resetTitle();
    }
  }, [open]);

  return <div className="ChatOverlay">
    <button className={`chat-toggle-button ${bounce ? 'bounce' : ''}`} onClick={toggleOpen}>
      <SVGIcon type={open ? 'chat' : 'chatOpen'} fill={'#fff'} />
      {hasUnread && <div className={'unread'}></div>}
      <TopbarButtonTooltip text="Chat" />
    </button>

    <div className={'chat-display'}>
      <Chat open={open} setOpen={setOpen} setHasUnread={setHasUnread} />
    </div>
  </div>;
};

export default ChatOverlay;
