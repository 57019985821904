import React from 'react';
import axios from 'axios';
import SVGIcon from '../components/SVGIcon/SVGIcon';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    this.isLocal = window.location.href.includes('localhost:8443');

    this.errorColor = '#f00';
  }

  componentDidCatch(error, errorInfo) {
    console.error('error', error);
    this.setState({ error, errorInfo });

    axios.post('/api/errors/', {
      message: error.toString(),
      at: errorInfo.componentStack,
    });
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <div style={{ display: 'flex' }}>
            <SVGIcon type='error' size={32} fill={this.errorColor} />
            <div style={{ marginLeft: '8px' }}>
              <h2 style={{ color: this.errorColor, fontWeight: 'bold', fontStyle: 'italic', fontSize: '14px' }}>
                Something went wrong.
              </h2>
              <p style={{ color: this.errorColor, fontStyle: 'italic', fontSize: '12px' }}>
                {' '}
                An error report has been sent.
              </p>
            </div>
          </div>

          {this.isLocal && (
            <details style={{ whiteSpace: 'pre-wrap', opacity: 0.7, fontSize: '0.8em', margin: '10px' }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details>
          )}
        </div>
      );
    }

    // Normally, just render children
    return this.props.children;
  }
}
