/* eslint-disable no-alert */
const notify = {
  init: function() {
    if (window.notifications) return;
    window.notifications = {
      add: (notification) => {
        const event = new CustomEvent('new-notification', {
          detail: {
            text: 'Prompt',
            special: 'prompt',
            persist: true,
            id: new Date().getTime(),
            ...notification,
          },
        });
        window.dispatchEvent(event);
      },
      confirm: (text = undefined, action = undefined, options = undefined) => {
        const event = new CustomEvent('new-notification', {
          detail: {
            text: 'Are you sure?',
            special: 'prompt',
            persist: true,
            modal: true,
            action:
              action ??
              (() => {
                console.error('no action defined for notification');
              }),
            prompt: {
              text: text,
              confirmText: 'Select an option:',
              onCloseIndex: 2,
              options: options ?? [
                {
                  text: 'Yes',
                  value: true,
                },
                {
                  text: 'No',
                  value: false,
                },
              ],
            },
            id: new Date().getTime(),
          },
        });
        window.dispatchEvent(event);
      },
    };
  },

  confirm: function(
    text = 'Are you sure?',
    action = () => {
      console.error('No action provided for notification helper');
    },
    options = undefined,
  ) {
    if (!window.notifications) {
      const msg = confirm(text);
      if (msg) {
        action();
      }
    } else {
      window.notifications?.confirm(text, action, options);
    }
  },
  alert: function(message = 'Alert', type = 'info', action = () => {}) {
    if (!window.notifications) {
      alert(message);
      action();
    } else {
      const event = new CustomEvent('new-notification', {
        detail: {
          text: 'Alert',
          special: 'prompt',
          persist: true,
          type: type,
          modal: true,
          id: new Date().getTime(),
          action: action,
          prompt: {
            text: message,
            onCloseIndex: 2,
            options: [
              {
                text: 'OK',
                value: true,
              },
            ],
          },
        },
      });
      window.dispatchEvent(event);
    }
  },
  success: function(message = 'Success', type = 'info', action = () => {}) {
    if (!window.notifications) {
      alert(message);
      action();
    } else {
      const event = new CustomEvent('new-notification', {
        detail: {
          text: 'Success',
          special: 'prompt',
          persist: true,
          type: type,
          modal: true,
          id: new Date().getTime(),
          action: action,
          prompt: {
            text: message,
            onCloseIndex: 2,
            options: [
              {
                text: 'OK',
                value: true,
              },
            ],
          },
        },
      });
      window.dispatchEvent(event);
    }
  },
  toast: function(message = 'Alert', type = 'info', detail = {}) {
    if (!window.notifications) {
      alert(message);
    } else {
      const event = new CustomEvent('new-notification', {
        detail: {
          type: type,
          timeout: 5000,
          animate: true,
          special: 'toast',
          text: message,
          silent: true,
          id: new Date().getTime(),
          onClose: () => {},
          action: () => {},
          ...detail,
        },
      });
      window.dispatchEvent(event);
    }
  },
  prompt: function(text = 'Prompt') {
    const ans = prompt(text);

    return ans;
  },
};

export default notify;
