import React, { Component } from 'react';
import PropTypes from 'prop-types';

const cb = 'cml-tab';

class CMLTab extends Component {
  onClick = () => {
    const { label, onClick } = this.props;
    onClick(label);
  };

  render() {
    const {
      onClick,
      props: {
        activeTab,
        label,
      },
    } = this;

    let activeClassName = '';

    if (activeTab === label) {
      activeClassName = `${cb}--active`;
    }

    return (
      <li
        className={`${cb} ${activeClassName}`}
        onClick={onClick}
        onKeyUp={(e) => {if (e.which==13){onClick();}}}
        tabIndex="0"
      >
        {label}
      </li>
    );
  }
}

CMLTab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
CMLTab.defaultProps = {
  onClick: () => {},
};

export default CMLTab;
