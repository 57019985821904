import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CMLInput from '../CMLInput/CMLInput';
import { stripHTML } from '../../../../js/editor/components/Editor/components/Builder/Widgets/constants';

import './CMLHeading.scss';
const cb = 'cml-heading';

class CMLHeading extends Component {
  constructor(props) {
    super(props);
    const methods = ['getOverrideClasses', 'renderInput'];
    methods.forEach(method => (this[method] = this[method].bind(this)));
    this.state = {
      editing: false,
    };
  }

  render() {
    const cbOverrides = this.getOverrideClasses();

    return (
      <div className={`${cb} ${cbOverrides} notranslate`}>
        { this.props.editable ? this.renderInput() : this.props.title }
      </div>
    );
  }

  renderInput() {
    return (
      <CMLInput
        handleOnChange={(e) => {
          if (this.props.removeHTML) {
            this.props.onEdit( stripHTML(e.target.value) );
          }
          else {
            this.props.onEdit( e.target.value );
          }
        }}
        placeholder={this.props.placeholder}
        trackValue={true}
        value={this.props.title}
      />
    );
  }

  getOverrideClasses() {
    const cbOverrides = [];

    if ( this.props.editable ) {
      cbOverrides.push(`${cb}--editable`);
    }

    if ( this.props.color ) {
      cbOverrides.push(`${cb}--${this.props.color}`);
    }

    if ( this.props.size ) {
      cbOverrides.push(`${cb}--${this.props.size}`);
    }

    return cbOverrides.join(' ');
  }
}

CMLHeading.propTypes = {
  color: PropTypes.string,
  editable: PropTypes.bool,
  onEdit: PropTypes.func,
  placeholder: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string,
};

CMLHeading.defaultProps = {
  color: 'primary',
  editable: false,
  onEdit: () => {},
  size: '1',
};

export default CMLHeading;
