import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CMLTab from './CMLTab';

import './CMLTabs.scss';
const cb = 'cml-tabs';

class CMLTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.children[0].props.label,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    let tabFound=false;
    this.props.children.map((child) => {
      if (child.props.children.length && this.state.activeTab==child.props.label) {
        tabFound=true;
      }
    });
    if ((this.props.children.length && !tabFound) || prevProps.widgetId!=this.props.widgetId) {
      this.setState({ 'activeTab':this.props.children[0].props.label });
    }
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    const {
      onClickTabItem,
      props: {
        children,
      },
      state: {
        activeTab,
      },
    } = this;

    return (
      <div className={`${cb}`}>
        <ol className={`${cb}__tab-labels`}>
          {children.map((child) => {
            const { label } = child.props;
            if (child.props.children.length) {
              return (
                <CMLTab
                  activeTab={activeTab}
                  key={label}
                  label={label}
                  onClick={onClickTabItem}
                />
              );
            }
          })}
        </ol>
        <div className={`${cb}__tab-contents`}>
          {children.map((child) => {
            if (child.props.label !== activeTab) return undefined;

            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

CMLTabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
};
CMLTabs.defaultProps = {
  children: [],
};

export default CMLTabs;
