import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk to fetch course clients
export const fetchClients = createAsyncThunk('clients/fetchClients', async(_, thunkAPI) => {
  try {
    const response = await axios.get('/api/clients/');

    return response.data?.data ?? response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

export const createClient = createAsyncThunk('clients/createClient', async(data, thunkAPI) => {
  try {
    const response = await axios.post('/api/clients/', data);

    return response.data?.data ?? response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

const clientsSlice = createSlice({
  name: 'clients',
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {
    // Add synchronous actions if needed
  },
  extraReducers: (builder) => {
    // Handle fetchClients
    builder
      .addCase(fetchClients.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(createClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createClient.fulfilled, (state, action) => {
        state.loading = false;
        state.list = [...state.list, { ...action.payload, new: true }];
      })
      .addCase(createClient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default clientsSlice.reducer;
