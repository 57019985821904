import React from 'react';
import IssueCard from './IssueCard';
const BoardColumn = (props) => {
  const { status, issues = [], setSelectedIssueId = () => {} } = props;

  return (
    <div className='BoardColumn'>
      <div className={'title'}>{status.prettyname}</div>

      <div className={'issues'}>
        {issues.map((issue, index) => {
          return <IssueCard issue={issue} select={() => setSelectedIssueId(issue.id)} key={issue.id} />;
        })}
      </div>
    </div>
  );
};

export default BoardColumn;
