import React from 'react';
import './StyledTabs.scss';

const StyledTabs = props => {
  const {
    tabs,
    activeTab,
    setActiveTab,
  } = props;

  return <div className="StyledTabs">
    {
      Object.entries(tabs).map(([key, tab]) => {
        return <button key={key} className={`tab ${activeTab == key ? 'current' : ''}`} onClick={() => setActiveTab(tab?.key ?? key)}>
          {tab.label}
        </button>;
      })
    }
  </div>;
};

export default StyledTabs;
